import Vue from 'vue';
import { make } from 'vuex-pathify';
import axios from 'axios';
import { differenceInHours, parseISO } from 'date-fns';
import _ from 'lodash';
import { genericErrorMessage } from '../../mixins/errorMessages';
import helpers from '../../mixins/helpers';
/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-underscore-dangle: 0 */
/* eslint no-mixed-operators: 0 */
/* eslint no-unused-vars: 0 */
/* eslint no-nested-ternary: 0 */

const memberAccessServiceUrl = `${process.env.VUE_APP_WEB_URI}/services/tfbic.services.restmember.access/rest_memberaccess.svc`;
const memberLookupServiceUrl = `${process.env.VUE_APP_WEB_URI}/services/tfbic.services.restmember.lookup`;
const memberLookupAgentServiceUrl = `${process.env.VUE_APP_WEB_URI}/services/tfbic.services.restmember.agent/rest_memberagent.svc`;
const ssoIdentityServiceUrl = `${process.env.VUE_APP_WEB_URI}/sso-api/identity`;

const tokenName = process.env.VUE_APP_WEB_ENV !== 'PROD' ? `tfb_${process.env.VUE_APP_WEB_ENV}_token` : 'tfb_token';
const invoiceCloudTokenName = process.env.VUE_APP_WEB_ENV !== 'PROD' ? `tfb_ic_${process.env.VUE_APP_WEB_ENV}_token` : 'tfb_ic_token';

const config = {
  crossdomain: true,
  withCredentials: true,
  headers: { 'Access-Control-Allow-Origin': '*', Accept: 'application/json; odata=verbose', 'Content-Type': 'application/json; odata=verbose' },
};

const functions = {
  isEmailAddress(str) {
    // eslint-disable-next-line no-useless-escape
    const regEx = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/igm);
    return regEx.test(str);
  },
};

function getDefaultState() {
  return {
    loggedInStatus: false,
    agentNumber: '',
    communicationIndicator: false,
    conversionIndicator: false,
    delinquentIndicator: null,
    verifiedIndicator: false,
    emailAddress: '',
    pendingEmailAddress: '',
    lastLoginTimestamp: '',
    memberAddress: null,
    memberList: null,
    memberName: '',
    memberFirstName: '',
    memberLastName: '',
    memberNumber: '',
    memberId: '',
    resetIndicator: false,
    role: 'I',
    userName: '',
    removeMemberRequest: null,
    contactList: [],
    showPhoneVerification: false,
    showPhoneAdd: false,
    showTextEnable: false,
    invoiceCloudToken: null,
  };
}

// state
const state = getDefaultState();

// mutations
const mutations = {
  CLEAR_GUIDEWIRE_TOKEN(state) {
    Vue.$cookies.remove(invoiceCloudTokenName);
    state.invoiceCloudToken = null;
  },
  SET_CONTACT_LIST(state, value) {
    state.contactList = value;
  },
  SET_SHOW_PHONE_VERIFICATION(state, value) {
    state.showPhoneVerification = value;
  },
  SET_SHOW_PHONE_ADD(state, value) {
    state.showPhoneAdd = value;
  },
  SET_SHOW_TEXT_ENABLE(state, value) {
    state.showTextEnable = value;
  },
  SET_AGENT_NUMBER(state, value) {
    const tfbInsured = sessionStorage.getItem('tfb_insured');

    if (tfbInsured) {
      const parsedTfbInsured = JSON.parse(tfbInsured);
      parsedTfbInsured.agentNumber = value;
      const parsed = JSON.stringify(parsedTfbInsured);
      sessionStorage.setItem('tfb_insured', parsed);
    }

    state.agentNumber = value;
  },
  SET_COMMUNICATION_INDICATOR(state, value) {
    const tfbInsured = sessionStorage.getItem('tfb_insured');

    if (tfbInsured) {
      const parsedTfbInsured = JSON.parse(tfbInsured);
      parsedTfbInsured.communicationIndicator = value;
      const parsed = JSON.stringify(parsedTfbInsured);
      sessionStorage.setItem('tfb_insured', parsed);
    }

    state.communicationIndicator = value;
  },
  SET_CONVERSION_INDICATOR(state, value) {
    const tfbInsured = sessionStorage.getItem('tfb_insured');

    if (tfbInsured) {
      const parsedTfbInsured = JSON.parse(tfbInsured);
      parsedTfbInsured.conversionIndicator = value;
      const parsed = JSON.stringify(parsedTfbInsured);
      sessionStorage.setItem('tfb_insured', parsed);
    }

    state.conversionIndicator = value;
  },
  SET_DELINQUENT_INDICATOR(state, value) {
    const tfbInsured = sessionStorage.getItem('tfb_insured');

    if (tfbInsured) {
      const parsedTfbInsured = JSON.parse(tfbInsured);
      parsedTfbInsured.delinquentIndicator = value;
      const parsed = JSON.stringify(parsedTfbInsured);
      sessionStorage.setItem('tfb_insured', parsed);
    }

    state.delinquentIndicator = value;
  },
  SET_EMAIL_ADDRESS(state, value) {
    const tfbInsured = sessionStorage.getItem('tfb_insured');

    if (tfbInsured) {
      const parsedTfbInsured = JSON.parse(tfbInsured);
      parsedTfbInsured.emailAddress = value;
      const parsed = JSON.stringify(parsedTfbInsured);
      sessionStorage.setItem('tfb_insured', parsed);
    }

    state.emailAddress = value;
  },
  SET_INVOICE_CLOUD_TOKEN(state, value) {
    Vue.$cookies.set(invoiceCloudTokenName, value, '1d');
    state.invoiceCloudToken = value;
  },
  SET_PENDING_EMAIL_ADDRESS(state, value) {
    const tfbInsured = sessionStorage.getItem('tfb_insured');

    if (tfbInsured) {
      const parsedTfbInsured = JSON.parse(tfbInsured);
      parsedTfbInsured.pendingEmailAddress = value;
      const parsed = JSON.stringify(parsedTfbInsured);
      sessionStorage.setItem('tfb_insured', parsed);
    }

    state.pendingEmailAddress = value;
  },
  SET_LAST_LOGIN_TIMESTAMP(state, value) {
    const tfbInsured = sessionStorage.getItem('tfb_insured');

    if (tfbInsured) {
      const parsedTfbInsured = JSON.parse(tfbInsured);
      parsedTfbInsured.lastLoginTimestamp = value;
      const parsed = JSON.stringify(parsedTfbInsured);
      sessionStorage.setItem('tfb_insured', parsed);
    }

    state.lastLoginTimestamp = value;
  },
  SET_LOGGED_IN_STATUS(state, value) {
    state.loggedInStatus = value;
  },
  SET_MEMBER_ADDRESS(state, value) {
    const tfbInsured = sessionStorage.getItem('tfb_insured');

    if (tfbInsured) {
      const parsedTfbInsured = JSON.parse(tfbInsured);
      parsedTfbInsured.memberAddress = value;
      const parsed = JSON.stringify(parsedTfbInsured);
      sessionStorage.setItem('tfb_insured', parsed);
    }

    state.memberAddress = value;
  },
  SET_MEMBER_ID(state, value) {
    const tfbInsured = sessionStorage.getItem('tfb_insured');

    if (tfbInsured) {
      const parsedTfbInsured = JSON.parse(tfbInsured);
      parsedTfbInsured.memberId = value;
      const parsed = JSON.stringify(parsedTfbInsured);
      sessionStorage.setItem('tfb_insured', parsed);
    }

    state.memberId = value;
  },
  SET_MEMBER_LIST(state, value) {
    sessionStorage.setItem('tfb_members', JSON.stringify(value));
    state.memberList = value;
  },
  SET_MEMBER_NAME(state, value) {
    const tfbInsured = sessionStorage.getItem('tfb_insured');

    if (tfbInsured) {
      const parsedTfbInsured = JSON.parse(tfbInsured);
      parsedTfbInsured.memberName = value;
      const parsed = JSON.stringify(parsedTfbInsured);
      sessionStorage.setItem('tfb_insured', parsed);
    }

    state.memberName = value;
  },
  SET_MEMBER_FIRST_NAME(state, value) {
    const tfbInsured = sessionStorage.getItem('tfb_insured');

    if (tfbInsured) {
      const parsedTfbInsured = JSON.parse(tfbInsured);
      parsedTfbInsured.memberFirstName = value;
      const parsed = JSON.stringify(parsedTfbInsured);
      sessionStorage.setItem('tfb_insured', parsed);
    }

    state.memberFirstName = value;
  },
  SET_MEMBER_LAST_NAME(state, value) {
    const tfbInsured = sessionStorage.getItem('tfb_insured');

    if (tfbInsured) {
      const parsedTfbInsured = JSON.parse(tfbInsured);
      parsedTfbInsured.memberLastName = value;
      const parsed = JSON.stringify(parsedTfbInsured);
      sessionStorage.setItem('tfb_insured', parsed);
    }

    state.memberLastName = value;
  },
  SET_MEMBER_NUMBER(state, value) {
    const tfbInsured = sessionStorage.getItem('tfb_insured');

    if (tfbInsured) {
      const parsedTfbInsured = JSON.parse(tfbInsured);
      parsedTfbInsured.memberNumber = value;
      const parsed = JSON.stringify(parsedTfbInsured);
      sessionStorage.setItem('tfb_insured', parsed);
    }

    state.memberNumber = value;
  },
  SET_REMOVE_MEMBER_REQUEST(state, value) {
    state.removeMemberRequest = value;
  },
  SET_RESET_INDICATOR(state, value) {
    const tfbInsured = sessionStorage.getItem('tfb_insured');

    if (tfbInsured) {
      const parsedTfbInsured = JSON.parse(tfbInsured);
      parsedTfbInsured.resetIndicator = value;
      const parsed = JSON.stringify(parsedTfbInsured);
      sessionStorage.setItem('tfb_insured', parsed);
    }

    state.resetIndicator = value;
  },
  SET_ROLE(state, value) {
    const tfbInsured = sessionStorage.getItem('tfb_insured');

    if (tfbInsured) {
      const parsedTfbInsured = JSON.parse(tfbInsured);
      parsedTfbInsured.role = value;
      const parsed = JSON.stringify(parsedTfbInsured);
      sessionStorage.setItem('tfb_insured', parsed);
    }

    state.role = value;
  },
  SET_USER_NAME(state, value) {
    const tfbInsured = sessionStorage.getItem('tfb_insured');

    if (tfbInsured) {
      const parsedTfbInsured = JSON.parse(tfbInsured);
      parsedTfbInsured.userName = value;
      const parsed = JSON.stringify(parsedTfbInsured);
      sessionStorage.setItem('tfb_insured', parsed);
    }

    state.userName = value;
  },
  SET_VERIFIED_INDICATOR(state, value) {
    const tfbInsured = sessionStorage.getItem('tfb_insured');

    if (tfbInsured) {
      const parsedTfbInsured = JSON.parse(tfbInsured);
      parsedTfbInsured.verifiedIndicator = value;
      const parsed = JSON.stringify(parsedTfbInsured);
      sessionStorage.setItem('tfb_insured', parsed);
    }

    state.verifiedIndicator = value;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

// getters
const getters = {
  ...make.getters(state),
  memberNumber(state) {
    if (!state.memberNumber || state.memberNumber === null) {
      const tfbInsured = sessionStorage.getItem('tfb_insured');
      if (tfbInsured) {
        const parsedTfbInsured = JSON.parse(tfbInsured);
        state.memberNumber = parsedTfbInsured.memberNumber;
      }
    }

    return state.memberNumber;
  },
  invoiceCloudToken(state) {
    if (!state.invoiceCloudToken || state.invoiceCloudToken === null) {
      state.invoiceCloudToken = Vue.$cookies.get(invoiceCloudTokenName);
    }

    return state.invoiceCloudToken;
  },
};

// actions
const actions = {
  validateLogin(context, sRequest) {
    return new Promise((resolve) => {
      // Is information populated in the state?
      if (context.state.loggedInStatus && context.state.lastLoginTimestamp !== '') {
        const loggedInTime = differenceInHours(new Date(), parseISO(context.state.lastLoginTimestamp));

        if (loggedInTime > 4) {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: 'Auto LogOut: State Timestamp GT 4 Hours', fileName: 'member.js', methodName: 'validateLogin-Exit', trackerId: sRequest.sTfbId,
          }, { root: true });
          // Session is no good
          resolve(false);
        } else {
          // Make sure the information is up to date
          context.dispatch('validateInformation', { sMemberNumber: context.state.memberNumber, sTfbId: sRequest.sTfbId });
          resolve(true);
        }
      } else {
        // Is information populated in the SessionStorage?
        const tfbInsured = sessionStorage.getItem('tfb_insured');
        const tfbMembers = sessionStorage.getItem('tfb_members');

        if (tfbInsured) {
          const tfbParsedInsured = JSON.parse(tfbInsured);
          const tfbParsedMembers = JSON.parse(tfbMembers);

          const loggedInTime = differenceInHours(new Date(), parseISO(tfbParsedInsured.lastLoginTimestamp));

          if (loggedInTime > 4) {
            context.dispatch('app/logToULS', {
              logLevel: 'Info', logMessage: 'Auto LogOut: Session Timestamp GT 4 Hours', fileName: 'member.js', methodName: 'validateLogin-Exit', trackerId: sRequest.sTfbId,
            }, { root: true });
            // Session is no good
            resolve(false);
          }

          if (tfbParsedInsured.memberName && tfbParsedInsured.memberName !== '') {
            const memberSplitName = tfbParsedInsured.memberName.split(' ');
            if (memberSplitName && memberSplitName.length > 0) {
              context.commit('SET_MEMBER_FIRST_NAME', memberSplitName[0]);
              context.commit('SET_MEMBER_LAST_NAME', memberSplitName[1]);
            } else {
              context.commit('SET_MEMBER_FIRST_NAME', '');
              context.commit('SET_MEMBER_LAST_NAME', '');
            }
          } else {
            context.commit('SET_MEMBER_FIRST_NAME', '');
            context.commit('SET_MEMBER_LAST_NAME', '');
          }

          context.commit('SET_LOGGED_IN_STATUS', true);
          context.commit('SET_ROLE', tfbParsedInsured.role);
          context.commit('SET_LAST_LOGIN_TIMESTAMP', tfbParsedInsured.lastLoginTimestamp);
          context.commit('SET_EMAIL_ADDRESS', tfbParsedInsured.emailAddress);
          context.commit('SET_USER_NAME', tfbParsedInsured.userName);
          context.commit('SET_MEMBER_NUMBER', tfbParsedInsured.memberNumber);
          context.commit('SET_MEMBER_ID', tfbParsedInsured.memberId);
          context.commit('SET_RESET_INDICATOR', tfbParsedInsured.resetIndicator);
          context.commit('SET_VERIFIED_INDICATOR', tfbParsedInsured.verifiedIndicator);
          context.commit('SET_COMMUNICATION_INDICATOR', tfbParsedInsured.communicationIndicator);
          context.commit('SET_MEMBER_NAME', tfbParsedInsured.memberName);
          context.commit('SET_AGENT_NUMBER', tfbParsedInsured.agentNumber);
          context.commit('SET_MEMBER_ADDRESS', tfbParsedInsured.memberAddress);
          context.commit('SET_CONVERSION_INDICATOR', tfbParsedInsured.conversionIndicator);
          context.commit('SET_MEMBER_LIST', tfbParsedMembers);

          // Make sure the information is up to date
          context.dispatch('validateInformation', { sMemberNumber: tfbParsedInsured.memberNumber, sTfbId: sRequest.sTfbId });
          resolve(true);
        } else {
          // Is there a SSO cookie/token?
          const ssoCookie = Vue.$cookies.get(tokenName);
          const ssoToken = sRequest.sToken;

          if (ssoCookie || ssoToken) {
            let ssoValue = '';
            if (ssoCookie) {
              ssoValue = ssoCookie;
            } else {
              ssoValue = ssoToken;
              Vue.$cookies.set(tokenName, sRequest.sToken, '1d');
            }
            // config.headers.Authorization = `Bearer ${ssoCookie}`;
            axios.get(`${ssoIdentityServiceUrl}?access_token=${ssoValue}`, config)
              .then((identityResponse) => {
                // console.log(`data:${identityResponse.data}`);
                // const parsedIdentityResponse = JSON.parse(identityResponse.data);
                // console.log(parsedIdentityResponse);
                const lastLogin = new Date().toISOString();
                context.commit('SET_LAST_LOGIN_TIMESTAMP', lastLogin);
                context.commit('SET_LOGGED_IN_STATUS', true);

                for (let i = 0; i < identityResponse.data.length; i += 1) {
                  const identityItem = identityResponse.data[i];
                  if (identityItem.type === 'insured_id') {
                    context.commit('SET_MEMBER_ID', identityItem.value);
                    // Make sure the information is up to date
                    context.dispatch('validateInformation', { sMemberNumber: context.state.memberNumber, sTfbId: sRequest.sTfbId });
                  } else if (identityItem.type === 'current_member') {
                    context.commit('SET_MEMBER_NUMBER', identityItem.value);
                    // Make sure the information is up to date
                    context.dispatch('validateInformation', { sMemberNumber: identityItem.value, sTfbId: sRequest.sTfbId });

                    // Get member information
                    context.dispatch('getMemberInformation', { sMemberNumber: identityItem.value, sTfbId: '' })
                      .then((memberResults) => {
                        if (memberResults && (!memberResults.ErrorMessage || memberResults.ErrorMessage === null || memberResults.ErrorMessage === '')) {
                          // Rebuild session
                          context.commit('SET_MEMBER_NAME', memberResults.PrimaryMemberName);
                          context.commit('SET_AGENT_NUMBER', memberResults.AgentCode);
                          context.commit('SET_MEMBER_ADDRESS', memberResults.MemberAddress);

                          if (!memberResults.AgentCode || memberResults.AgentCode === '' || memberResults.AgentCode === null || memberResults.AgentCode === '99997') {
                            // See if we can get the agent number
                            context.dispatch('agentcounty/getAgentCodeByMember', {
                              sMemberNumber: identityItem.value, trackerId: sRequest.sTfbId,
                            }, { root: true })
                              .then((response) => {
                                if (response._agentCode) {
                                  if (response._agentCode === '99997') {
                                    // Pool account agent, so don't use it
                                    context.commit('SET_AGENT_NUMBER', '');
                                  } else {
                                    // Save code
                                    context.commit('SET_AGENT_NUMBER', response._agentCode);
                                  }
                                }
                              })
                              .catch(() => {
                                // Do nothing
                              });
                          }

                          if (memberResults.PrimaryMemberName && memberResults.PrimaryMemberName !== '') {
                            const memberSplitName = memberResults.PrimaryMemberName.split(' ');
                            if (memberSplitName && memberSplitName.length > 0) {
                              context.commit('SET_MEMBER_FIRST_NAME', memberSplitName[0]);
                              context.commit('SET_MEMBER_LAST_NAME', memberSplitName[1]);
                            } else {
                              context.commit('SET_MEMBER_FIRST_NAME', '');
                              context.commit('SET_MEMBER_LAST_NAME', '');
                            }
                          } else {
                            context.commit('SET_MEMBER_FIRST_NAME', '');
                            context.commit('SET_MEMBER_LAST_NAME', '');
                          }

                          resolve('');
                        } else {
                          context.dispatch('app/logToULS', {
                            logLevel: 'Error', logMessage: JSON.stringify(memberResults), fileName: 'member.js', methodName: 'validateLogin-MemberReturnError', trackerId: sRequest.sTfbId,
                          }, { root: true });
                          context.commit('SET_MEMBER_NAME', '');
                          context.commit('SET_AGENT_NUMBER', '');
                          context.commit('SET_MEMBER_ADDRESS', null);
                          context.commit('SET_MEMBER_FIRST_NAME', '');
                          context.commit('SET_MEMBER_LAST_NAME', '');
                          resolve('');
                        }
                      })
                      .catch((error) => {
                        context.dispatch('app/logToULS', {
                          logLevel: 'Error', logMessage: error, fileName: 'member.js', methodName: 'validateLogin-Error', trackerId: sRequest.sTfbId,
                        }, { root: true });
                        context.commit('SET_MEMBER_NAME', '');
                        context.commit('SET_AGENT_NUMBER', '');
                        context.commit('SET_MEMBER_ADDRESS', null);
                        context.commit('SET_MEMBER_FIRST_NAME', '');
                        context.commit('SET_MEMBER_LAST_NAME', '');
                        resolve('');
                      });
                  } else if (identityItem.type === 'member_list') {
                    const finalMemberList = [];
                    const memberList = identityItem.value.split(';');
                    for (let m = 0; m < memberList.length; m += 1) {
                      const memberListItem = memberList[m];
                      const memberListItemDetails = memberListItem.split('-');
                      finalMemberList.push({ Id: memberListItemDetails[0], Nbr: memberListItemDetails[1] });
                    }
                    context.commit('SET_MEMBER_LIST', finalMemberList);
                  }
                }

                resolve(true);
              })
              .catch((error) => {
                context.dispatch('app/logToULS', {
                  logLevel: 'Info', logMessage: `Auto LogOut: Token No Good: ${error}`, fileName: 'member.js', methodName: 'validateLogin-Exit', trackerId: sRequest.sTfbId,
                }, { root: true });
                // Cookie is no good
                Vue.$cookies.remove(tokenName);
                Vue.$cookies.remove(invoiceCloudTokenName);
                resolve(false);
              });
          } else {
            resolve(false);
          }
        }
      }
    });
  },
  validateInformation(context, sRequest) {
    // Make sure we have the latest email address/verification information
    return new Promise((resolve, reject) => {
      if (sRequest && sRequest.sMemberNumber && sRequest.sMemberNumber !== null) {
        const ssoToken = Vue.$cookies.get(tokenName);
        config.headers.Authorization = `Bearer ${ssoToken}`;
        axios.get(`${memberAccessServiceUrl}/fetch-login/${sRequest.sMemberNumber}`, config)
          .then((loginResults) => {
            // console.log(loginResults.data);
            for (let i = 0; i < loginResults.data.length; i += 1) {
              const user = loginResults.data[i];
              if (user.InsuredId.toString().trim().toLowerCase() === context.state.memberId.toString().trim().toLowerCase()) {
                // This is the member we're looking for, make sure we have the updated information
                context.commit('SET_EMAIL_ADDRESS', user.EmailAddress);
                context.commit('SET_USER_NAME', user.UserName);
                context.commit('SET_VERIFIED_INDICATOR', (user.EmailVerificationIndicator === 'Y' ? !!true : false));
                context.commit('SET_PENDING_EMAIL_ADDRESS', user.PendingEmailChange);
                context.commit('SET_COMMUNICATION_INDICATOR', (user.CommunicationOption === 'Y' ? !!true : false));
                context.commit('SET_RESET_INDICATOR', (user.PasswordResetIndicator === 'Y' ? !!true : false));
                context.commit('SET_CONVERSION_INDICATOR', (functions.isEmailAddress(user.UserName) ? !!false : true));
              }
            }

            resolve();
          })
          .catch((error) => {
            resolve();
          });
      }

      resolve();
    });
  },
  doLogin(context, loginRequest) {
    return new Promise((resolve, reject) => {
      context.dispatch('app/logToULS', {
        logLevel: 'Info', logMessage: `Starting login: ${loginRequest.sUserName}`, fileName: 'member.js', methodName: 'doLogin-Begin', trackerId: loginRequest.sTfbId,
      }, { root: true });
      axios.post(`${memberAccessServiceUrl}/SecuredLogin`, {
        Password: loginRequest.sPassword,
        Username: loginRequest.sUserName,
        GoogleCaptchaToken: loginRequest.sCatchaText,
      }, config)
        .then((loginResult) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: JSON.stringify(loginResult), fileName: 'member.js', methodName: 'doLogin-Result', trackerId: loginRequest.sTfbId,
          }, { root: true });
          if (loginResult && loginResult.data && loginResult.data.AccessToken && loginResult.data.AccessToken !== null) {
            if (loginResult.data.ErrorMessage === null || loginResult.data.ErrorMessage === '' || loginResult.data.ErrorMessage === 'Unable to locate agent.') {
              Vue.$cookies.set(tokenName, loginResult.data.AccessToken, '1d');
              context.commit('SET_LOGGED_IN_STATUS', true);
              context.commit('SET_INVOICE_CLOUD_TOKEN', loginResult.data.InvoiceCloudAccessToken);
              context.commit('SET_LAST_LOGIN_TIMESTAMP', new Date().toISOString());
              context.commit('SET_ROLE', 'I');
              context.commit('SET_EMAIL_ADDRESS', loginResult.data.MemberEmailAddress);
              context.commit('SET_PENDING_EMAIL_ADDRESS', loginResult.data.MemberPendingEmailAddress);
              context.commit('SET_USER_NAME', loginResult.data.UserName);
              context.commit('SET_RESET_INDICATOR', (loginResult.data.PasswordResetFlag === 'True' ? !!true : false));
              context.commit('SET_VERIFIED_INDICATOR', (loginResult.data.EmailVerified === 'True' ? !!true : false));
              context.commit('SET_COMMUNICATION_INDICATOR', (loginResult.data.CommunicationPreferred === 'Y' ? !!true : false));
              context.commit('SET_MEMBER_NAME', loginResult.data.MemberName);
              context.commit('SET_AGENT_NUMBER', loginResult.data.AgentNumber);
              context.commit('SET_MEMBER_ADDRESS', loginResult.data.MemberAddress);
              context.commit('SET_CONVERSION_INDICATOR', (functions.isEmailAddress(loginResult.data.UserName) ? !!false : true));

              // See if this is one of the weird email address usernames but no email address
              if (loginResult.data.MemberEmailAddress === null || loginResult.data.MemberEmailAddress === '') {
                if (functions.isEmailAddress(loginResult.data.UserName)) {
                  // Set the email address to the username
                  context.commit('SET_EMAIL_ADDRESS', loginResult.data.UserName);
                }
              }

              const memberList = [];
              if (loginResult.data.Members && loginResult.data.Members !== null && loginResult.data.Members.length > 0) {
                context.commit('SET_MEMBER_ID', loginResult.data.Members[0].MemberIDNumber);
                context.commit('SET_MEMBER_NUMBER', loginResult.data.Members[0].LongMemberNumber && loginResult.data.Members[0].LongMemberNumber !== ''
                  ? loginResult.data.Members[0].LongMemberNumber : loginResult.data.Members[0].MemberNumber);

                for (let i = 0; i < loginResult.data.Members.length; i += 1) {
                  const member = loginResult.data.Members[i];
                  if (member.LongMemberNumber === undefined || member.LongMemberNumber === null || member.LongMemberNumber === '') {
                    memberList.push({ Id: member.MemberIDNumber, Nbr: member.MemberNumber, Lng: member.MemberNumber });
                  } else {
                    memberList.push({ Id: member.MemberIDNumber, Nbr: member.MemberNumber, Lng: member.LongMemberNumber });
                  }
                }
              }
              context.commit('SET_MEMBER_LIST', memberList);

              if (!loginResult.data.AgentNumber || loginResult.data.AgentNumber === '' || loginResult.data.AgentNumber === null || loginResult.data.AgentNumber === '99997'
                && (loginResult.data.Members && loginResult.data.Members !== null && loginResult.data.Members.length > 0)) {
                // See if we can get the agent number
                context.dispatch('agentcounty/getAgentCodeByMember', {
                  sMemberNumber: loginResult.data.Members[0].MemberNumber, trackerId: loginRequest.sTfbId,
                }, { root: true })
                  .then((response) => {
                    if (response._agentCode) {
                      // Save code
                      context.commit('SET_AGENT_NUMBER', response._agentCode);
                    }
                  })
                  .catch(() => {
                    // Do nothing
                  });
              }

              if (loginResult.data.MemberName && loginResult.data.MemberName !== '') {
                const memberSplitName = loginResult.data.MemberName.split(' ');
                if (memberSplitName && memberSplitName.length > 0) {
                  context.commit('SET_MEMBER_FIRST_NAME', memberSplitName[0]);
                  context.commit('SET_MEMBER_LAST_NAME', memberSplitName[1]);
                } else {
                  context.commit('SET_MEMBER_FIRST_NAME', '');
                  context.commit('SET_MEMBER_LAST_NAME', '');
                }
              } else {
                context.commit('SET_MEMBER_FIRST_NAME', '');
                context.commit('SET_MEMBER_LAST_NAME', '');
              }

              const parsed = JSON.stringify({
                role: 'I',
                lastLoginTimestamp: new Date().toISOString(),
                emailAddress: loginResult.data.MemberEmailAddress,
                userName: loginResult.data.UserName,
                memberId: (loginResult.data.Members && loginResult.data.Members !== null && loginResult.data.Members.length > 0) ? loginResult.data.Members[0].MemberIDNumber : '',
                memberNumber: (loginResult.data.Members && loginResult.data.Members !== null && loginResult.data.Members.length > 0)
                  ? (loginResult.data.Members[0].LongMemberNumber && loginResult.data.Members[0].LongMemberNumber !== ''
                    ? loginResult.data.Members[0].LongMemberNumber : loginResult.data.Members[0].MemberNumber) : '',
                memberName: loginResult.data.MemberName,
                agentNumber: loginResult.data.AgentNumber,
                resetIndicator: (loginResult.data.PasswordResetFlag === 'True' ? !!true : false),
                verifiedIndicator: (loginResult.data.EmailVerified === 'True' ? !!true : false),
                communicationIndicator: (loginResult.data.CommunicationPreferred === 'Y' ? !!true : false),
                conversionIndicator: (functions.isEmailAddress(loginResult.data.UserName)
                  ? !!false : true),
                delinquentIndicator: false,
              });
              sessionStorage.setItem('tfb_insured', parsed);

              context.dispatch('app/logToULS', {
                logLevel: 'Info', logMessage: 'Complete', fileName: 'member.js', methodName: 'doLogin-Complete', trackerId: loginRequest.sTfbId,
              }, { root: true });

              resolve('');
            } else if (loginResult.data.ErrorMessage !== null || loginResult.data.ErrorMessage !== '') {
                resolve(loginResult.data.ErrorMessage);
            } else {
                reject(new Error(genericErrorMessage));
            }
          } else if (loginResult.data.ErrorMessage !== null || loginResult.data.ErrorMessage !== '') {
            resolve(loginResult.data.ErrorMessage);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'member.js', methodName: 'doLogin-Error', trackerId: loginRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  doSsoLogin(context, loginRequest) {
    return new Promise((resolve, reject) => {
      context.dispatch('app/logToULS', {
        logLevel: 'Info', logMessage: `Starting login: ${loginRequest.sAccessToken}`, fileName: 'member.js', methodName: 'doSsoLogin-Begin', trackerId: loginRequest.sTfbId,
      }, { root: true });
      axios.post(`${memberAccessServiceUrl}/SSOLogin`, {
        AccessToken: loginRequest.sAccessToken,
      }, config)
        .then((loginResult) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: JSON.stringify(loginResult.data), fileName: 'member.js', methodName: 'doSsoLogin-Result', trackerId: loginRequest.sTfbId,
          }, { root: true });
          if (loginResult && loginResult.data) {
            if (loginResult.data.ErrorMessage === null || loginResult.data.ErrorMessage === '' || loginResult.data.ErrorMessage === 'Unable to locate agent.') {
              Vue.$cookies.set(tokenName, loginRequest.sAccessToken, '1d');
              context.commit('SET_LOGGED_IN_STATUS', true);
              context.commit('SET_LAST_LOGIN_TIMESTAMP', new Date().toISOString());
              context.commit('SET_ROLE', 'I');
              context.commit('SET_EMAIL_ADDRESS', loginResult.data.MemberEmailAddress);
              context.commit('SET_USER_NAME', loginResult.data.UserName);
              context.commit('SET_MEMBER_ID', loginResult.data.Members[0].MemberIDNumber);
              context.commit('SET_MEMBER_NUMBER', loginResult.data.Members[0].MemberNumber);
              context.commit('SET_RESET_INDICATOR', (loginResult.data.PasswordResetFlag === 'True' ? !!true : false));
              context.commit('SET_VERIFIED_INDICATOR', (loginResult.data.EmailVerified === 'True' ? !!true : false));
              context.commit('SET_COMMUNICATION_INDICATOR', (loginResult.data.CommunicationPreferred === 'Y' ? !!true : false));
              context.commit('SET_MEMBER_NAME', loginResult.data.MemberName);
              context.commit('SET_AGENT_NUMBER', loginResult.data.AgentNumber);
              context.commit('SET_MEMBER_ADDRESS', loginResult.data.MemberAddress);
              context.commit('SET_CONVERSION_INDICATOR', (functions.isEmailAddress(loginResult.data.UserName) ? !!false : true));

              if (!loginResult.data.AgentNumber || loginResult.data.AgentNumber === '' || loginResult.data.AgentNumber === null || loginResult.data.AgentNumber === '99997') {
                // See if we can get the agent number
                context.dispatch('agentcounty/getAgentCodeByMember', {
                  sMemberNumber: loginResult.data.Members[0].MemberNumber, trackerId: loginRequest.sTfbId,
                }, { root: true })
                  .then((response) => {
                    if (response._agentCode) {
                      // Save code
                      context.commit('SET_AGENT_NUMBER', response._agentCode);
                    }
                  })
                  .catch(() => {
                    // Do nothing
                  });
              }

              const memberList = [];
              if (loginResult.data.Members && loginResult.data.Members !== null && loginResult.data.Members.length > 0) {
                context.commit('SET_MEMBER_ID', loginResult.data.Members[0].MemberIDNumber);
                context.commit('SET_MEMBER_NUMBER', loginResult.data.Members[0].MemberNumber);

                for (let i = 0; i < loginResult.data.Members.length; i += 1) {
                  const member = loginResult.data.Members[i];
                  memberList.push({ Id: member.MemberIDNumber, Nbr: member.MemberNumber });
                }
              }
              context.commit('SET_MEMBER_LIST', memberList);

              if (loginResult.data.MemberName && loginResult.data.MemberName !== '') {
                const memberSplitName = loginResult.data.MemberName.split(' ');
                if (memberSplitName && memberSplitName.length > 0) {
                  context.commit('SET_MEMBER_FIRST_NAME', memberSplitName[0]);
                  context.commit('SET_MEMBER_LAST_NAME', memberSplitName[1]);
                } else {
                  context.commit('SET_MEMBER_FIRST_NAME', '');
                  context.commit('SET_MEMBER_LAST_NAME', '');
                }
              } else {
                context.commit('SET_MEMBER_FIRST_NAME', '');
                context.commit('SET_MEMBER_LAST_NAME', '');
              }

              const parsed = JSON.stringify({
                role: 'I',
                lastLoginTimestamp: new Date().toISOString(),
                emailAddress: loginResult.data.MemberEmailAddress,
                userName: loginResult.data.UserName,
                memberId: loginResult.data.Members[0].MemberIDNumber,
                memberNumber: loginResult.data.Members[0].MemberNumber,
                memberName: loginResult.data.MemberName,
                agentNumber: loginResult.data.AgentNumber,
                resetIndicator: (loginResult.data.PasswordResetFlag === 'True' ? !!true : false),
                verifiedIndicator: (loginResult.data.EmailVerified === 'True' ? !!true : false),
                communicationIndicator: (loginResult.data.CommunicationPreferred === 'Y' ? !!true : false),
                conversionIndicator: (functions.isEmailAddress(loginResult.data.UserName)
                  ? !!false : true),
                delinquentIndicator: false,
              });
              sessionStorage.setItem('tfb_insured', parsed);

              context.dispatch('app/logToULS', {
                logLevel: 'Info', logMessage: 'Complete', fileName: 'member.js', methodName: 'doSsoLogin-Complete', trackerId: loginRequest.sTfbId,
              }, { root: true });

              resolve('');
            } else if (loginResult.data.ErrorMessage !== null || loginResult.data.ErrorMessage !== '') {
              resolve(loginResult.data.ErrorMessage);
            } else {
              reject(new Error(genericErrorMessage));
            }
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'member.js', methodName: 'doSsoLogin-Error', trackerId: loginRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  doAgentLogin(context, loginRequest) {
    return new Promise((resolve, reject) => {
      context.dispatch('app/logToULS', {
        logLevel: 'Info',
        logMessage: `Starting login for ${loginRequest.sUser}: ${loginRequest.sMemberNumber} in ${loginRequest.sCountyCode}`,
        fileName: 'member.js',
        methodName: 'doAgentLogin-Begin',
        trackerId: loginRequest.sTfbId,
      }, { root: true });
      axios.get(`${memberLookupAgentServiceUrl}/MemberCountyValidation/${loginRequest.sCountyCode}/${loginRequest.sMemberNumber}`, config)
        .then((loginResult) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: JSON.stringify(loginResult.data), fileName: 'member.js', methodName: 'doAgentLogin-ValidationResult', trackerId: loginRequest.sTfbId,
          }, { root: true });
          if (loginResult && loginResult.data && (loginResult.data.ErrorMessage === null || loginResult.data.ErrorMessage === '')) {
            Vue.$cookies.set(tokenName, loginResult.data.AccessToken, '1d');
            const lastLogin = new Date().toISOString();
            const hasUser = loginRequest.sUser && loginRequest.sUser !== null && loginRequest.sUser !== '';
            const agentEmailRaw = hasUser ? `${loginRequest.sUser}@txfb-ins.com` : `${loginRequest.sMemberNumber}@TXFBAgent.com`;
            const agentEmail = helpers.methods.replaceAll(agentEmailRaw, '@txfb-ins.com@txfb-ins.com', '@txfb-ins.com');
            const agentName = helpers.methods.replaceAll(agentEmail, '@txfb-ins.com', '');
            const memberList = [{ Id: '0', Nbr: loginRequest.sMemberNumber }];

            context.commit('SET_LAST_LOGIN_TIMESTAMP', lastLogin);
            context.commit('SET_ROLE', 'A');
            context.commit('SET_EMAIL_ADDRESS', agentEmail);
            context.commit('SET_USER_NAME', agentName);
            context.commit('SET_MEMBER_ID', '0');
            context.commit('SET_MEMBER_NUMBER', loginRequest.sMemberNumber);
            context.commit('SET_RESET_INDICATOR', false);
            context.commit('SET_VERIFIED_INDICATOR', true);
            context.commit('SET_COMMUNICATION_INDICATOR', false);
            context.commit('SET_CONVERSION_INDICATOR', false);
            context.commit('SET_MEMBER_LIST', memberList);

            config.headers.Authorization = `Bearer ${loginResult.data.AccessToken}`;
            axios.get(`${memberLookupServiceUrl}/basic/${loginRequest.sMemberNumber}`, config)
              .then((memberResults) => {
                if (memberResults && memberResults.data) {
                  context.commit('SET_MEMBER_NAME', hasUser ? agentName : 'TXFB Agent');
                  context.commit('SET_MEMBER_FIRST_NAME', hasUser ? agentName : 'TXFB');
                  context.commit('SET_MEMBER_LAST_NAME', hasUser ? agentName : 'Agent');
                  context.commit('SET_AGENT_NUMBER', memberResults.data.AgentCode);
                  context.commit('SET_MEMBER_ADDRESS', memberResults.data.MemberAddress);
                  context.commit('SET_LOGGED_IN_STATUS', true);

                  if (!memberResults.data.AgentCode || memberResults.data.AgentCode === '' || memberResults.data.AgentCode === null || memberResults.data.AgentCode === '99997') {
                    // See if we can get the agent number
                    context.dispatch('agentcounty/getAgentCodeByMember', {
                      sMemberNumber: loginRequest.sMemberNumber, trackerId: loginRequest.sTfbId,
                    }, { root: true })
                      .then((response) => {
                        if (response._agentCode) {
                          // Save code
                          context.commit('SET_AGENT_NUMBER', response._agentCode);
                        }
                      })
                      .catch(() => {
                        // Do nothing
                      });
                  }

                  const parsed = JSON.stringify({
                    role: 'A',
                    lastLoginTimestamp: lastLogin,
                    emailAddress: agentEmail,
                    userName: agentName,
                    memberId: '0',
                    memberNumber: loginRequest.sMemberNumber,
                    memberName: hasUser ? agentName : '',
                    agentNumber: memberResults.data.AgentCode,
                    resetIndicator: false,
                    verifiedIndicator: true,
                    communicationIndicator: false,
                    conversionIndicator: false,
                    delinquentIndicator: false,
                  });
                  sessionStorage.setItem('tfb_insured', parsed);

                  context.dispatch('app/logToULS', {
                    logLevel: 'Info', logMessage: 'Complete', fileName: 'member.js', methodName: 'doAgentLogin-Complete', trackerId: loginRequest.sTfbId,
                  }, { root: true });

                  resolve('');
                } else {
                  context.dispatch('app/logToULS', {
                    logLevel: 'Error', logMessage: JSON.stringify(memberResults), fileName: 'member.js', methodName: 'doAgentLogin-MemberReturnError', trackerId: loginRequest.sTfbId,
                  }, { root: true });
                  reject(new Error(memberResults));
                }
              })
              .catch((error) => {
                context.dispatch('app/logToULS', {
                  logLevel: 'Error', logMessage: error, fileName: 'member.js', methodName: 'doAgentLogin-MemberInfoError', trackerId: loginRequest.sTfbId,
                }, { root: true });
                reject(error);
              });
          } else if (loginResult && loginResult.data && (loginResult.data.ErrorMessage !== null || loginResult.data.ErrorMessage !== '')) {
            context.dispatch('app/logToULS', {
              logLevel: 'Error', logMessage: loginResult.data.ErrorMessage, fileName: 'member.js', methodName: 'doAgentLogin-ValidationReturnError', trackerId: loginRequest.sTfbId,
            }, { root: true });
            resolve(loginResult.data.ErrorMessage);
          } else {
            context.dispatch('app/logToULS', {
              logLevel: 'Error', logMessage: loginResult, fileName: 'member.js', methodName: 'doAgentLogin-ValidationError', trackerId: loginRequest.sTfbId,
            }, { root: true });
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'member.js', methodName: 'doAgentLogin-Error', trackerId: loginRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  doRegistrationIdVerification(context, verificationRequest) {
    return new Promise((resolve, reject) => {
      context.dispatch('app/logToULS', {
        logLevel: 'Info',
        logMessage: `Starting Verification: ${verificationRequest.sRegistrationId}`,
        fileName: 'member.js',
        methodName: 'doRegistrationIdVerification-Begin',
        trackerId: verificationRequest.sTfbId,
      }, { root: true });
      axios.post(`${memberLookupServiceUrl}/validate-registration`, { ValidationId: verificationRequest.sRegistrationId }, config)
        .then((verificationResult) => {
          if (verificationResult && verificationResult.data) {
            context.dispatch('app/logToULS', {
              logLevel: 'Info', logMessage: JSON.stringify(verificationResult.data), fileName: 'member.js', methodName: 'doRegistrationIdVerification-Result', trackerId: verificationRequest.sTfbId,
            }, { root: true });

            resolve(verificationResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'member.js', methodName: 'doRegistrationIdVerification-Error', trackerId: verificationRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  doRegistration(context, registrationRequest) {
    return new Promise((resolve, reject) => {
      context.dispatch('app/logToULS', {
        logLevel: 'Info',
        logMessage: `Starting registration: ${registrationRequest.sPolicyNumber}, ${registrationRequest.sMemberNumber.toUpperCase()}, ${registrationRequest.sUserName.toLowerCase()}`,
        fileName: 'member.js',
        methodName: 'doRegistration-Begin',
        trackerId: registrationRequest.sTfbId,
      }, { root: true });
      axios.post(`${memberAccessServiceUrl}/SecureRegistration`, {
        CommunicationOption: registrationRequest.sCommunication,
        EmailAddress: registrationRequest.sUserName.toLowerCase(),
        Password: registrationRequest.sPassword,
        PolicyNumber: registrationRequest.sPolicyNumber,
        MemberNumber: registrationRequest.sMemberNumber.toUpperCase(),
      }, config)
        .then((registrationResult) => {
          if (registrationResult && registrationResult.data) {
            context.dispatch('app/logToULS', {
              logLevel: 'Info', logMessage: JSON.stringify(registrationResult.data), fileName: 'member.js', methodName: 'doRegistration-Result', trackerId: registrationRequest.sTfbId,
            }, { root: true });

            if (!registrationResult.data.ErrorMessage || (registrationResult.data.ErrorMessage && (registrationResult.data.ErrorMessage === null || registrationResult.data.ErrorMessage === ''))) {
              resolve(context.dispatch('doLogin', {
                sUserName: registrationRequest.sUserName,
                sPassword: registrationRequest.sPassword,
                sCatchaText: 'kQMSVtPlYECzM1IX8WibEg',
                sTfbId: registrationRequest.sTfbId,
              }));
            } else if (registrationResult.data.ErrorMessage && (registrationResult.data.ErrorMessage !== null || registrationResult.data.ErrorMessage !== '')) {
              resolve(registrationResult.data.ErrorMessage);
            } else {
              reject(new Error(genericErrorMessage));
            }
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'member.js', methodName: 'doRegistration-Error', trackerId: registrationRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  doLogout(context) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.post(`${memberAccessServiceUrl}/Logout`, ssoToken, config)
        .then(() => {
          // Remove the cookies
          Vue.$cookies.remove(tokenName);
          context.commit('CLEAR_GUIDEWIRE_TOKEN', null);

          // Clear the session storage
          sessionStorage.removeItem('tfb_insured');
          sessionStorage.removeItem('tfb_members');
          // Reset the contact list
          context.commit('SET_CONTACT_LIST', null);

          // Clear values and reset the policy state
          context.commit('policy/CLEAR_ACCOUNT_SUMMARY', {}, { root: true });
          context.commit('policy/CLEAR_HAS_GUIDEWIRE_LEGACY', {}, { root: true });
          context.commit('policy/RESET_STATE', {}, { root: true });
          // Clear values and reset the payment state
          context.commit('payments/CLEAR_ACCOUNT_BILL_SUMMARY', {}, { root: true });
          context.commit('payments/RESET_STATE', {}, { root: true });
          // Reset the member state
          context.commit('RESET_STATE');

          resolve('');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  doForgotPassword(context, resetRequest) {
    return new Promise((resolve, reject) => {
      context.dispatch('app/logToULS', {
        logLevel: 'Info', logMessage: `Starting password reset: ${resetRequest.sUserName}`, fileName: 'member.js', methodName: 'doForgotPassword-Begin', trackerId: resetRequest.sTfbId,
      }, { root: true });
      axios.get(`${memberAccessServiceUrl}/ForgotPassword/${resetRequest.sUserName}`, config)
        .then((resetResult) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: JSON.stringify(resetResult.data), fileName: 'member.js', methodName: 'doForgotPassword-Result', trackerId: resetRequest.sTfbId,
          }, { root: true });
          if (resetResult && resetResult.data && (resetResult.data.ErrorMessage === null || resetResult.data.ErrorMessage === '')) {
            resolve(resetResult.data.ReturnMessage);
          } else if (resetResult && resetResult.data && (resetResult.data.ErrorMessage !== null || resetResult.data.ErrorMessage !== '')) {
            resolve(resetResult.data.ErrorMessage);
          }

          reject(new Error(genericErrorMessage));
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'member.js', methodName: 'doForgotPassword-Error', trackerId: resetRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  doIdConversion(context, convertRequest) {
    return new Promise((resolve, reject) => {
      context.dispatch('app/logToULS', {
        logLevel: 'Info',
        logMessage: `Convert: ${convertRequest.sOldUserName} to ${convertRequest.sNewUserName}`,
        fileName: 'member.js',
        methodName: 'doIdConversion-Begin',
        trackerId: convertRequest.sTfbId,
      }, { root: true });
      const memberList = [];
      const tfbMembers = sessionStorage.getItem('tfb_members');
      const tfbParsedMembers = JSON.parse(tfbMembers);
      if (tfbParsedMembers && tfbParsedMembers.length > 0) {
        for (let i = 0; i < tfbParsedMembers.length; i += 1) {
          const member = tfbParsedMembers[i];
          memberList.push(member.Nbr);
        }
      }
      axios.post(`${memberAccessServiceUrl}/SendMultipleEmailVerification`, {
        NewUserName: convertRequest.sNewUserName,
        OldUserName: convertRequest.sOldUserName,
        MembershipArray: memberList,
      }, config)
        .then((convertResult) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: JSON.stringify(convertResult.data), fileName: 'member.js', methodName: 'doIdConversion-Result', trackerId: convertRequest.sTfbId,
          }, { root: true });
          if (convertResult && convertResult.data && (convertResult.data.ErrorMessage === null || convertResult.data.ErrorMessage === '')) {
            resolve(convertResult.data.ReturnMessage);
          } else if (convertResult && convertResult.data && (convertResult.data.ErrorMessage !== null || convertResult.data.ErrorMessage !== '')) {
            reject(convertResult.data.ErrorMessage);
          }

          reject(new Error(genericErrorMessage));
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'member.js', methodName: 'doIdConversion-Error', trackerId: convertRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  doEmailVerification(context, verifyRequest) {
    return new Promise((resolve, reject) => {
      context.dispatch('app/logToULS', {
        logLevel: 'Info', logMessage: `Starting email verification: ${verifyRequest.sValidationCode}`, fileName: 'member.js', methodName: 'doEmailVerification-Begin', trackerId: verifyRequest.sTfbId,
      }, { root: true });
      axios.get(`${memberAccessServiceUrl}/UpdateMultipleEmailVerification/${verifyRequest.sValidationCode}`, config)
        .then((updateResult) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: JSON.stringify(updateResult.data), fileName: 'member.js', methodName: 'doEmailVerification-Result', trackerId: verifyRequest.sTfbId,
          }, { root: true });
          if (updateResult && updateResult.data && (updateResult.data.ErrorMessage === null || updateResult.data.ErrorMessage === '')) {
            if (updateResult.data.Verified === 'Y') {
              context.commit('SET_VERIFIED_INDICATOR', true);
            }
            if (updateResult.data.EmailUpdate !== null && updateResult.data.EmailUpdate !== '') {
              context.commit('SET_EMAIL_ADDRESS', updateResult.data.EmailUpdate);
              context.commit('SET_USER_NAME', updateResult.data.EmailUpdate);
            }
            resolve(updateResult.data);
          } else if (updateResult && updateResult.data && (updateResult.data.ErrorMessage !== null || updateResult.data.ErrorMessage !== '')) {
            context.dispatch('app/logToULS', {
              logLevel: 'Error',
              logMessage: new Error(updateResult.data.ErrorMessage),
              fileName: 'member.js',
              methodName: 'doEmailVerification-ErrorMsg',
              trackerId: verifyRequest.sTfbId,
            }, { root: true });
            reject(new Error(updateResult.data.ErrorMessage));
          }

          reject(new Error(genericErrorMessage));
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'member.js', methodName: 'doEmailVerification-Error', trackerId: verifyRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  doPasswordVerification(context, verifyRequest) {
    return new Promise((resolve, reject) => {
      context.dispatch('app/logToULS', {
        logLevel: 'Info', logMessage: `Starting reset password: ${verifyRequest.sValidationCode}`, fileName: 'member.js', methodName: 'doPasswordVerification-Begin', trackerId: verifyRequest.sTfbId,
      }, { root: true });
      axios.post(`${memberAccessServiceUrl}/ForgotPassword/Verify`, {
        EmailAddress: verifyRequest.sEmailAddress,
        VerificationCode: verifyRequest.sValidationCode,
      }, config)
        .then((updateResult) => {
          if (updateResult && updateResult.data) {
            context.dispatch('app/logToULS', {
              logLevel: 'Info', logMessage: JSON.stringify(updateResult.data), fileName: 'member.js', methodName: 'doPasswordVerification-Result', trackerId: verifyRequest.sTfbId,
            }, { root: true });
            if (updateResult.data.ErrorMessage && (updateResult.data.ErrorMessage !== null || updateResult.data.ErrorMessage !== '')) {
              console.log(updateResult.data.ErrorMessage);
              reject(new Error(updateResult.data.ErrorMessage));
            } else if (updateResult.data.MembershipArray !== null || updateResult.data.MembershipArray.length > 0) {
              const finalMemberList = [];
              for (let i = 0; i < updateResult.data.MembershipArray.length; i += 1) {
                const member = updateResult.data.MembershipArray[i];
                finalMemberList.push({ Id: i, Nbr: member.trim() });
              }
              context.commit('SET_MEMBER_LIST', finalMemberList);
              resolve(updateResult.data);
            }
          }

          reject(new Error(genericErrorMessage));
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'member.js', methodName: 'doPasswordVerification-Error', trackerId: verifyRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  doPhoneVerification(context, verifyRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${memberLookupServiceUrl}/phone/verify/${verifyRequest.sVerificationCode}`, config)
        .then((checkResults) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: JSON.stringify(checkResults.data), fileName: 'member.js', methodName: 'doPhoneVerification-Result', trackerId: verifyRequest.sTfbId,
          }, { root: true });
          resolve(checkResults.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  resendRegistration(context, registrationRequest) {
    return new Promise((resolve, reject) => {
      context.dispatch('app/logToULS', {
        logLevel: 'Info',
        logMessage: `Resending Registration: ${registrationRequest.sMemberNumber}, ${registrationRequest.sPolicyNumber}, ${registrationRequest.sUserName}`,
        fileName: 'member.js',
        methodName: 'resendRegistration-Begin',
        trackerId: registrationRequest.sTfbId,
      }, { root: true });
      axios.post(`${memberAccessServiceUrl}/registration/resend`, {
        CommunicationOption: '',
        EmailAddress: registrationRequest.sUserName,
        Password: '',
        PolicyNumber: registrationRequest.sPolicyNumber,
        MemberNumber: registrationRequest.sMemberNumber,
      }, config)
        .then((registrationResult) => {
          if (registrationResult && registrationResult.data) {
            context.dispatch('app/logToULS', {
              logLevel: 'Info', logMessage: JSON.stringify(registrationResult.data), fileName: 'member.js', methodName: 'resendRegistration-Result', trackerId: registrationRequest.sTfbId,
            }, { root: true });

            if (!registrationResult.data.ErrorMessage || (registrationResult.data.ErrorMessage && (registrationResult.data.ErrorMessage === null || registrationResult.data.ErrorMessage === ''))) {
              resolve('');
            } else if (registrationResult.data.ErrorMessage && (registrationResult.data.ErrorMessage !== null || registrationResult.data.ErrorMessage !== '')) {
              resolve(registrationResult.data.ErrorMessage);
            } else {
              reject(new Error(genericErrorMessage));
            }
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'member.js', methodName: 'resendRegistration-Error', trackerId: registrationRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  createCloudPaymentUrl(context, cloudPaymentRequest) {
    return new Promise((resolve, reject) => {
      context.dispatch('app/logToULS', {
        logLevel: 'Info',
        logMessage: `Starting getCloudPaymentUrl: ${cloudPaymentRequest.sAccountNumber}, ${cloudPaymentRequest.sInvoiceNumber}`,
        fileName: 'member.js',
        methodName: 'getCloudPaymentUrl-Begin',
        trackerId: cloudPaymentRequest.sTfbId,
      }, { root: true });

      if (cloudPaymentRequest.sAccountNumber && cloudPaymentRequest.sAccountNumber !== null && cloudPaymentRequest.sAccountNumber !== '') {
        let serviceUrl = `${memberAccessServiceUrl}/cloud-payment/${cloudPaymentRequest.sAccountNumber}`;

        if (cloudPaymentRequest.sInvoiceNumber && cloudPaymentRequest.sInvoiceNumber !== undefined && cloudPaymentRequest.sInvoiceNumber !== null && cloudPaymentRequest.sInvoiceNumber !== '') {
          serviceUrl = `${memberAccessServiceUrl}/cloud-payment/${cloudPaymentRequest.sAccountNumber}/${cloudPaymentRequest.sInvoiceNumber}`;
        }

        context.dispatch('app/logToULS', {
          logLevel: 'Info',
          logMessage: `Going to: ${serviceUrl}`,
          fileName: 'member.js',
          methodName: 'getCloudPaymentUrl-Call',
          trackerId: cloudPaymentRequest.sTfbId,
        }, { root: true });

        axios.get(serviceUrl, config)
          .then((cloudPaymentResult) => {
            if (cloudPaymentResult && cloudPaymentResult.data) {
              context.dispatch('app/logToULS', {
                logLevel: 'Info', logMessage: JSON.stringify(cloudPaymentResult.data), fileName: 'member.js', methodName: 'getCloudPaymentUrl-Result', trackerId: cloudPaymentRequest.sTfbId,
              }, { root: true });

              resolve(cloudPaymentResult.data);
            } else {
              reject(new Error(genericErrorMessage));
            }
          })
          .catch((error) => {
            context.dispatch('app/logToULS', {
              logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'member.js', methodName: 'getCloudPaymentUrl-Error', trackerId: cloudPaymentRequest.sTfbId,
            }, { root: true });
            reject(error);
          });
      } else {
        resolve('');
      }
    });
  },
  updatePassword(context, updateRequest) {
    return new Promise((resolve, reject) => {
      context.dispatch('app/logToULS', {
        logLevel: 'Info', logMessage: `Starting password update: ${updateRequest.sUserName}`, fileName: 'member.js', methodName: 'updatePassword-Begin', trackerId: updateRequest.sTfbId,
      }, { root: true });
      const memberList = [];
      let username = updateRequest.sUserName;
      if (!updateRequest.sMemberList || updateRequest.sMemberList === null || updateRequest.sMemberList.length <= 0) {
        const tfbMembers = sessionStorage.getItem('tfb_members');
        const tfbParsedMembers = JSON.parse(tfbMembers);
        if (tfbParsedMembers && tfbParsedMembers.length > 0) {
          for (let i = 0; i < tfbParsedMembers.length; i += 1) {
            const member = tfbParsedMembers[i];
            memberList.push(member.Nbr);
          }
        }
      } else {
        for (let i = 0; i < updateRequest.sMemberList.length; i += 1) {
          const member = updateRequest.sMemberList[i];
          memberList.push(member.Nbr);
        }
      }

      if (!updateRequest.sUserName || updateRequest.sUserName === null || updateRequest.sUserName === '') {
        username = state.emailAddress;
      }

      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.post(`${memberAccessServiceUrl}/UpdateMemberSecurePassword`, {
        MemberId: 0, // updateRequest.sMemberId,
        UserName: username,
        NewPassword: updateRequest.sNewPassword,
        MembershipArray: memberList,
      }, config)
        .then((updateResult) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: JSON.stringify(updateResult.data), fileName: 'member.js', methodName: 'updatePassword-Result', trackerId: updateRequest.sTfbId,
          }, { root: true });
          if (updateResult && updateResult.data && (updateResult.data.ErrorMessage === null || updateResult.data.ErrorMessage === '')) {
            resolve(updateResult.data.ReturnMessage);
          } else if (updateResult && updateResult.data && (updateResult.data.ErrorMessage !== null || updateResult.data.ErrorMessage !== '')) {
            resolve(updateResult.data.ErrorMessage);
          }

          reject(new Error(genericErrorMessage));
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'member.js', methodName: 'updatePassword-Error', trackerId: updateRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  updateCommunication(context, updateRequest) {
    return new Promise((resolve, reject) => {
      context.dispatch('app/logToULS', {
        logLevel: 'Info', logMessage: `Starting communication update: ${updateRequest.sUserName}`, fileName: 'member.js', methodName: 'updateCommunication-Begin', trackerId: updateRequest.sTfbId,
      }, { root: true });
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.post(`${memberAccessServiceUrl}/UpdateMemberCommPref`, {
        UserName: updateRequest.sUserName,
        MemberNumber: updateRequest.sMemberNumber,
        RequestsCommunication: updateRequest.sPreference === 'Y',
      }, config)
        .then((updateResult) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: JSON.stringify(updateResult.data), fileName: 'member.js', methodName: 'updateCommunication-Result', trackerId: updateRequest.sTfbId,
          }, { root: true });
          if (updateResult && updateResult.data) {
            context.commit('SET_COMMUNICATION_INDICATOR', (updateRequest.sPreference === 'Y'));
            resolve('');
          }

          reject(new Error(genericErrorMessage));
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'member.js', methodName: 'updateCommunication-Error', trackerId: updateRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  updateEmailAddress(context, updateRequest) {
    return new Promise((resolve, reject) => {
      context.dispatch('app/logToULS', {
        logLevel: 'Info', logMessage: JSON.stringify(updateRequest), fileName: 'member.js', methodName: 'updateEmailAddress-Begin', trackerId: updateRequest.sTfbId,
      }, { root: true });
      let memberNumber = null;

      if (updateRequest.sIncludeAllMembers === false) {
        // eslint-disable-next-line prefer-destructuring
        memberNumber = context.state.memberNumber;
      }
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.post(`${memberAccessServiceUrl}/SendMultipleEmailVerification`, {
        NewUserName: updateRequest.sNewEmailAddress,
        OldUserName: updateRequest.sOldEmailAddress,
        MemberNumber: memberNumber,
      }, config)
        .then((updateResult) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: JSON.stringify(updateResult.data), fileName: 'member.js', methodName: 'updateEmailAddress-Result', trackerId: updateRequest.sTfbId,
          }, { root: true });
          if (updateResult && updateResult.data && (updateResult.data.ErrorMessage === null || updateResult.data.ErrorMessage === '')) {
            resolve(updateResult.data.ReturnMessage);
          } else if (updateResult && updateResult.data && (updateResult.data.ErrorMessage !== null || updateResult.data.ErrorMessage !== '')) {
            reject(new Error(updateResult.data.ErrorMessage));
          }

          reject(new Error(genericErrorMessage));
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'member.js', methodName: 'updateEmailAddress-Error', trackerId: updateRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  updatePhoneNumber(context, updateRequest) {
    return new Promise((resolve, reject) => {
      context.dispatch('app/logToULS', {
        logLevel: 'Info', logMessage: JSON.stringify(updateRequest), fileName: 'member.js', methodName: 'updatePhoneNumber-Begin', trackerId: updateRequest.sTfbId,
      }, { root: true });
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.post(`${memberLookupServiceUrl}/phone/modify`, {
        MemberNumber: updateRequest.sMemberNumber,
        MiraId: updateRequest.sMiraId,
        PhoneNumber: helpers.methods.removePhoneFormatting(updateRequest.sPhoneNumber),
      }, config)
        .then((updateResult) => {
          // // Clear out the contacts so we can fetch the latest
          // context.commit('SET_CONTACT_LIST', null);
          // // Clear out the summaries so we can fetch the latest E-bill
          // context.commit('policy/CLEAR_ACCOUNT_SUMMARY', {}, { root: true });
          // context.commit('payments/CLEAR_ACCOUNT_BILL_SUMMARY', {}, { root: true });
          // Fetch the updated policies/Account Bill
          context.dispatch('policy/getAccountSummary', { sMemberNumber: updateRequest.sMemberNumber, sTfbId: updateRequest.sTfbId }, { root: true });
          context.dispatch('payments/getAccountBillInformation', { sMemberNumber: updateRequest.sMemberNumber, sTfbId: updateRequest.sTfbId }, { root: true });
          // Fetch the updated phone list
          context.dispatch('getPhoneNumbers', { sMemberNumber: updateRequest.sMemberNumber, sTfbId: updateRequest.sTfbId })
            .then(() => {
              resolve(updateResult.data);
            });
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'member.js', methodName: 'updatePhoneNumber-Error', trackerId: updateRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  sendEmailVerification(context, updateRequest) {
    return new Promise((resolve, reject) => {
      context.dispatch('app/logToULS', {
        logLevel: 'Info', logMessage: `Send email verification: ${updateRequest.sEmailAddress}`, fileName: 'member.js', methodName: 'sendEmailVerification-Begin', trackerId: updateRequest.sTfbId,
      }, { root: true });

      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.post(`${memberAccessServiceUrl}/SendMultipleEmailVerification`, {
        NewUserName: '',
        OldUserName: updateRequest.sEmailAddress,
        MemberNumber: null,
      }, config)
        .then((updateResult) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: JSON.stringify(updateResult.data), fileName: 'member.js', methodName: 'sendEmailVerification-Result', trackerId: updateRequest.sTfbId,
          }, { root: true });
          if (updateResult && updateResult.data && (updateResult.data.ErrorMessage === null || updateResult.data.ErrorMessage === '')) {
            resolve(updateResult.data.ReturnMessage);
          } else if (updateResult && updateResult.data && (updateResult.data.ErrorMessage !== null || updateResult.data.ErrorMessage !== '')) {
            reject(new Error(updateResult.data.ErrorMessage));
          }

          reject(new Error(genericErrorMessage));
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'member.js', methodName: 'sendEmailVerification-Error', trackerId: updateRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  sendPhoneVerification(context, updateRequest) {
    return new Promise((resolve, reject) => {
      context.dispatch('app/logToULS', {
        logLevel: 'Info',
        logMessage: `Send phone verification: ${helpers.methods.removePhoneFormatting(updateRequest.sPhoneNumber)}`,
        fileName: 'member.js',
        methodName: 'sendPhoneVerification-Begin',
        trackerId: updateRequest.sTfbId,
      }, { root: true });
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.post(`${memberLookupServiceUrl}/phone/verify`, {
        MemberNumber: updateRequest.sMemberNumber,
        MiraId: updateRequest.sMiraId,
        OnlineAccountId: updateRequest.sOnlineAccountId,
        PhoneNumber: helpers.methods.removePhoneFormatting(updateRequest.sPhoneNumber),
      }, config)
        .then((updateResult) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: JSON.stringify(updateResult.data), fileName: 'member.js', methodName: 'sendPhoneVerification-Result', trackerId: updateRequest.sTfbId,
          }, { root: true });
            resolve(updateResult.data);
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'member.js', methodName: 'sendPhoneVerification-Error', trackerId: updateRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  delinquentMemberCheck(context, sRequest) {
    if (context.state.delinquentIndicator && context.state.delinquentIndicator !== null) {
      return context.state.delinquentIndicator;
    }
    return new Promise((resolve, reject) => {
      if (!sRequest || !sRequest.sMemberNumber || sRequest.sMemberNumber === '' || sRequest.sMemberNumber === null) {
        resolve(false);
      }
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${memberLookupServiceUrl}/delinquent/${sRequest.sMemberNumber}`, config)
        .then((checkResults) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: JSON.stringify(checkResults.data), fileName: 'member.js', methodName: 'delinquentMemberCheck-Result', trackerId: sRequest.sTfbId,
          }, { root: true });
          context.commit('SET_DELINQUENT_INDICATOR', checkResults.data);
          resolve(checkResults.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getGenders() {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${memberLookupServiceUrl}/lookup/genders`, config)
        .then((genderResults) => {
          resolve(genderResults.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getMemberInformation(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${memberLookupServiceUrl}/basic/${sRequest.sMemberNumber}`, config)
        .then((checkResults) => {
          resolve(checkResults.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getPhoneNumbers(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${memberLookupServiceUrl}/contacts/${sRequest.sMemberNumber}`, config)
        .then((contactResults) => {
          // console.log(contactResults.data);
          context.commit('SET_CONTACT_LIST', contactResults.data);
          resolve(contactResults.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getRelationships() {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${memberLookupServiceUrl}/lookup/relationships`, config)
        .then((relationshipResults) => {
          resolve(relationshipResults.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addContact(context, addRequest) {
    return new Promise((resolve, reject) => {
      context.dispatch('app/logToULS', {
        logLevel: 'Info', logMessage: `Starting add contact: ${addRequest.sMemberNumber}`, fileName: 'member.js', methodName: 'addContact-Begin', trackerId: addRequest.sTfbId,
      }, { root: true });
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.post(`${memberLookupServiceUrl}/contacts/add`, {
        MemberNumber: addRequest.sMemberNumber,
        FirstName: addRequest.sFirstName,
        LastName: addRequest.sLastName,
        RelationshipId: addRequest.sRelationship,
        GenderId: addRequest.sGender,
        BirthDate: addRequest.sDateOfBirth,
        PhoneNumber: helpers.methods.removePhoneFormatting(addRequest.sPhoneNumber),
      }, config)
        .then((contactResult) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: JSON.stringify(contactResult.data), fileName: 'member.js', methodName: 'addContact-Result', trackerId: addRequest.sTfbId,
          }, { root: true });

          if (contactResult.data !== null) {
            context.commit('SET_CONTACT_LIST', contactResult.data);
            resolve('');
          } else {
            reject(new Error('Unable to save contact. Please verify the entered information and try again.'));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'member.js', methodName: 'addContact-Error', trackerId: addRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  addNewMembership(context, addRequest) {
    return new Promise((resolve, reject) => {
      context.dispatch('app/logToULS', {
        logLevel: 'Info', logMessage: `Starting add membership: ${addRequest.sUserName}`, fileName: 'member.js', methodName: 'addNewMembership-Begin', trackerId: addRequest.sTfbId,
      }, { root: true });
      const memberList = [];
      const tfbMembers = sessionStorage.getItem('tfb_members');
      const tfbParsedMembers = JSON.parse(tfbMembers);
      for (let i = 0; i < tfbParsedMembers.length; i += 1) {
        const member = tfbParsedMembers[i];
        memberList.push(member.Lng);
      }
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.post(`${memberAccessServiceUrl}/AddAdditionalMembership`, {
        MemberId: context.state.memberId,
        PolicyNumber: addRequest.sPolicyNumber,
        MemberNumber: addRequest.sMemberNumber,
        MembershipArray: memberList,
      }, config)
        .then((additionResult) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: JSON.stringify(additionResult.data), fileName: 'member.js', methodName: 'addNewMembership-Result', trackerId: addRequest.sTfbId,
          }, { root: true });

          if (additionResult.data.ErrorMessage === null || additionResult.data.ErrorMessage === '') {
            const lastLogin = new Date().toISOString();
            context.commit('SET_LAST_LOGIN_TIMESTAMP', lastLogin);
            context.commit('SET_MEMBER_ID', additionResult.data.Member.MemberIdNumber);
            context.commit('SET_MEMBER_NUMBER', additionResult.data.Member.MemberNumber);

            // Valid addition, add the new membership to the array
            tfbParsedMembers.push({
              Id: additionResult.data.Member.MemberIdNumber, Nbr: additionResult.data.Member.MemberNumber,
            });

            // Reorder the memberships
            const sortedMembers = _.orderBy(tfbParsedMembers, ['Nbr'], ['desc']);
            context.commit('SET_MEMBER_LIST', sortedMembers);

            // Remove the previous Account/Account Bill Summary
            context.commit('policy/CLEAR_ACCOUNT_SUMMARY', {}, { root: true });
            context.commit('payments/CLEAR_ACCOUNT_BILL_SUMMARY', {}, { root: true });

            // Clear other saved information
            context.commit('SET_CONTACT_LIST', null);

            // Get member information
            context.dispatch('getMemberInformation', { sMemberNumber: additionResult.data.Member.MemberNumber, sTfbId: addRequest.sTfbId })
              .then((memberResults) => {
                if (memberResults && (!memberResults.ErrorMessage || memberResults.ErrorMessage === null || memberResults.ErrorMessage === '')) {
                  // Rebuild session
                  context.commit('SET_MEMBER_NAME', memberResults.PrimaryMemberName);
                  context.commit('SET_AGENT_NUMBER', memberResults.AgentCode);
                  context.commit('SET_MEMBER_ADDRESS', memberResults.MemberAddress);

                  if (!memberResults.AgentCode || memberResults.AgentCode === '' || memberResults.AgentCode === null || memberResults.AgentCode === '99997') {
                    // See if we can get the agent number
                    context.dispatch('agentcounty/getAgentCodeByMember', {
                      sMemberNumber: additionResult.data.Member.MemberNumber, trackerId: addRequest.sTfbId,
                    }, { root: true })
                      .then((response) => {
                        if (response._agentCode) {
                          // Save code
                          context.commit('SET_AGENT_NUMBER', response._agentCode);
                        }
                      })
                      .catch(() => {
                        // Do nothing
                      });
                  }

                  if (memberResults.PrimaryMemberName && memberResults.PrimaryMemberName !== '') {
                    const memberSplitName = memberResults.PrimaryMemberName.split(' ');
                    if (memberSplitName && memberSplitName.length > 0) {
                      context.commit('SET_MEMBER_FIRST_NAME', memberSplitName[0]);
                      context.commit('SET_MEMBER_LAST_NAME', memberSplitName[1]);
                    } else {
                      context.commit('SET_MEMBER_FIRST_NAME', '');
                      context.commit('SET_MEMBER_LAST_NAME', '');
                    }
                  } else {
                    context.commit('SET_MEMBER_FIRST_NAME', '');
                    context.commit('SET_MEMBER_LAST_NAME', '');
                  }

                  resolve('');
                } else {
                  context.dispatch('app/logToULS', {
                    logLevel: 'Error', logMessage: JSON.stringify(memberResults), fileName: 'member.js', methodName: 'addNewMembership-MemberReturnError', trackerId: addRequest.sTfbId,
                  }, { root: true });
                  context.commit('SET_MEMBER_NAME', '');
                  context.commit('SET_AGENT_NUMBER', '');
                  context.commit('SET_MEMBER_ADDRESS', null);
                  context.commit('SET_MEMBER_FIRST_NAME', '');
                  context.commit('SET_MEMBER_LAST_NAME', '');
                  resolve('');
                }
              })
              .catch((error) => {
                context.dispatch('app/logToULS', {
                  logLevel: 'Error', logMessage: error, fileName: 'member.js', methodName: 'addNewMembership-Error', trackerId: addRequest.sTfbId,
                }, { root: true });
                context.commit('SET_MEMBER_NAME', '');
                context.commit('SET_AGENT_NUMBER', '');
                context.commit('SET_MEMBER_ADDRESS', null);
                context.commit('SET_MEMBER_FIRST_NAME', '');
                context.commit('SET_MEMBER_LAST_NAME', '');
                resolve('');
              });
          } else {
            reject(new Error(additionResult.data.ErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'member.js', methodName: 'addNewMembership-Error', trackerId: addRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  removeMembership(context, removeRequest) {
    return new Promise((resolve, reject) => {
      context.dispatch('app/logToULS', {
        logLevel: 'Info', logMessage: `Starting remove membership: ${removeRequest.sMemberNumber}`, fileName: 'member.js', methodName: 'removeMembership-Begin', trackerId: removeRequest.sTfbId,
      }, { root: true });
      const memberList = [];
      const tfbMembers = sessionStorage.getItem('tfb_members');
      const tfbParsedMembers = JSON.parse(tfbMembers);
      for (let i = 0; i < tfbParsedMembers.length; i += 1) {
        const member = tfbParsedMembers[i];
        memberList.push(member.Lng);
      }
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.post(`${memberAccessServiceUrl}/RemoveMembership`, {
        MemberId: removeRequest.sMemberId,
        MemberNumber: removeRequest.sMemberNumber,
        MembershipArray: memberList,
      }, config)
        .then((additionResult) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: JSON.stringify(additionResult.data), fileName: 'member.js', methodName: 'removeMembership-Result', trackerId: removeRequest.sTfbId,
          }, { root: true });

          if (additionResult.data) {
            // Remove the request
            context.commit('SET_REMOVE_MEMBER_REQUEST', null);

            // Remove the membership from the array
            const tfbFilteredMembers = [];

            for (let i = 0; i < tfbParsedMembers.length; i += 1) {
              const member = tfbParsedMembers[i];
              if (member.Lng !== removeRequest.sMemberNumber) {
                tfbFilteredMembers.push(member);
              }
            }

            // Save the remaining
            context.commit('SET_MEMBER_LIST', tfbFilteredMembers);
            resolve('');
          }

          reject(new Error(genericErrorMessage));
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'member.js', methodName: 'removeMembership-Error', trackerId: removeRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  switchMembership(context, switchRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      // Get member information
      context.dispatch('getMemberInformation', { sMemberNumber: switchRequest.sMemberNumber, sTfbId: switchRequest.sTfbId })
        .then((memberResults) => {
          if (memberResults) {
            // Update last login
            axios.get(`${memberAccessServiceUrl}/UpdateLastLogon/${switchRequest.sMemberId}`, config)
              .then(() => {
                const lastLogin = new Date().toISOString();
                context.commit('CLEAR_GUIDEWIRE_TOKEN', null);

                // Remove the previous Account/Account Bill Summary
                context.commit('policy/CLEAR_ACCOUNT_SUMMARY', {}, { root: true });
                context.commit('payments/CLEAR_ACCOUNT_BILL_SUMMARY', {}, { root: true });
                // Reset the contact list
                context.commit('SET_CONTACT_LIST', null);

                // Rebuild session
                context.commit('SET_LAST_LOGIN_TIMESTAMP', lastLogin);
                context.commit('SET_MEMBER_ID', switchRequest.sMemberId);
                context.commit('SET_MEMBER_NUMBER', switchRequest.sMemberNumber);
                context.commit('SET_MEMBER_NAME', memberResults.PrimaryMemberName);
                context.commit('SET_AGENT_NUMBER', memberResults.AgentCode);
                context.commit('SET_MEMBER_ADDRESS', memberResults.MemberAddress);

                // Get InvoiceCloud token
                axios.post(`${memberAccessServiceUrl}/invoice-cloud/token`, {
                  MemberNumber: switchRequest.sMemberNumber,
                  EmailAddress: context.state.emailAddress,
                  EncryptedPassword: '',
                }, config)
                  .then((tokenResult) => {
                    if (tokenResult && tokenResult.data) {
                      context.dispatch('app/logToULS', {
                        logLevel: 'Info', logMessage: JSON.stringify(tokenResult.data), fileName: 'member.js', methodName: 'getICToken-Result', trackerId: switchRequest.sTfbId,
                      }, { root: true });

                      context.commit('SET_INVOICE_CLOUD_TOKEN', tokenResult.data);
                    }
                  })
                  .catch((error) => {
                    context.dispatch('app/logToULS', {
                      logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'member.js', methodName: 'getCloudPaymentUrl-Error', trackerId: switchRequest.sTfbId,
                    }, { root: true });
                  });

                if (!memberResults.AgentCode || memberResults.AgentCode === '' || memberResults.AgentCode === null || memberResults.AgentCode === '99997') {
                  // See if we can get the agent number
                  context.dispatch('agentcounty/getAgentCodeByMember', {
                    sMemberNumber: switchRequest.sMemberNumber, trackerId: switchRequest.sTfbId,
                  }, { root: true })
                    .then((response) => {
                      if (response._agentCode) {
                        // Save code
                        context.commit('SET_AGENT_NUMBER', response._agentCode);
                      }
                    })
                    .catch(() => {
                      // Do nothing
                    });
                }

                if (memberResults.PrimaryMemberName && memberResults.PrimaryMemberName !== '') {
                  const memberSplitName = memberResults.PrimaryMemberName.split(' ');
                  if (memberSplitName && memberSplitName.length > 0) {
                    context.commit('SET_MEMBER_FIRST_NAME', memberSplitName[0]);
                    context.commit('SET_MEMBER_LAST_NAME', memberSplitName[1]);
                  } else {
                    context.commit('SET_MEMBER_FIRST_NAME', '');
                    context.commit('SET_MEMBER_LAST_NAME', '');
                  }
                } else {
                  context.commit('SET_MEMBER_FIRST_NAME', '');
                  context.commit('SET_MEMBER_LAST_NAME', '');
                }

                resolve(true);
              })
              .catch((error) => {
                context.dispatch('app/logToULS', {
                  logLevel: 'Error', logMessage: error, fileName: 'member.js', methodName: 'switchMembership.UpdateLastLogon-Error', trackerId: switchRequest.sTfbId,
                }, { root: true });
                reject(error);
              });
          } else {
            context.dispatch('app/logToULS', {
              logLevel: 'Error', logMessage: JSON.stringify(memberResults), fileName: 'member.js', methodName: 'switchMembership-MemberReturnError', trackerId: switchRequest.sTfbId,
            }, { root: true });
            reject(new Error(memberResults));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'member.js', methodName: 'switchMembership-Error', trackerId: switchRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
