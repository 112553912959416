<template>
  <v-container fluid
               class="tfb-container">
    <v-row>
      <v-col cols="12">
        <v-container fluid
                     class="drop-shadow background-white tfb-smaller-container">
          <iframe :src="autopayIFrameUrl"
                  title="AutoPay Settings"
                  class="autopayIFrame"></iframe>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import tfbMetaInfo from '@/mixins/metaInfo';

  export default {
    name: 'InvoiceCloudAutoPay',
    mixins: [tfbMetaInfo],
    computed: {
      metaPageTitle() {
        return 'AutoPayments | PolicyHolder';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/policyholder/payments/auto-pay`;
      },
      remoteAuthenticationToken() {
        try {
          return this.$store.getters['member/invoiceCloudToken'];
        } catch (error) {
          return '';
        }
      },
      autopayIFrameUrl() {
        try {
          if (!this.isMemberRole && this.remoteAuthenticationToken && this.remoteAuthenticationToken !== null && this.remoteAuthenticationToken !== '') {
            // Not the member, don't allow changes
            return `${this.$store.getters['app/invoiceCloudIFrameUrl']}?RemoteAuthenticationToken=${this.remoteAuthenticationToken}&ViewMode=1&RedirectID=3`;
          }

          return '';
        } catch (error) {
          return '';
        }
      },
    },
    mounted() {
      this.$store.dispatch('app/clearErrorMessage');
      this.$store.dispatch('app/setIsLoading', true);
      this.$store.dispatch('app/doApplicationCheck', { sApplicationName: 'Policyholder', sTfbId: this.trackingId })
        .then((returnedStatus) => {
          if (returnedStatus === '') {
            const includedToken = this.$route.query.access_token;
            this.$store.dispatch('member/validateLogin', { sToken: includedToken, sTfbId: this.trackingId })
              .then((loginValid) => {
                if (loginValid) {
                  this.$store.dispatch('app/setIsLoading', false);
                } else {
                  this.$store.dispatch('member/doLogout')
                    .then(() => {
                      this.$router.push({ name: 'policyholderLogin' });
                    })
                    .catch((accessError) => {
                      this.$store.dispatch('app/setErrorMessage', accessError.toString());
                      this.$store.dispatch('app/setIsLoading', false);
                    });
                }
              })
              .catch((error) => {
                this.$store.dispatch('app/setErrorMessage', error.toString());
                this.$store.dispatch('app/setIsLoading', false);
              });
          } else {
            this.$store.dispatch('app/setErrorMessage', returnedStatus);
            this.$store.dispatch('app/setIsLoading', false);
          }
        })
        .catch((error) => {
          this.$store.dispatch('app/setErrorMessage', error.toString());
          this.$store.dispatch('app/setIsLoading', false);
        });
    },
  };
</script>
<style lang="scss">
  .autopayIFrame iframe {
    width: 100%;
    height: 100%;
  }

  .autopayIFrame {
    width: 100%;
    height: 500px;
  }
</style>
