<template>
  <v-container fluid
               class="py-0">
    <div v-if="policyDetails.Mortgagees && policyDetails.Mortgagees.length > 0">
      <v-row v-for="mortgagee in policyDetails.Mortgagees"
             v-bind:key="mortgagee.id">
        <v-col cols="12"
               class="py-1">
          <span class="font-weight-bold">{{mortgagee.Name}}</span><br />
          <span v-if="mortgagee.AdditionalName && mortgagee.AdditionalName.length > 0">
            <em>{{mortgagee.AdditionalName}}</em><br />
          </span>
          <span v-if="mortgagee.LoanNumber && mortgagee.LoanNumber.length > 0">
            Loan Number: {{mortgagee.LoanNumber}}<br />
          </span>
          <span v-if="mortgagee.Loan && mortgagee.Loan.length > 0">
            Loan Number: {{mortgagee.Loan}}<br />
          </span>
          <span v-if="mortgagee.RelatedItemNumber && mortgagee.RelatedItemNumber.length > 0">
            Related Item Number: {{mortgagee.RelatedItemNumber}}<br />
          </span>
          <address v-if="mortgagee.Address">
            {{mortgagee.Address}}
          </address>
          <div class="d-block d-lg-none"><hr /></div>
        </v-col>
      </v-row>
    </div>
    <v-row v-else>
      <v-col cols="12"
             class="py-0">None</v-col>
    </v-row>
  </v-container>
</template>
<script>
  export default {
    name: 'GuidewireMortgageesLossPayees',
    props: {
      policyDetails: {
        type: Object,
        required: true,
      },
    },
  };
</script>
<style lang="scss">

</style>
