<template>
  <div id="policy-details">
    <v-container fluid
                 class="tfb-container">
      <h2 class="tfb-title-text-medium text-darker-blue text-uppercase"
          v-if="policyDetails"
          v-html="policyDetails.PolicyDescription"></h2>
      <h2 class="tfb-title-text-medium text-darker-blue text-uppercase"
          v-else>
        Policy Details
      </h2>
      <v-skeleton-loader :loading="policyLoading"
                         type="list-item-avatar-three-line"
                         class="mx-auto drop-shadow policy-box px-2 px-md-4 px-lg-6 mb-10">
        <v-row v-if="!policyDetails || policyDetails.length === 0">
          <v-col>
            Unable to locate policy information
          </v-col>
        </v-row>
        <v-container v-else
                     fluid>
          <!-- Policy/Account Bill Information -->
          <v-row v-if="policyDetails.PolicyType !== 'PR'">
            <v-col cols="12"
                   md="7">
              <h3 class="tfb-title-text-small text-darker-blue text-uppercase">
                Policy {{policyDetails.PolicyNumber}}
              </h3>
              <span class="font-weight-bold">Effective Dates:</span>
              {{formatDate(policyDetails.PolicyEffectiveDate)}} &mdash;
              {{formatDate(policyDetails.PolicyExpirationDate)}}<br />
            </v-col>
            <v-col cols="12"
                   md="5"
                   class="policy-separator">
              <policy-payment-list :policyDetails="policyDetails"></policy-payment-list>
            </v-col>
          </v-row>
          <v-row v-if="policyDetails.PolicyType !== 'PR'">
            <v-col cols="12"
                   md="7">
              <h3 class="text-blue font-weight-bold">Policyholder Information:</h3>
              <span class="font-weight-bold" v-html="policyDetails.InsuredNames"></span><br />
              <span v-if="policyDetails.FormattedPolicyAddress"
                    v-html="policyDetails.FormattedPolicyAddress"></span>
              <span v-if="policyDetails.PolicyType !== 'AB'">
                <span class="font-weight-bold">Company:</span> {{policyDetails.WritingCompany}}
                <br />
              </span>
              <span v-if="policyDetails.PolicyType === 'UM'">
                <span class="font-weight-bold">Umbrella Policy Type:</span> {{policyDetails.PolicyDescription}}
                <br />
                <!-- Umbrella Limit -->
                <span v-if="policyDetails.PolicyLimit && policyDetails.PolicyLimit !== '' && policyDetails.PolicyLimit !== '0'">
                  <span class="font-weight-bold">Umbrella Policy Limit:</span> {{formatMoney(policyDetails.PolicyLimit, 0)}}<br />
                </span>
              </span>
              <br />
            </v-col>
            <link-list displayLocation="details"
                       displayType="policy"
                       :policyAccount="policyDetails"></link-list>
          </v-row>
          <!-- Premium Finance Information -->
          <v-row v-if="policyDetails.PolicyType === 'PR'">
            <v-col cols="12"
                   md="7">
              <span class="tfb-title-text-small text-darker-blue text-uppercase">
                Contract Number {{policyDetails.PolicyNumber}}
              </span>
            </v-col>
            <v-col cols="12"
                   md="5"
                   class="policy-separator">
              <div class="font-weight-bold">Monthly Payment:<br /></div>
              <div class="text-right text-md-left">{{formatMoney(policyDetails.PolicyMinimumAmountDue, 2)}}<br /></div>
              <div class="font-weight-bold">Current Due Date:<br /></div>
              <div class="text-right text-md-left">{{formatDate(policyDetails.PolicyDueDate)}}<br /></div>
              <div class="font-weight-bold">Total Amount Due:<br /></div>
              <div class="text-right text-md-left">{{formatMoney(policyDetails.PolicyMaximumAmountDue, 2)}}<br /></div>
            </v-col>
          </v-row>
          <v-row v-if="policyDetails.PolicyType === 'PR'">
            <v-col cols="12"
                   md="7">
              <span class="font-weight-bold">Contract Date:</span> {{formatDate(policyDetails.ContractDate)}}<br />
              <span class="font-weight-bold">Total Premium:</span> {{policyDetails.TotalPremium}}<br />
              <span class="font-weight-bold">Finance Charge:</span> {{policyDetails.FinanceCharge}}<br />
            </v-col>
            <link-list displayLocation="details"
                       displayType="policy"
                       :policyAccount="policyDetails"></link-list>
          </v-row>
          <!-- Account Bill: Policy Listing -->
          <v-expansion-panels accordion
                              flat
                              class="tfb-section"
                              v-if="policyDetails.PolicyType === 'AB'">
            <v-expansion-panel>
              <v-expansion-panel-header class="text-blue text-right font-weight-bold px-0">
                <template v-slot:actions>
                  <v-icon>$expand</v-icon>
                </template>
                <span class="text-right">Policy Listing</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="background-lightest-gray py-4">
                <v-row>
                  <v-col cols="12">
                    <span class="text-blue font-weight-bold">Associated Policies</span>
                  </v-col>
                </v-row>
                <v-data-table dense
                              :headers="abPolicyHeaders"
                              :items="policyDetails.Policies"
                              :items-per-page="10"
                              sort-by="policyNbr"
                              sort-desc
                              class="tfb-table">
                  <template v-slot:no-data>
                    No Policy Information Available
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- Billing and Payment History -->
          <billing-payment-history :policyDetails="policyDetails"></billing-payment-history>
          <!-- Billing Documents -->
          <billing-document-list :policyNumber="policyDetails.PolicyNumber"
                                 :policyType="policyDetails.PolicyType"
                                 :policySubType="policyDetails.PolicySubType"
                                 :laurusFlag="policyDetails.LaurusFlag"
                                 :guidewireFlag="policyDetails.GuidewireFlag"></billing-document-list>
          <!-- Policy Documents -->
          <policy-document-list :policyNumber="policyDetails.PolicyNumber"
                                :policyType="policyDetails.PolicyType"
                                :policySubType="policyDetails.PolicySubType"
                                :policySymbol="policyDetails.PolicySymbol"
                                :effectiveDate="policyDetails.EffectiveDate"
                                :expirationDate="policyDetails.ExpirationDate"
                                :futureEffectiveDate="policyDetails.FutureEffectiveDate"
                                :futureExpirationDate="policyDetails.FutureExpirationDate"
                                :openPanel="99"
                                v-if="showPolicyDocuments"></policy-document-list>
          <!-- Property Location -->
          <div v-if="policyDetails.PolicyType !== 'CA' && policyDetails.PolicyType !== 'SM'">
            <v-row v-if="(policyDetails.PolicyType === 'TC' || policyDetails.PolicyType === 'CF' || policyDetails.PolicyType === 'CP') && policyDetails.PolicySubType !== '3'">
              <h3 class="text-blue font-weight-bold">Location of Property</h3>
              <v-col cols="12"
                     class="px-0"
                     v-if="policyDetails.Locations && policyDetails.Locations.length > 0">
                <div v-for="location in policyDetails.Locations"
                     v-bind:key="location.LocationId">
                  {{location.LocationAddress}}<br />
                </div>
              </v-col>
              <v-col cols="12"
                     v-else>
                None<br />
              </v-col>
            </v-row>
            <div v-else>
              <v-row v-if="((policyDetails.PolicyType === 'PF' || policyDetails.PolicyType === 'SF') && policyDetails.PolicySubType === '1') || policyDetails.GuidewireFlag === 'Y'">
                <span class="text-blue font-weight-bold">Location of Property</span>
                <v-col cols="12"
                       v-if="policyDetails.Locations && policyDetails.Locations.length > 0"
                       class="px-0">
                  <div v-for="location in policyDetails.Locations"
                       v-bind:key="location.id">
                    {{location}}<br />
                  </div>
                </v-col>
                <v-col cols="12"
                       v-else>
                  None<br />
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12"
                       class="px-0"
                       v-if="policyDetails.PropertyLocation && policyDetails.PropertyLocation.trim() !== ''">
                  <span class="text-blue font-weight-bold">Location of Property</span><br />
                  {{policyDetails.PropertyLocation}}
                </v-col>
              </v-row>
            </div>
            <!-- Form  -->
            <v-row v-if="policyDetails.PolicyForm && policyDetails.PolicyForm !== ''">
              <v-col cols="12"
                     class="pa-0">
                <span class="font-weight-bold">Form:</span> {{policyDetails.PolicyForm}}
                <!--<v-icon small
        @click="showPolicyDefinition(policyDetails.PolicyType,'Liability','')">mdi-help</v-icon>-->
                <br />
              </v-col>
            </v-row>
            <!-- Construction -->
            <v-row v-if="policyDetails.LocationConstruction && policyDetails.LocationConstruction !== '' && policyDetails.PolicyType !== 'PF' && policyDetails.PolicyType !== 'SF'">
              <v-col cols="12"
                     class="pa-0">
                <span class="font-weight-bold">Construction:</span> {{policyDetails.LocationConstruction}}<br />
              </v-col>
            </v-row>
          </div>
          <!-- Property Coverages/Endorsements/Discounts/Mortgagees -->
          <div v-if="policyDetails.PolicyType !== 'SM' && policyDetails.PolicyType !== 'CA' && policyDetails.PolicyType !== 'PR' && policyDetails.PolicyType !== 'AB'
                 && policyDetails.PolicyType !== 'UM' && policyDetails.PolicyType !== 'CP' && policyDetails.PolicyType !== 'CF' && policyDetails.PolicyType !== 'TC'">
            <v-expansion-panels accordion
                                flat
                                class="my-3">
              <v-expansion-panel>
                <v-expansion-panel-header class="px-0">
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-col cols="12"
                           md="5">
                      <template v-slot:actions>
                        <v-icon>$expand</v-icon>
                      </template>
                      <h3 class="text-blue font-weight-bold">Coverage Information</h3>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="background-lightest-gray pb-5">
                  <!-- Coverages -->
                  <v-row class="mt-2">
                    <v-col cols="12">
                      <h3 class="tfb-title-text-smaller font-weight-bold text-blue">
                        Coverages
                        <!--TODO: <v-icon small
        @click="showPolicyDefinition(policyDetails.PolicyType,'Liability','')">mdi-help</v-icon>-->
                      </h3>
                    </v-col>
                  </v-row>
                  <!-- Guidewire Coverages -->
                  <guidewire-policy-coverages v-if="policyDetails.GuidewireFlag === 'Y' && policyDetails.Sections"
                                              :policyDetails="policyDetails"></guidewire-policy-coverages>
                  <!-- TAA/HOL/STF/INM Coverages -->
                  <v-container fluid
                               class="py-0"
                               v-else-if="(policyDetails.PolicyType === 'AA' || policyDetails.PolicyType === 'AT' || policyDetails.PolicyType === 'HT'
                               || policyDetails.PolicyType === 'TT' || ((policyDetails.PolicyType === 'PF' || policyDetails.PolicyType === 'SF') && policyDetails.PolicySubType === '1'))
                               && policyDetails.Sections">
                    <!-- Loop through sections -->
                    <div v-for="section in policyDetails.Sections"
                         v-bind:key="section.id">
                      <!-- Is this a non-mortgagee section? -->
                      <div v-if="section.Name !== 'Mortgagees' && section.Name !== 'Mortgagees/Loss Payees'"
                           class="py-2">
                        <!-- Output the section name -->
                        <v-row>
                          <v-col cols="12"
                                 class="pb-1">
                            <h4 class="font-weight-bold">{{section.Name}}</h4>
                          </v-col>
                        </v-row>
                        <!-- Does this section have coverages? -->
                        <v-container fluid
                                     class="py-0"
                                     v-if="section.Coverages && section.Coverages.length > 0">
                          <!-- Loop through the section coverages -->
                          <div v-for="coverage in section.Coverages"
                               v-bind:key="coverage.id">
                            <!-- Does this section coverage not have a detail section? -->
                            <v-row v-if="!coverage.CoverageDetail || coverage.CoverageDetail.length <= 0"
                                   class="mt-3">
                              <v-col cols="12"
                                     md="9"
                                     class="py-0">
                                <span v-if="coverage.Group != ''">{{coverage.Group}}. </span>{{coverage.Name}}
                              </v-col>
                              <v-col cols="12"
                                     md="3"
                                     class="text-right text-md-left py-0">{{coverage.Limit}}</v-col>
                              <v-col cols="12"
                                     class="d-block d-lg-none"><hr /></v-col>
                            </v-row>
                            <!-- Does this section coverage have a detail section? -->
                            <v-container fluid
                                         v-if="coverage.CoverageDetail && coverage.CoverageDetail.length > 0">
                              <!-- Output the detail section name -->
                              <v-row>
                                <v-col cols="12"
                                       class="mt-1 pb-1">
                                  <span class="font-weight-bold">{{coverage.Name}}</span>
                                </v-col>
                              </v-row>
                              <!-- Loop through the detail section subcoverages -->
                              <v-row v-for="subCoverage in coverage.CoverageDetail"
                                     v-bind:key="subCoverage.id">
                                <v-col cols="12"
                                       class="py-0">{{subCoverage.Name}}</v-col>
                                <v-col cols="12"
                                       class="d-block d-lg-none"><hr /></v-col>
                              </v-row>
                            </v-container>
                          </div>
                        </v-container>
                        <v-row v-else>
                          <v-col cols="12"
                                 class="py-0 px-6">None</v-col>
                        </v-row>
                        <!-- Non-INM/STF Deductibles -->
                        <v-container fluid
                                     class="mt-3 pb-0"
                                     v-if="section.Deductibles && section.Deductibles.length > 0 && policyDetails.GuidewireFlag !== 'Y' && policyDetails.PolicyType !== 'PF'
                                     && policyDetails.PolicyType !== 'SF'">
                          <v-row v-for="deductible in section.Deductibles"
                                 v-bind:key="deductible.id">
                            <v-col cols="12"
                                   md="9"
                                   class="py-0">{{deductible.Name}}</v-col>
                            <v-col cols="12"
                                   md="3"
                                   class="text-right text-md-left py-0">{{formatMoney(deductible.Amount, 0)}}</v-col>
                            <v-col cols="12"
                                   class="d-block d-lg-none"><hr /></v-col>
                          </v-row>
                        </v-container>
                      </div>
                    </div>
                  </v-container>
                  <!-- Non-specialized Coverages -->
                  <v-container fluid
                               class="py-0"
                               v-else>
                    <div v-if="policyDetails.Coverages && policyDetails.Coverages.length > 0">
                      <v-row v-for="coverage in policyDetails.Coverages"
                             v-bind:key="coverage.ItemNumberDescription">
                        <v-col cols="12"
                               md="7"
                               v-if="!coverage.ItemNumberDescription && !coverage.Description">Unknown</v-col>
                        <v-col cols="12"
                               md="7"
                               v-if="!coverage.ItemNumberDescription && coverage.Description">{{coverage.Description}}</v-col>
                        <v-col cols="12"
                               md="7"
                               v-if="coverage.ItemNumberDescription">{{coverage.ItemNumberDescription}}</v-col>
                        <v-col cols="12"
                               md="5"
                               class="text-right text-md-left">{{formatMoney(coverage.Limit, 0)}}</v-col>
                      </v-row>
                    </div>
                    <v-row v-else>
                      <v-col cols="12"
                             class="px-6 py-0">None</v-col>
                    </v-row>
                  </v-container>
                  <!-- Deductibles -->
                  <div v-if="((policyDetails.PolicyType === 'PF' || policyDetails.PolicyType === 'SF') && policyDetails.PolicySubType === '1') || policyDetails.GuidewireFlag === 'Y'">
                    <v-row class="mt-2">
                      <v-col cols="12">
                        <h3 class="tfb-title-text-smaller font-weight-bold text-blue">
                          Deductibles
                          <!--TODO: <v-icon small
          @click="showPolicyDefinition(policyDetails.PolicyType,'Liability','')">mdi-help</v-icon>-->
                        </h3>
                      </v-col>
                    </v-row>
                    <!-- STF/INM Deductibles -->
                    <v-container fluid
                                 class="py-0"
                                 v-if="policyDetails.Sections">
                      <div v-for="section in policyDetails.Sections"
                           v-bind:key="section.id">
                        <div v-if="section.Name !== 'Mortgagees' && section.Name !== 'Mortgagees/Loss Payees'">
                          <v-row>
                            <v-col cols="12">
                              <h4 class="font-weight-bold">{{section.Name}}</h4>
                            </v-col>
                          </v-row>
                          <v-container fluid
                                       class="py-0"
                                       v-if="section.Deductibles && section.Deductibles.length > 0">
                            <v-row v-for="deductible in section.Deductibles"
                                   v-bind:key="deductible.id">
                              <v-col cols="12"
                                     md="9"
                                     class="py-0">{{deductible.ObjectName}}</v-col>
                              <v-col cols="12"
                                     md="3"
                                     class="text-right text-md-left py-0">{{formatMoney(deductible.Amount, 0)}}</v-col>
                              <v-col cols="12"
                                     class="d-block d-lg-none"><hr /></v-col>
                            </v-row>
                          </v-container>
                          <v-row v-else>
                            <v-col cols="12"
                                   class="px-6 py-0">
                              None
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </v-container>
                    <v-row v-else>
                      <v-col cols="12"
                             class="px-6 py-0">
                        None
                      </v-col>
                    </v-row>
                  </div>
                  <!-- Endorsements -->
                  <v-row class="mt-2">
                    <v-col cols="12">
                      <h3 class="tfb-title-text-smaller font-weight-bold text-blue">
                        Endorsements
                        <!--TODO: <v-icon small
        @click="showPolicyDefinition(policyDetails.PolicyType,'Endorsements','')">mdi-help</v-icon>-->
                      </h3>
                    </v-col>
                  </v-row>
                  <!-- Guidewire Endorsements -->
                  <guidewire-endorsements v-if="policyDetails.GuidewireFlag === 'Y'"
                                          :policyDetails="policyDetails"></guidewire-endorsements>
                  <v-container fluid
                               class="py-0"
                               v-else>
                    <div v-if="policyDetails.Endorsements && policyDetails.Endorsements.length > 0">
                      <v-row v-for="endorsement in policyDetails.Endorsements"
                             v-bind:key="endorsement.Id">
                        <v-col cols="3"
                               class="py-0 d-none d-sm-block">{{endorsement.Id}}</v-col>
                        <v-col cols="9"
                               class="py-0">{{endorsement.Name}}</v-col>
                        <v-col cols="12"
                               class="d-block d-lg-none"><hr /></v-col>
                      </v-row>
                    </div>
                    <v-row v-else>
                      <v-col cols="12"
                             class="py-0">None</v-col>
                    </v-row>
                  </v-container>
                  <!-- Discounts -->
                  <v-row class="mt-2">
                    <v-col cols="12">
                      <h3 class="tfb-title-text-smaller font-weight-bold text-blue">
                        Discounts
                        <!--TODO: <v-icon small
        @click="showPolicyDefinition(policyDetails.PolicyType,'Discounts','')">mdi-help</v-icon>-->
                      </h3>
                    </v-col>
                  </v-row>
                  <v-container fluid
                               class="py-0">
                    <div v-if="policyDetails.Discounts && policyDetails.Discounts.length > 0">
                      <v-row v-for="discount in policyDetails.Discounts"
                             v-bind:key="discount.id">
                        <v-col cols="12"
                               class="py-0">{{discount.Name}}</v-col>
                        <v-col cols="12"
                               class="d-block d-lg-none"><hr /></v-col>
                      </v-row>
                    </div>
                    <v-row v-else>
                      <v-col cols="12"
                             class="py-0">None</v-col>
                    </v-row>
                  </v-container>
                  <!-- Mortgagees -->
                  <v-row class="mt-2">
                    <v-col cols="12">
                      <h3 class="tfb-title-text-smaller font-weight-bold text-blue">Mortgagees/Loss Payees</h3>
                    </v-col>
                  </v-row>
                  <guidewire-mortgagees-loss-payees v-if="policyDetails.GuidewireFlag === 'Y'"
                                                    :policyDetails="policyDetails"></guidewire-mortgagees-loss-payees>
                  <v-container fluid
                               class="py-0"
                               v-else>
                    <div v-if="policyDetails.Mortgagees && policyDetails.Mortgagees.length > 0">
                      <v-row v-for="mortgagee in policyDetails.Mortgagees"
                             v-bind:key="mortgagee.id">
                        <v-col cols="12"
                               class="py-1">
                          <span class="font-weight-bold">{{mortgagee.Name}}</span><br />
                          <span v-if="mortgagee.AdditionalName && mortgagee.AdditionalName.length > 0">
                            <em>{{mortgagee.AdditionalName}}</em><br />
                          </span>
                          <span v-if="mortgagee.LoanNumber && mortgagee.LoanNumber.length > 0">
                            Loan Number: {{mortgagee.LoanNumber}}<br />
                          </span>
                          <span v-if="mortgagee.Loan && mortgagee.Loan.length > 0">
                            Loan Number: {{mortgagee.Loan}}<br />
                          </span>
                          <address v-if="mortgagee.Address">
                            {{mortgagee.Address}}
                          </address>
                          <div class="d-block d-lg-none"><hr /></div>
                        </v-col>
                      </v-row>
                    </div>
                    <v-row v-else>
                      <v-col cols="12"
                             class="py-0">None</v-col>
                    </v-row>
                  </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <!-- CPP/GL Locations -->
          <div v-if="(policyDetails.PolicyType === 'CP' || policyDetails.PolicyType === 'CF' || policyDetails.PolicyType === 'TC') && policyDetails.PolicySubType !== '3'">
            <v-expansion-panels accordion
                                flat
                                class="tfb-section py-1 my-3">
              <v-expansion-panel>
                <v-expansion-panel-header class="px-0">
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-col cols="12"
                           md="5">
                      <template v-slot:actions>
                        <v-icon>$expand</v-icon>
                      </template>
                      <h3 class="text-blue font-weight-bold">Coverage Information</h3>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pa-0 background-lightest-gray">
                  <v-row v-if="!policyDetails.Coverages || policyDetails.Coverages.length === 0">
                    <v-col cols="12">None</v-col>
                  </v-row>
                  <v-row v-else>
                    <v-container v-for="(coverageLocation, index) in policyDetails.Coverages"
                                 v-bind:key="coverageLocation.LocationId">
                      <v-row>
                        <v-col cols="12">
                          <span class="tfb-title-text-smaller font-weight-bold">
                            {{coverageLocation.LocationAddress}}
                          </span>
                        </v-col>
                      </v-row>
                      <v-container v-if="coverageLocation.OccurrenceLimit || coverageLocation.AggregateLimit">
                        <v-row v-if="coverageLocation.OccurrenceLimit">
                          <v-col cols="12"
                                 md="8"
                                 class="py-0"><label class="font-weight-bold">Each Occurrence Limit (CSL):</label></v-col>
                          <v-col cols="12"
                                 md="4"
                                 class="py-0 text-right text-md-left">{{formatMoney(coverageLocation.OccurrenceLimit, 0)}}</v-col>
                          <v-col cols="12"
                                 class="py-0 d-block d-lg-none"><hr /></v-col>
                        </v-row>
                        <v-row v-if="coverageLocation.AggregateLimit">
                          <v-col cols="12"
                                 md="8"
                                 class="py-0"><label class="font-weight-bold">General Aggregate Limit (CSL):</label></v-col>
                          <v-col cols="12"
                                 md="4"
                                 class="py-0 text-right text-md-left">{{formatMoney(coverageLocation.AggregateLimit, 0)}}</v-col>
                        </v-row>
                      </v-container>
                      <div v-if="coverageLocation.Buildings && coverageLocation.Buildings.length > 0">
                        <v-container v-for="building in coverageLocation.Buildings"
                                     v-bind:key="building.BuildingId">
                          <v-row>
                            <v-col cols="12">
                              <span class="tfb-title-text-smallest font-weight-bold">{{building.BuildingDescription}}</span>
                            </v-col>
                          </v-row>
                          <v-row v-if="building.BuildingCoverage && building.BuildingCoverage !== ''">
                            <v-col cols="12"
                                   md="8"
                                   class="py-0"><label class="font-weight-bold">Building Coverage:</label></v-col>
                            <v-col cols="12"
                                   md="4"
                                   class="py-0 text-right text-md-left">{{formatMoney(building.BuildingCoverage, 0)}}</v-col>
                            <v-col cols="12"
                                   md="12"
                                   class="py-0 d-block d-lg-none"
                                   v-if="index != policyDetails.Coverages.length - 1"><hr /></v-col>
                          </v-row>
                          <v-row v-if="building.BusinessPersonalPropertyCoverage && building.BusinessPersonalPropertyCoverage !== ''">
                            <v-col cols="12"
                                   md="8"
                                   class="py-0"><label class="font-weight-bold">Business Personal Property Coverage:</label></v-col>
                            <v-col cols="12"
                                   md="4"
                                   class="py-0 text-right text-md-left">{{formatMoney(building.BusinessPersonalPropertyCoverage, 0)}}</v-col>
                            <v-col cols="12"
                                   class="py-0 d-block d-lg-none"><hr /></v-col>
                          </v-row>
                          <v-row v-if="building.BusinessIncomeCoverage && building.BusinessIncomeCoverage !== ''">
                            <v-col cols="12"
                                   md="8"
                                   class="py-0"><label class="font-weight-bold">Business Income Coverage:</label></v-col>
                            <v-col cols="12"
                                   md="4"
                                   class="py-0 text-right text-md-left">{{formatMoney(building.BusinessIncomeCoverage, 0)}}</v-col>
                            <v-col cols="12"
                                   class="py-0 d-block d-lg-none"><hr /></v-col>
                          </v-row>
                          <v-row v-if="building.SpecialClassCoverage && building.SpecialClassCoverage !== ''">
                            <v-col cols="12"
                                   md="8"
                                   class="py-0"><label class="font-weight-bold">Special Class Coverage:</label></v-col>
                            <v-col cols="12"
                                   md="4"
                                   class="py-0 text-right text-md-left">{{formatMoney(building.SpecialClassCoverage, 0)}}</v-col>
                            <v-col cols="12"
                                   class="py-0 d-block d-lg-none"><hr /></v-col>
                          </v-row>
                          <v-row v-if="building.DeductibleAmount && building.DeductibleAmount !== ''">
                            <v-col cols="12"
                                   md="8"
                                   class="py-0"><label class="font-weight-bold">Deductible:</label></v-col>
                            <v-col cols="12"
                                   md="4"
                                   class="py-0 text-right text-md-left">{{formatMoney(building.DeductibleAmount, 0)}}</v-col>
                            <v-col cols="12"
                                   class="py-0 d-block d-lg-none"><hr /></v-col>
                          </v-row>
                          <v-row v-if="coverageLocation.OccurrenceLimit && coverageLocation.OccurrenceLimit !== ''">
                            <v-col cols="12"
                                   md="8"
                                   class="py-0"><label class="font-weight-bold">Each Occurrence Limit (CSL):</label></v-col>
                            <v-col cols="12"
                                   md="4"
                                   class="py-0 text-right text-md-left">{{formatMoney(coverageLocation.OccurrenceLimit, 0)}}</v-col>
                            <v-col cols="12"
                                   class="py-0 d-block d-lg-none"><hr /></v-col>
                          </v-row>
                          <v-row v-if="coverageLocation.AggregateLimit && coverageLocation.AggregateLimit !== ''">
                            <v-col cols="12"
                                   md="8"
                                   class="py-0"><label class="font-weight-bold">General Aggregate Limit (CSL):</label></v-col>
                            <v-col cols="12"
                                   md="4"
                                   class="py-0 text-right text-md-left">{{formatMoney(coverageLocation.AggregateLimit, 0)}}</v-col>
                            <v-col cols="12"
                                   class="py-0 d-block d-lg-none"><hr /></v-col>
                          </v-row>
                        </v-container>
                      </div>
                      <v-col cols="12"
                             class="d-block d-lg-none"
                             v-if="index != policyDetails.Coverages.length - 1"><hr /></v-col>
                    </v-container>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <!-- Farm Liability Locations -->
          <div v-if="policyDetails.PolicyType === 'CP' && policyDetails.PolicySubType === '3'">
            <farm-liability-locations :locations="policyDetails.Locations"></farm-liability-locations>
          </div>
          <!-- Underlying Location Counties/Policies -->
          <div v-if="policyDetails.PolicyType === 'UM'">
            <!-- Underlying Location Counties -->
            <underlying-locations :locations="policyDetails.Locations"></underlying-locations>
            <!-- Underlying Policies -->
            <underlying-policies :auto-policies="policyDetails.UnderlyingAutoPolicies"
                                 :property-policies="policyDetails.UnderlyingPropertyPolicies"></underlying-policies>
          </div>
          <div v-if="policyDetails.PolicyType === 'SM' || policyDetails.PolicyType === 'CA'">
            <!-- Vehicle Information -->
            <vehicles :policyType="policyDetails.PolicyType"
                      :vehicles="policyDetails.Vehicles"></vehicles>
            <!-- Driver Information -->
            <drivers :coveredDrivers="policyDetails.CoveredDrivers"
                     :excludedDrivers="policyDetails.ExcludedDrivers"></drivers>
          </div>
        </v-container>
      </v-skeleton-loader>
    </v-container>
  </div>
</template>
<script>
  import { dispatch } from 'vuex-pathify';
  import tfbMetaInfo from '@/mixins/metaInfo';
  import tfbHelperMixin from '@/mixins/helpers';
  import PolicyPaymentList from './policy/PolicyPaymentList.vue';
  import LinkList from './components/LinkList.vue';
  import BillingDocumentList from './policy/BillingDocumentList.vue';
  import PolicyDocumentList from './policy/PolicyDocumentList.vue';
  import GuidewirePolicyCoverages from './policy/GuidewirePolicyCoverages.vue';
  import GuidewireEndorsements from './policy/GuidewireEndorsements.vue';
  import GuidewireMortgageesLossPayees from './policy/GuidewireMortgageesLossPayees.vue';
  import Vehicles from './policy/Vehicles.vue';
  import Drivers from './policy/Drivers.vue';
  import UnderlyingLocations from './policy/UnderlyingLocations.vue';
  import UnderlyingPolicies from './policy/UnderlyingPolicies.vue';
  import FarmLiabilityLocations from './policy/FarmLiabilityLocations.vue';
  import BillingPaymentHistory from './policy/BillingPaymentHistory.vue';

  export default {
    name: 'PolicyDetails',
    props: {
      policyNumber: {
        type: String,
        required: true,
      },
      policyType: {
        type: String,
        required: true,
      },
    },
    mixins: [tfbMetaInfo, tfbHelperMixin],
    components: {
      PolicyPaymentList,
      LinkList,
      BillingDocumentList,
      PolicyDocumentList,
      GuidewirePolicyCoverages,
      GuidewireEndorsements,
      GuidewireMortgageesLossPayees,
      Vehicles,
      Drivers,
      UnderlyingLocations,
      UnderlyingPolicies,
      FarmLiabilityLocations,
      BillingPaymentHistory,
    },
    data() {
      return {
        policyLoading: true,
        policyDetails: null,
        showPolicyDocuments: false,
        paymentHistoryHeaders: [
          { text: 'Date', align: 'start', value: 'PaymentDate' },
          { text: 'Amount', value: 'PaymentAmount' },
          { text: 'Type', value: 'Description' },
        ],
        pfPaymentHistoryHeaders: [
          { text: 'Date', align: 'start', value: 'PaymentDate' },
          { text: 'Description', value: 'Description' },
          { text: 'Amount', value: 'Amount' },
          { text: 'Remaining Balance', value: 'RemainingBalance' },
        ],
        abPolicyHeaders: [
          { text: 'Policy Description', align: 'start', value: 'policyDescription' },
          { text: 'Policy Number', value: 'policyNbr' },
          { text: 'Member Number', value: 'membershipNbr' },
        ],
        abPaymentHistoryHeaders: [
          { text: 'Date', align: 'start', value: 'paymentDate' },
          { text: 'Amount', value: 'payorAmount' },
          { text: 'Method', value: 'payeeTypeDescr' },
        ],
      };
    },
    computed: {
      metaPageTitle() {
        return this.policyDetails ? `${this.policyDetails.PolicyDescription} | PolicyHolder` : 'Policy Details | PolicyHolder';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/policyholder/policy/${this.$route.params.type}/${this.$route.params.number}`;
      },
      isMemberRole() {
        try {
          const memberRole = this.$store.getters['member/role'];
          if (memberRole === 'I') {
            return true;
          }
          return false;
        } catch (error) {
          return false;
        }
      },
      memberNumber() {
        try {
          return this.$store.getters['member/memberNumber'];
        } catch (error) {
          return '';
        }
      },
    },
    mounted() {
      // Clear out any existing error messages
      dispatch('app/clearErrorMessage');

      // Show the loading overlay
      dispatch('app/setIsLoading', true);

      // Clear out any existing access page
      dispatch('app/setAccessPage', null);

      // Check that the application is active
      this.$store.dispatch('app/doApplicationCheck', { sApplicationName: 'Policyholder', sTfbId: this.trackingId })
        .then((returnedStatus) => {
          if (returnedStatus === '') {
            // Check that the member is logged in
            const includedToken = this.$route.query.access_token;
            dispatch('member/validateLogin', { sToken: includedToken, sTfbId: this.trackingId })
              .then((loginValid) => {
                if (loginValid) {
                  if (this.doStatusCheck()) {
                    // Clear the access page
                    dispatch('app/setAccessPage', null);

                    // Make sure we have the Summary items
                    dispatch('policy/getAccountSummary', { sMemberNumber: this.memberNumber, sTfbId: this.trackingId })
                      .then(() => {
                        // Get the policy details
                        this.getPolicyDetails();
                      })
                      .catch(() => {
                        // Clear the loading overlay
                        this.$store.dispatch('app/setIsLoading', false);
                      });
                  }
                } else {
                  // Save the access page
                  this.$store.dispatch('app/setAccessPage', this.$router.currentRoute);

                  // Log out the member
                  dispatch('member/doLogout')
                    .then(() => {
                      // Clear the loading overlay
                      this.$store.dispatch('app/setIsLoading', false);

                      // Redirect to the login
                      this.$router.push({ name: 'policyholderLogin' });
                    })
                    .catch((accessError) => {
                      // Clear the loading overlay
                      this.$store.dispatch('app/setIsLoading', false);

                      // Show the error message
                      this.$store.dispatch('app/setErrorMessage', accessError.toString());
                    });
                }
              })
              .catch((error) => {
                // Show the error message
                dispatch('app/setErrorMessage', error.toString());

                // Clear the loading overlay
                this.$store.dispatch('app/setIsLoading', false);
              });
          } else {
            // Show the error message
            this.$store.dispatch('app/setErrorMessage', returnedStatus);

            // Clear the loading overlay
            this.$store.dispatch('app/setIsLoading', false);
          }
        })
        .catch((error) => {
          // Show the error message
          this.$store.dispatch('app/setErrorMessage', error.toString());

          // Clear the loading overlay
          this.$store.dispatch('app/setIsLoading', false);
        });

      // Clear the loading overlay
      dispatch('app/setIsLoading', false);
    },
    methods: {
      async getPolicyDetails() {
        dispatch('policy/getPolicyDetails', { sPolicyNumber: this.policyNumber, sPolicyType: this.policyType, sTfbId: this.trackingId })
          .then((policyResponse) => {
            this.policyDetails = policyResponse;
            // Get the policy types/subtypes allowed on Paperless Policy
            this.$store.dispatch('app/getFeatureFlag', { sTitle: 'PaperlessPolicyDisplayAllowed' })
              .then((allowedPaperless) => {
                if (allowedPaperless && allowedPaperless.length > 0) {
                  const allowedPaperlessSplit = allowedPaperless.split(',');

                  // Loop through list of allowed Paperless Policy types
                  for (let a = 0; a < allowedPaperlessSplit.length; a += 1) {
                    if (allowedPaperlessSplit[a]) {
                      const policyTypeSplit = allowedPaperlessSplit[a].split('-');

                      // Is this policy type/subtype allowed to view Paperless Policy documents?
                      if (policyTypeSplit.length > 1 && this.policyDetails.PolicyType === policyTypeSplit[0] && this.policyDetails.PolicySubType === policyTypeSplit[1]) {
                        // Show Paperless Policy documents section
                        this.showPolicyDocuments = true;
                      }
                    }
                  }
                }
              })
              .catch((error) => {
                this.$store.dispatch('app/setErrorMessage', error.toString());
                this.showPolicyDocuments = false;
              });
            this.policyLoading = false;
          })
          .catch((error) => {
            if (error.toString().indexOf('Request failed with status code 401') >= 0) {
              // Session has timed out, log out and go to login
              this.policyLoading = false;
              this.$store.dispatch('app/setIsLoading', true);
              // Save the access page
              this.$store.dispatch('app/setAccessPage', this.$router.currentRoute);

              // Log out the member
              dispatch('member/doLogout')
                .then(() => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$router.push({ name: 'policyholderLogin' });
                })
                .catch((accessError) => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$store.dispatch('app/setErrorMessage', accessError.toString());
                });
            } else if (error.toString().indexOf('No policy details') >= 0) {
              // Session has timed out, log out and go to login
              this.policyLoading = false;
              this.$store.dispatch('app/setIsLoading', true);
              // Save the access page
              this.$store.dispatch('app/setAccessPage', this.$router.currentRoute);

              // Log out the member
              dispatch('member/doLogout')
                .then(() => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$router.push({ name: 'policyholderLogin' });
                })
                .catch((accessError) => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$store.dispatch('app/setErrorMessage', accessError.toString());
                });
            }
            dispatch('app/setErrorMessage', error.toString());
            this.policyLoading = false;
            this.documentLoading = false;
          });
      },
      showPolicyDefinition(policyType, anchorText, newClassCode) {
        console.log(`TODO: Fetching definition for ${policyType}-${anchorText}-${newClassCode}!`);
        // var definition = { "define": policyType + anchorText, "hasNewClass": ((newClassCode) ? (newClassCode !== '' ? true : false) : false) };
        // localStorageService.set('txfbDefine', definition);
        // $('#phPolicyDefinitions').modal();
      },
    },
  };
</script>
<style lang="scss">

  .policy-box {
    background-color: $white;
  }

  div .policy-separator:not(:first-child) {
    border-top: solid 1px $tfb-gray;
  }

  @media (min-width: 960px) {
    div .policy-separator:not(:first-child) {
      border-top: none;
      border-left: solid 1px $tfb-gray;
    }
  }

  @media (max-width: 960px) {
    .v-expansion-panel-content__wrap {
      padding: 0 6px 16px !important;
    }
  }

  .tfb-section .v-expansion-panel-header {
    min-height: 16px !important;
  }
</style>
