<template>
  <div>
    <v-row>
      <v-col cols="12">
        <a :href="membershipPaymentsUrl"
           v-if="!delinquentIndicator"
           target="_blank">
          Make a Membership Payment
        </a>
        <span class="header-text text-red"
              v-else>
          To serve you better, you will be given an opportunity to pay your membership dues when making a payment below.
        </span>
      </v-col>
    </v-row>
    <v-row v-if="memberList && memberList.length > 1">
      <v-col cols="12"
             sm="7"
             md="5"
             lg="4">
        <strong>
          Looking for a different membership?
        </strong>
      </v-col>
      <v-col cols="12"
             sm="4">
        <v-select outlined
                  dense
                  :items="filteredMemberList"
                  item-text="Lng"
                  item-value="Lng"
                  hide-details="auto"
                  return-object
                  v-model="switchMember"
                  v-on:change="switchMembership()">
        </v-select>
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import { dispatch } from 'vuex-pathify';
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'MembershipHeader',
    mixins: [tfbHelperMixin],
    data() {
      return {
        membershipPaymentsBaseUrl: '',
        switchMember: null,
      };
    },
    computed: {
      delinquentIndicator() {
        try {
          return this.$store.getters['member/delinquentIndicator'];
        } catch (error) {
          return false;
        }
      },
      memberList() {
        try {
          return this.$store.getters['member/memberList'];
        } catch (error) {
          return null;
        }
      },
      memberNumber() {
        try {
          return this.$store.getters['member/memberNumber'];
        } catch (error) {
          return '';
        }
      },
      memberZipCode() {
        try {
          const memberAddress = this.$store.getters['member/memberAddress'];
          if (memberAddress && memberAddress.Zipcode) {
            return memberAddress.Zipcode.length > 5 ? memberAddress.Zipcode.substr(0, 5) : memberAddress.Zipcode;
          }
          return '';
        } catch (error) {
          return '';
        }
      },
      membershipPaymentsUrl() {
        const gwPaymentsEnabled = this.$store.getters['app/guidewirePaymentsEnabled'];
        const membershipBaseUrl = this.$store.getters['app/membershipPaymentsUrl'];

        if (gwPaymentsEnabled && gwPaymentsEnabled === 'Y') {
          return membershipBaseUrl;
        }

        let buildUrl = `${membershipBaseUrl}?mbrnum=${this.memberNumber}`;
        if (this.memberZipCode && this.memberZipCode !== '') {
          buildUrl += `&zip=${this.memberZipCode}`;
        }

        return buildUrl;
      },
      filteredMemberList() {
        let search = null;
        if (this.memberNumber) {
          search = this.memberNumber;
        }
        if (!search) return this.memberList;
        if (!this.memberList) return this.memberList;
        return this.memberList.filter(c => c.Nbr.toUpperCase().indexOf(search) < 0);
      },
    },
    methods: {
      switchMembership() {
        dispatch('app/clearErrorMessage');
        dispatch('app/setIsLoading', true);

        dispatch('member/switchMembership', { sMemberNumber: this.switchMember.Lng, sMemberId: this.switchMember.Id, sTfbId: this.trackingId })
          .then((switchResult) => {
            if (switchResult) {
              this.switchMember = null;
            }
            dispatch('app/setIsLoading', false);
          })
          .catch((error) => {
            dispatch('app/setErrorMessage', error.toString());
            dispatch('app/setIsLoading', false);
          });
      },
    },
  };
</script>
<style lang="scss">
</style>
