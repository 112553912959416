<template>
  <v-expansion-panels accordion
                      flat
                      class="tfb-section my-3">
    <v-expansion-panel>
      <v-expansion-panel-header class="px-0">
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="12"
                 md="5">
            <template v-slot:actions>
              <v-icon>$expand</v-icon>
            </template>
            <h3 class="text-blue font-weight-bold">Billing and Payment History</h3>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="background-lightest-gray">
        <!-- Non-Premium Finance/Account Bill Billing Information -->
        <div v-if="policyDetails.PolicyType !== 'PR' && policyDetails.PolicyType !== 'AB'">
          <v-row>
            <v-col cols="12">
              <h4 class="tfb-title-text-smaller text-blue font-weight-bold">Premium For Current Policy</h4>
            </v-col>
          </v-row>
          <div class="px-2">
            <v-row v-if="policyDetails.TermPremiumAmount && policyDetails.TermPremiumAmount !== '' && policyDetails.TermPremiumAmount !== '0' && policyDetails.TermPremiumAmount !== '0.00'">
              <v-col cols="12"
                     sm="6"
                     class="py-1">
                <span class="font-weight-bold">Term Premium For Current Policy:</span>
              </v-col>
              <v-col cols="12"
                     sm="6"
                     class="py-1 text-right">
                {{formatMoney(policyDetails.TermPremiumAmount, 0)}}
              </v-col>
            </v-row>
            <v-row v-if="policyDetails.TotalDiscounts && policyDetails.TotalDiscounts !== '' && policyDetails.TotalDiscounts !== '0' && policyDetails.TotalDiscounts !== '0.00'">
              <v-col cols="12"
                     sm="6"
                     class="py-1">
                <span class="font-weight-bold">Total Discounts For Current Policy:</span>
              </v-col>
              <v-col cols="12"
                     sm="6"
                     class="py-1 text-right">
                {{formatMoney(policyDetails.TotalDiscounts, 0)}}
              </v-col>
            </v-row>
            <v-row v-if="policyDetails.BilledPremiumAmount && policyDetails.BilledPremiumAmount !== '' && policyDetails.BilledPremiumAmount !== '0' && policyDetails.BilledPremiumAmount !== '0.00'">
              <v-col cols="12"
                     sm="6"
                     class="py-1">
                <span class="font-weight-bold">Billed Premium For Current Policy:</span>
              </v-col>
              <v-col cols="12"
                     sm="6"
                     class="py-1 text-right">
                {{formatMoney(policyDetails.BilledPremiumAmount, 0)}}
              </v-col>
            </v-row>
            <v-row v-if="(policyDetails.PolicyType === 'SM' || policyDetails.PolicyType === 'CA')
                   && policyDetails.TheftFee && policyDetails.TheftFee !== '' && policyDetails.TheftFee !== '0' && policyDetails.TheftFee !== '0.00'">
              <v-col cols="10"
                     sm="6"
                     class="py-1">
                <span class="font-weight-bold">Motor Vehicle Crime Prevention Authority Fee:</span>
              </v-col>
              <v-col cols="2"
                     sm="6"
                     class="py-1 text-right">
                {{formatMoney(policyDetails.TheftFee, 2)}}
              </v-col>
            </v-row>
            <v-row v-if="policyDetails.PreviousDebitCredit && policyDetails.PreviousDebitCredit !== '' && policyDetails.PreviousDebitCredit !== '0' && policyDetails.PreviousDebitCredit !== '0.00'">
              <v-col cols="10"
                     sm="6"
                     class="py-1">
                <span class="font-weight-bold">Previous Debit/Credit:</span>
              </v-col>
              <v-col cols="2"
                     sm="6"
                     class="py-1 text-right">
                {{formatMoney(policyDetails.PreviousDebitCredit, 2)}}
              </v-col>
            </v-row>
            <v-row v-if="policyDetails.Adjustments && policyDetails.Adjustments !== '' && policyDetails.Adjustments !== '0' && policyDetails.Adjustments !== '0.00'">
              <v-col cols="10"
                     sm="6"
                     class="py-1">
                <span class="font-weight-bold">Adjustments Due To Changes:</span>
              </v-col>
              <v-col cols="2"
                     sm="6"
                     class="py-1 text-right">
                {{formatMoney(policyDetails.Adjustments, 0)}}
              </v-col>
            </v-row>
          </div>
        </div>
        <!-- Account Bill Billing Information -->
        <div v-if="policyDetails.PolicyType === 'AB'">
          <!-- Current Invoice -->
          <v-row>
            <v-col cols="12">
              <h4 class="tfb-title-text-smaller text-blue font-weight-bold">Current Invoice</h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <table class="table table-responsive table-striped table-condensed account-bill-table">
                <thead>
                  <tr>
                    <th>Policy Number</th>
                    <th>Previous Balance</th>
                    <th>Payment Activity</th>
                    <th>Refunds</th>
                    <th>Fees</th>
                    <th>Past Due Amount</th>
                    <th>Current Amount Due</th>
                    <th>Remaining Balance</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!policyDetails.CurrentInvoice || policyDetails.CurrentInvoice.length === 0">
                    <td colspan="8">Current Invoice Unavailable</td>
                  </tr>
                  <tr v-for="invoice in policyDetails.CurrentInvoice"
                      v-bind:key="invoice.id">
                    <td><span v-html="invoice.policyNbr"></span></td>
                    <td><span v-html="invoice.remainingBalanceFromLastStatement"></span></td>
                    <td><span v-html="invoice.paymentActivity"></span></td>
                    <td><span v-html="invoice.refunds"></span></td>
                    <td><span v-html="invoice.fees"></span></td>
                    <td><span v-html="invoice.pastAmountDue"></span></td>
                    <td><span v-html="invoice.currentAmountDue"></span></td>
                    <td><span v-html="invoice.remainingBalance"></span></td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr v-if="policyDetails.CurrentInvoice && policyDetails.CurrentInvoice.length > 0">
                    <td class="font-weight-bold">Totals</td>
                    <td><span v-html="currentPreviousBalance"></span></td>
                    <td><span v-html="currentPaymentActivity"></span></td>
                    <td><span v-html="currentRefunds"></span></td>
                    <td><span v-html="currentFees"></span></td>
                    <td><span v-html="currentPastDue"></span></td>
                    <td><span v-html="currentCurrentAmount"></span></td>
                    <td><span v-html="currentRemainingBalance"></span></td>
                  </tr>
                </tfoot>
              </table>
            </v-col>
          </v-row>
          <!-- Projected Next Invoice -->
          <div class="row detail-row">
            <div class="col-ss-12">
              <h4 class="tfb-title-text-smaller text-blue font-weight-bold">Projected Next Invoice</h4>
            </div>
          </div>
          <div class="row policy-detail-row">
            <div class="col-ss-12">
              <table class="table table-responsive table-striped table-condensed account-bill-table">
                <thead>
                  <tr>
                    <th>Policy Number</th>
                    <th>Previous Balance</th>
                    <th>Payment Activity</th>
                    <th>Refunds</th>
                    <th>Fees</th>
                    <th>Past Due Amount</th>
                    <th>Current Amount Due</th>
                    <th>Remaining Balance</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!policyDetails.NextInvoice || policyDetails.NextInvoice.length === 0">
                    <td colspan="8">Projected Next Invoice Unavailable</td>
                  </tr>
                  <tr v-for="invoice in policyDetails.NextInvoice"
                      v-bind:key="invoice.id">
                    <td><span v-html="invoice.policyNbr"></span></td>
                    <td><span v-html="invoice.remainingBalanceFromLastStatement"></span></td>
                    <td><span v-html="invoice.paymentActivity"></span></td>
                    <td><span v-html="invoice.refunds"></span></td>
                    <td><span v-html="invoice.fees"></span></td>
                    <td><span v-html="invoice.pastAmountDue"></span></td>
                    <td><span v-html="invoice.currentAmountDue"></span></td>
                    <td><span v-html="invoice.remainingBalance"></span></td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr v-if="nextInvoice && nextInvoice.length > 0">
                    <td class="font-weight-bold">Totals</td>
                    <td><span v-html="nextPreviousBalance"></span></td>
                    <td><span v-html="nextPaymentActivity"></span></td>
                    <td><span v-html="nextRefunds"></span></td>
                    <td><span v-html="nextFees"></span></td>
                    <td><span v-html="nextPastDue"></span></td>
                    <td><span v-html="nextCurrentAmount"></span></td>
                    <td><span v-html="nextRemainingBalance"></span></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <!-- Previous Invoice -->
          <div class="row detail-row">
            <div class="col-ss-12">
              <h4 class="tfb-title-text-smaller text-blue font-weight-bold">Previous Invoice</h4>
            </div>
          </div>
          <div class="row policy-detail-row">
            <div class="col-ss-12">
              <table class="table table-responsive table-striped table-condensed account-bill-table">
                <thead>
                  <tr>
                    <th>Policy Number</th>
                    <th>Previous Balance</th>
                    <th>Payment Activity</th>
                    <th>Refunds</th>
                    <th>Fees</th>
                    <th>Past Due Amount</th>
                    <th>Current Amount Due</th>
                    <th>Remaining Balance</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!policyDetails.PastInvoice || policyDetails.PastInvoice.length === 0">
                    <td colspan="8">Previous Invoice Unavailable</td>
                  </tr>
                  <tr v-for="invoice in policyDetails.PastInvoice"
                      v-bind:key="invoice.id">
                    <td><span v-html="invoice.policyNbr"></span></td>
                    <td><span v-html="invoice.remainingBalanceFromLastStatement"></span></td>
                    <td><span v-html="invoice.paymentActivity"></span></td>
                    <td><span v-html="invoice.refunds"></span></td>
                    <td><span v-html="invoice.fees"></span></td>
                    <td><span v-html="invoice.pastAmountDue"></span></td>
                    <td><span v-html="invoice.currentAmountDue"></span></td>
                    <td><span v-html="invoice.remainingBalance"></span></td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr v-if="policyDetails.PastInvoice && policyDetails.PastInvoice.length > 0">
                    <td class="font-weight-bold">Totals</td>
                    <td><span v-html="previousPreviousBalance"></span></td>
                    <td><span v-html="previousPaymentActivity"></span></td>
                    <td><span v-html="previousRefunds"></span></td>
                    <td><span v-html="previousFees"></span></td>
                    <td><span v-html="previousPastDue"></span></td>
                    <td><span v-html="previousCurrentAmount"></span></td>
                    <td><span v-html="previousRemainingBalance"></span></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
        <!-- Payment History -->
        <v-row>
          <v-col cols="12">
            <h4 class="tfb-title-text-smaller text-blue font-weight-bold">Payment History</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <!-- Non-Premium Finance Payment History -->
            <v-data-table dense
                          :headers="paymentHistoryHeaders"
                          :items="policyDetails.Payments"
                          :items-per-page="10"
                          sort-by="PaymentDate"
                          sort-desc
                          v-if="policyDetails.PolicyType !== 'PR' && policyDetails.PolicyType !== 'AB'"
                          class="tfb-table">
              <template v-slot:no-data>
                No Payment History Available
              </template>
              <template v-slot:item.PaymentDate="{ item }">
                {{formatDate(item.PaymentDate)}}
              </template>
              <template v-slot:item.PaymentAmount="{ item }">
                {{formatMoney(item.PaymentAmount, 2)}}
              </template>
            </v-data-table>
            <!-- Premium Finance Payment History -->
            <v-data-table dense
                          :headers="pfPaymentHistoryHeaders"
                          :items="policyDetails.Payments"
                          :items-per-page="10"
                          v-if="policyDetails.PolicyType === 'PR'">
              <template v-slot:no-data>
                No Payment History Available
              </template>
            </v-data-table>
            <!-- Account Bill Payment History -->
            <v-data-table dense
                          :headers="abPaymentHistoryHeaders"
                          :items="policyDetails.CurrentPayments"
                          :items-per-page="10"
                          sort-by="paymentDate"
                          sort-desc
                          v-if="policyDetails.PolicyType === 'AB'">
              <template v-slot:no-data>
                No Payment History Available
              </template>
              <template v-slot:item.paymentDate="{ item }">
                {{formatDate(item.paymentDate)}}
              </template>
              <template v-slot:item.payorAmount="{ item }">
                {{formatMoney(item.payorAmount, 2)}}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'BillingPaymentHistory',
    props: {
      policyDetails: {
        type: Object,
        required: true,
      },
    },
    mixins: [tfbHelperMixin],
    data() {
      return {
        paymentHistoryHeaders: [
          { text: 'Date', align: 'start', value: 'PaymentDate' },
          { text: 'Amount', value: 'PaymentAmount' },
          { text: 'Type', value: 'Description' },
        ],
        pfPaymentHistoryHeaders: [
          { text: 'Date', align: 'start', value: 'PaymentDate' },
          { text: 'Description', value: 'Description' },
          { text: 'Amount', value: 'Amount' },
          { text: 'Remaining Balance', value: 'RemainingBalance' },
        ],
        abPolicyHeaders: [
          { text: 'Policy Description', align: 'start', value: 'policyDescription' },
          { text: 'Policy Number', value: 'policyNbr' },
          { text: 'Member Number', value: 'membershipNbr' },
        ],
        abPaymentHistoryHeaders: [
          { text: 'Date', align: 'start', value: 'paymentDate' },
          { text: 'Amount', value: 'payorAmount' },
          { text: 'Method', value: 'payeeTypeDescr' },
        ],
      };
    },
  };
</script>
<style lang="scss">

</style>
