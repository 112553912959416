<template>
  <v-col cols="12"
         md="5"
         class="policy-separator">
    <!-- Account Bill link list -->
    <div v-if="displayType === 'accountbill'">
      <router-link v-if="policyAccount.DetailPath && displayLocation === 'summary'"
                   :to="{ name: 'policyholderPolicyDetail', params: { policyType: policyAccount.DetailPath, policyNumber: policyAccount.MasterAccountNumber }}">
        View Account Details<br />
      </router-link>
      <a href=""
         v-on:click.prevent="recurringPayments(policyAccount.CurrentRecurring,policyAccount.MasterAccountNumber,'AB','0',policyAccount.InsuredFullName,'')">
        <span v-if="policyAccount.CurrentRecurring === 'CHANGE'">Modify Recurring Payments</span>
        <span v-else>Schedule Recurring Payments</span>
        <br />
      </a>
      <router-link v-if="showTextEnrollmentLink || showEbillEnrollmentLink"
                   :to="{ name: 'policyholderAccountManagement', hash: '#ebill'}">
        <span v-if="showTextEnrollmentLink">E-bill Paperless Billing and Text Message Notifications Settings</span>
        <span v-else-if="policyAccount.EbillStatus === 'Y'">Modify E-billing</span>
        <span v-else>Sign Up For E-billing</span>
        <br />
      </router-link>
      <span v-else>
        <span v-if="showTextEnrollmentLink">Not Currently Eligible For E-bill Paperless Billing or Text Message Notifications</span>
        <span v-else>Not Currently Eligible For E-bill Paperless Billing</span><br />
      </span>
      <router-link v-if="policyAccount.AccountBillEligibleFlag === 'Y'"
                   :to="{ name: 'policyholderAccountBill' }">
        Sign Up For Account Bill<br />
      </router-link>
      <a href=""
         v-on:click.prevent="getCurrentBillingDocument(policyAccount.MasterAccountNumber,'AB','0',policyAccount.MemberNumber)">
        View Current Bill
      </a>&nbsp;<v-icon class="mdi-18px">mdi-open-in-new</v-icon><br />
    </div>
    <!-- Policy link list -->
    <div v-else>
      <router-link v-if="policyAccount.PolicyLinkFlag === 'Y' && policyAccount.DetailPath !== '' && displayLocation === 'summary'"
                   :to="{ name: 'policyholderPolicyDetail', params: { policyType: policyAccount.DetailPath, policyNumber: policyAccount.PolicyNumber }}">
        View Policy Details<br />
      </router-link>
      <!-- Laurus/Guidewire Billed Policies -->
      <span v-if="policyAccount.LaurusFlag === 'Y' || policyAccount.GuidewireFlag === 'Y'">
        <!-- Active Laurus Billed Policies Not on Account Bill -->
        <span v-if="policyAccount.PolicyStatus === 'ACTIVE' && policyAccount.MasterAccountNumber === ''">
          <a href=""
             v-if="(policyAccount.CurrentRecurring === 'SIGNUP' || policyAccount.CurrentRecurring === 'CHANGE') && policyAccount.GuidewireFlag !== 'Y' && policyAccount.HasGuidewireEquivalent !== 'Y'"
             v-on:click.prevent="recurringPayments(policyAccount.CurrentRecurring, policyAccount.PolicyNumber, policyAccount.PolicyType,
                         policyAccount.PolicySubType, policyAccount.PolicyInsuredName, policyAccount.PolicyEffectiveDate)">
            <span v-if="policyAccount.CurrentRecurring === 'CHANGE'">Modify Recurring Payments</span>
            <span v-else>Schedule Recurring Payments</span>
            <br />
          </a>
          <a href=""
             v-if="(policyAccount.GuidewireFlag === 'Y' || policyAccount.HasGuidewireEquivalent === 'Y') && policyAccount.PolicyRecurringFlag !== 'X'"
             v-on:click.prevent="invoiceCloudRecurringPayments(policyAccount.PolicyNumber)">
            Schedule Recurring Payments
            <br />
          </a>
          <a href=""
             v-if="policyAccount.GuidewireFlag === 'Y' || policyAccount.HasGuidewireEquivalent === 'Y'"
             v-on:click.prevent="invoiceCloudPaymentMethods(policyAccount.PolicyNumber)">
            Manage Saved Payment Methods
            <br />
          </a>
          <router-link v-if="showTextEnrollmentLink || showEbillEnrollmentLink"
                       :to="{ name: 'policyholderAccountManagement', hash: '#ebill'}">
            E-bill Paperless Billing and Text Message Notifications Settings<br />
          </router-link>
          <router-link :to="{ name: 'policyholderAccountManagement', hash: '#paperless'}"
                       v-if="showPolicyEnrollmentLink !== null && showPolicyEnrollmentLink">
            <span v-if="policyAccount.PaperlessFlag === 'Y'">Modify Paperless Notifications</span>
            <span v-else>Sign Up For Paperless Notifications</span>
            <br />
          </router-link>
          <router-link v-if="policyAccount.AccountBillEligibleFlag === 'Y'"
                       :to="{ name: 'policyholderAccountBill' }">
            Sign Up For Account Bill<br />
          </router-link>
        </span>
        <!-- Active Laurus Billed Policies on Account Bill -->
        <span v-if="policyAccount.PolicyStatus === 'ACTIVE' && policyAccount.MasterAccountNumber !== '' && policyAccount.PaperlessFlag !== 'X'">
          <router-link :to="{ name: 'policyholderAccountManagement', hash: '#paperless'}"
                       v-if="showPolicyEnrollmentLink !== null && showPolicyEnrollmentLink">
            <span v-if="policyAccount.PaperlessFlag === 'Y'">Modify Paperless Notifications</span>
            <span v-else>Sign Up For Paperless Notifications</span>
            <br />
          </router-link>
        </span>
        <!-- All Laurus Billed Policies -->
        <a href=""
           v-if="!policyAccount.MasterAccountNumber || policyAccount.MasterAccountNumber === ''"
           v-on:click.prevent="getCurrentBillingDocument(policyAccount.PolicyNumber, policyAccount.PolicyType, policyAccount.PolicySubType, policyAccount.MemberNumber,)">
          View Current Bill&nbsp;<v-icon class="mdi-18px">mdi-open-in-new</v-icon>
        </a>
        <span v-if="!policyAccount.MasterAccountNumber || policyAccount.MasterAccountNumber === ''"><br /></span>
      </span>
      <!-- Active Non-Laurus Billed Policies-->
      <span v-else-if="policyAccount.PolicyStatus === 'ACTIVE'">
        <span v-if="showTextEnrollmentLink">Not Currently Eligible For E-bill Paperless Billing or Text Message Notifications</span><br />
      </span>
      <!-- Inactive Paperless Eligible Policies -->
      <span v-if="showPolicyEnrollmentLink !== null && showPolicyEnrollmentLink && policyAccount.PolicyStatus !== 'ACTIVE'">
        <!-- onclick.prevent, open panels array-->
        <a href=""
           v-if="!policyAccount.MasterAccountNumber || policyAccount.MasterAccountNumber === ''"
           v-on:click.prevent="showDocumentDialog = true">
          View Current Policy Documents&nbsp;<v-icon class="mdi-18px">mdi-open-in-new</v-icon>
        </a>
        <v-dialog v-model="showDocumentDialog"
                  scrollable
                  max-width="500">
          <policy-document-list :policyNumber="policyAccount.PolicyNumber"
                                :policyType="policyAccount.PolicyType"
                                :policySubType="policyAccount.PolicySubType"
                                :policySymbol="policyAccount.PolicySymbol"
                                :effectiveDate="policyAccount.PolicyEffectiveDate"
                                :expirationDate="policyAccount.PolicyExpirationDate"
                                futureEffectiveDate=""
                                futureExpirationDate=""
                                :openPanel="0"></policy-document-list>
        </v-dialog>
      </span>
      <!-- Active Auto Policies -->
      <span v-if="(policyAccount.PolicyType === 'SM' || policyAccount.PolicyType === 'CA') && policyAccount.PolicyStatus === 'ACTIVE'">
        <a href=""
           v-on:click.prevent="autoIdCard(policyAccount.PolicyNumber, policyAccount.PolicyType, policyAccount.PolicyExpirationDate);">
          Print ID Card
        </a>&nbsp;<v-icon class="mdi-18px">mdi-open-in-new</v-icon><br />
      </span>
      <!-- Active Policies with Roadside Assistance -->
      <span v-if="policyAccount.RoadsideAssistanceCardFlag === 'Y' && policyAccount.PolicyStatus === 'ACTIVE'">
        <a href=""
           v-if="policyAccount.RoadsideAssistanceCardFlag === 'Y'"
           v-on:click.prevent="roadsideAssistanceCard(policyAccount.PolicyNumber, policyAccount.PolicyType, policyAccount.PolicyExpirationDate);">
          Print Roadside Assistance Card
        </a>&nbsp;<v-icon class="mdi-18px">mdi-open-in-new</v-icon><br />
        <span v-if="ageroRoadsideEnabled && ageroRoadsideEnabled === 'Y'">
          <a :href="roadsideUrl"
             hreflang="en-us"
             target="_blank"
             rel="noopener"
             aria-label="Request Roadside Assistance (opens in a new tab)">
            Request Roadside Assistance
          </a>&nbsp;<v-icon class="mdi-18px">mdi-open-in-new</v-icon><br />
        </span>
      </span>
    </div>
  </v-col>
</template>
<script>
  import { dispatch } from 'vuex-pathify';
  import { insuredAccessOnlyErrorMessage } from '@/mixins/errorMessages';
  import tfbHelperMixin from '@/mixins/helpers';
  import PolicyDocumentList from '../policy/PolicyDocumentList.vue';

  export default {
    name: 'LinkList',
    props: {
      displayLocation: {
        type: String,
        required: true,
      },
      displayType: {
        type: String,
        required: true,
      },
      policyAccount: {
        type: Object,
        required: true,
      },
    },
    mixins: [tfbHelperMixin],
    components: {
      PolicyDocumentList,
    },
    data() {
      return {
        showDocumentDialog: false,
        roadsideUrl: null,
        showPolicyEnrollmentLink: false,
        showEbillEnrollmentLink: false,
        showTextEnrollmentLink: false,
      };
    },
    computed: {
      memberNumber() {
        try {
          return this.$store.getters['member/memberNumber'];
        } catch (error) {
          return '';
        }
      },
      ageroRoadsideEnabled() {
        try {
          return this.$store.getters['app/ageroRoadsideEnabled'];
        } catch (error) {
          return 'N';
        }
      },
    },
    methods: {
      recurringPayments(currentRecurringStatus, policyNumber, policyType, policySubType, insuredName, effectiveDate) {
        if (this.isMemberRole) {
          this.$store.dispatch('app/logToULS', {
            logLevel: 'Info',
            logMessage: `${policyNumber}-${currentRecurringStatus}`,
            fileName: 'LinkList.vue',
            methodName: 'recurringPayments-Start',
            trackerId: this.trackingId,
          });
          this.$store.dispatch('app/setIsLoading', true);
          this.$store.commit('payments/SET_RECURRING_REQUEST', {
            PolicyNumber: policyNumber,
            PolicyType: policyType,
            PolicySubType: policySubType,
            InsuredName: insuredName,
            EffectiveDate: effectiveDate,
          });
          this.$router.push({ name: 'policyholderRecurringPayments', params: { changeType: currentRecurringStatus.toLowerCase() } });
        } else {
          dispatch('app/setErrorMessage', insuredAccessOnlyErrorMessage);
        }
      },
      invoiceCloudRecurringPayments(policyNumber) {
        if (this.isMemberRole) {
          this.$store.dispatch('app/logToULS', {
            logLevel: 'Info',
            logMessage: `${policyNumber}`,
            fileName: 'LinkList.vue',
            methodName: 'invoiceCloudRecurringPayments-Start',
            trackerId: this.trackingId,
          });
          this.$store.dispatch('app/setIsLoading', true);
          this.$router.push({ name: 'policyholderInvoiceCloudAutoPay' });
        } else {
          dispatch('app/setErrorMessage', insuredAccessOnlyErrorMessage);
        }
      },
      invoiceCloudPaymentMethods(policyNumber) {
        if (this.isMemberRole) {
          this.$store.dispatch('app/logToULS', {
            logLevel: 'Info',
            logMessage: `${policyNumber}`,
            fileName: 'LinkList.vue',
            methodName: 'invoiceCloudPaymentMethods-Start',
            trackerId: this.trackingId,
          });
          this.$store.dispatch('app/setIsLoading', true);
          this.$router.push({ name: 'policyholderInvoiceCloudPaymentMethods' });
        } else {
          dispatch('app/setErrorMessage', insuredAccessOnlyErrorMessage);
        }
      },
      premiumFinancePayment(contractNumber, minimumAmountDue) {
        if (this.isMemberRole) {
          this.$store.dispatch('app/logToULS', {
            logLevel: 'Info',
            logMessage: `${contractNumber}-${minimumAmountDue}`,
            fileName: 'LinkList.vue',
            methodName: 'premiumFinancePayment-Start',
            trackerId: this.trackingId,
          });
          this.$store.dispatch('app/setIsLoading', true);
          this.$store.commit('payments/SET_PF_PAYMENT_REQUEST', {
            ContractNumber: contractNumber,
            MinimumAmountDue: minimumAmountDue,
          });
          this.$router.push({ name: 'policyholderPFPayment' });
        } else {
          dispatch('app/setErrorMessage', insuredAccessOnlyErrorMessage);
        }
      },
      getCurrentBillingDocument(policyNumber, policyType, policySubType, memberNumber) {
        this.$store.dispatch('app/setIsDocumentLoading', false);
        this.$store.dispatch('app/setIsLoading', true);
        dispatch('documents/getCurrentBillingDocument', {
          sPolicyNumber: policyNumber,
          sPolicyType: policyType,
          sPolicySubType: policySubType,
          sMemberNumber: memberNumber,
          sTfbId: this.trackingId,
        })
          .then((documentPages) => {
            this.$store.dispatch('app/setIsLoading', false);
            if (documentPages && documentPages !== null) {
              this.$store.dispatch('app/setIsDocumentLoading', true);
              dispatch('documents/showPDF', {
                inputPDFArray: documentPages, fileName: `${this.parseDate(new Date().toISOString())}-${policyNumber}-Billing.pdf`, tfbId: this.trackingId,
              })
                .then(() => {
                  // Do nothing
                })
                .catch((error) => {
                  this.$store.dispatch('app/setIsDocumentLoading', false);
                  this.$store.dispatch('app/setErrorMessage', error.toString());
                });
            }
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out, log out and go to login
              this.$store.dispatch('app/setIsLoading', true);
              dispatch('member/doLogout')
                .then(() => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$router.push({ name: 'policyholderLogin' });
                })
                .catch((accessError) => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$store.dispatch('app/setErrorMessage', accessError.toString());
                });
            } else if (error.toString().indexOf('status code 404') >= 0) {
              // Document was not found
              this.$store.dispatch('app/setIsLoading', false);
              this.$store.dispatch('app/setErrorMessage', 'Unable to load current billing document. Please try again.');
            } else {
              this.$store.dispatch('app/setIsLoading', false);
              this.$store.dispatch('app/setErrorMessage', error.toString());
            }
          });
      },
      getCurrentPolicyDocument(policyNumber, policyType, policySubType) {
        this.$store.dispatch('app/setIsDocumentLoading', false);
        this.$store.dispatch('app/setIsLoading', true);
        dispatch('documents/getCurrentPolicyDocument', {
          sPolicyNumber: policyNumber, sPolicyType: policyType, sPolicySubType: policySubType, sTfbId: this.trackingId,
        })
          .then((documentPages) => {
            this.$store.dispatch('app/setIsLoading', false);
            if (documentPages && documentPages !== null) {
              this.$store.dispatch('app/setIsDocumentLoading', true);
              dispatch('documents/showPDF', {
                inputPDFArray: documentPages, fileName: `${this.parseDate(new Date().toISOString())}-${policyNumber}-Policy.pdf`, tfbId: this.trackingId,
              })
                .then(() => {
                  // Do nothing
                })
                .catch((error) => {
                  this.$store.dispatch('app/setIsDocumentLoading', false);
                  this.$store.dispatch('app/setErrorMessage', error.toString());
                });
            }
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out, log out and go to login
              this.$store.dispatch('app/setIsLoading', true);
              dispatch('member/doLogout')
                .then(() => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$router.push({ name: 'policyholderLogin' });
                })
                .catch((accessError) => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$store.dispatch('app/setErrorMessage', accessError.toString());
                });
            } else if (error.toString().indexOf('status code 404') >= 0) {
              // Document was not found
              this.$store.dispatch('app/setIsLoading', false);
              this.$store.dispatch('app/setErrorMessage', 'Unable to load current policy document. Please try again.');
            } else {
              this.$store.dispatch('app/setIsLoading', false);
              this.$store.dispatch('app/setErrorMessage', error.toString());
            }
          });
      },
      // eslint-disable-next-line no-unused-vars
      roadsideAssistanceCard(policyNumber, policyType, expirationDate) {
        this.$store.dispatch('app/setIsDocumentLoading', false);
        this.$store.dispatch('app/setIsLoading', true);
        dispatch('documents/getRoadsideAssistanceCard', {
          sPolicyNumber: policyNumber, sPolicyType: policyType, sExpirationDate: this.parseDate(new Date().toISOString()), sTfbId: this.trackingId,
        })
          .then((documentPages) => {
            this.$store.dispatch('app/setIsLoading', false);
            if (documentPages && documentPages !== null) {
              this.$store.dispatch('app/setIsDocumentLoading', true);
              dispatch('documents/showPDF', {
                inputPDFArray: documentPages, fileName: `${this.parseDate(new Date().toISOString())}-${policyNumber}-RoadsideAssistanceCard.pdf`, tfbId: this.trackingId,
              })
                .then(() => {
                  // Do nothing
                })
                .catch((error) => {
                  this.$store.dispatch('app/setIsDocumentLoading', false);
                  this.$store.dispatch('app/setErrorMessage', error.toString());
                });
            }
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out, log out and go to login
              this.$store.dispatch('app/setIsLoading', true);
              dispatch('member/doLogout')
                .then(() => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$router.push({ name: 'policyholderLogin' });
                })
                .catch((accessError) => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$store.dispatch('app/setErrorMessage', accessError.toString());
                });
            } else if (error.toString().indexOf('status code 404') >= 0) {
              // Document was not found
              this.$store.dispatch('app/setIsLoading', false);
              this.$store.dispatch('app/setErrorMessage', 'Unable to load Roadside Assistance card. Please try again.');
            } else {
              this.$store.dispatch('app/setIsLoading', false);
              this.$store.dispatch('app/setErrorMessage', error.toString());
            }
          });
      },
      // eslint-disable-next-line no-unused-vars
      autoIdCard(policyNumber, policyType, expirationDate) {
        this.$store.dispatch('app/setIsDocumentLoading', false);
        this.$store.dispatch('app/setIsLoading', true);
        dispatch('documents/getAutoIdCard', {
          sPolicyNumber: policyNumber, sPolicyType: policyType, sExpirationDate: this.parseDate(new Date().toISOString()), sTfbId: this.trackingId,
        })
          .then((documentPages) => {
            this.$store.dispatch('app/setIsLoading', false);
            if (documentPages && documentPages !== null) {
              this.$store.dispatch('app/setIsDocumentLoading', true);
              dispatch('documents/showPDF', {
                inputPDFArray: documentPages, fileName: `${this.parseDate(new Date().toISOString())}-${policyNumber}-AutoIdCard.pdf`, tfbId: this.trackingId,
              })
                .then(() => {
                  // Do nothing
                })
                .catch((error) => {
                  this.$store.dispatch('app/setIsDocumentLoading', false);
                  this.$store.dispatch('app/setErrorMessage', error.toString());
                });
            }
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out, log out and go to login
              this.$store.dispatch('app/setIsLoading', true);
              dispatch('member/doLogout')
                .then(() => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$router.push({ name: 'policyholderLogin' });
                })
                .catch((accessError) => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$store.dispatch('app/setErrorMessage', accessError.toString());
                });
            } else if (error.toString().indexOf('status code 404') >= 0) {
              // Document was not found
              this.$store.dispatch('app/setIsLoading', false);
              this.$store.dispatch('app/setErrorMessage', 'Unable to load current ID card. Please try again later.');
            } else {
              this.$store.dispatch('app/setIsLoading', false);
              this.$store.dispatch('app/setErrorMessage', error.toString());
            }
          });
      },
    },
    mounted() {
      if (this.ageroRoadsideEnabled) {
        // Get the Roadside Assistance url
        this.$store.dispatch('app/getConfiguration', { sSection: 'ExternalUrl', sTitle: 'RequestRoadsideAssistance' })
          .then((roadsideAssistance) => {
            this.roadsideUrl = roadsideAssistance;
          })
          .catch(() => {
            // Do nothing
          });
      }

      if (this.policyAccount.GuidewireFlag === 'Y' || this.policyAccount.HasGuidewireEquivalent === 'Y') {
        // This policy is on Guidewire or has a Guidewire equivalent, so they can enroll in E-bill/Text
        this.showEbillEnrollmentLink = true;
        this.showTextEnrollmentLink = true;
      } else {
        // Get the policy types/subtypes allowed to enroll in E-bill
        this.$store.dispatch('app/getFeatureFlag', { sTitle: 'AllowedEbill' })
          .then((allowedEbill) => {
            if (allowedEbill && allowedEbill.length > 0) {
              const allowedEbillSplit = allowedEbill.split(',');

              // Loop through list of allowed E-bill types
              for (let a = 0; a < allowedEbillSplit.length; a += 1) {
                const ebillType = allowedEbillSplit[a];
                if (ebillType) {
                  const policyTypeSplit = ebillType.split('-');

                  // Is this policy type/subtype allowed to enroll in E-bill?
                  if ((policyTypeSplit.length > 1 && this.policyAccount.PolicyType === policyTypeSplit[0] && this.policyAccount.PolicySubType === policyTypeSplit[1])
                    || this.policyAccount.GuidewireFlag === 'Y') {
                    // Show E-bill enrollment link
                    this.showEbillEnrollmentLink = true;
                  }
                }
              }
            } else {
              // Unable to find policy types/subtypes or none are listed
              this.showEbillEnrollmentLink = null;
            }
          })
          .catch((error) => {
            this.$store.dispatch('app/setErrorMessage', error.toString());
            this.showEbillEnrollmentLink = false;
          });

        // Get the policy types/subtypes allowed to enroll in Text Notifications
        this.$store.dispatch('app/getFeatureFlag', { sTitle: 'AllowedEbillTextNotify' })
          .then((allowedEbillText) => {
            if (allowedEbillText && allowedEbillText.length > 0) {
              const allowedEbillSplit = allowedEbillText.split(',');

              // Loop through list of allowed Text Notification types
              for (let a = 0; a < allowedEbillSplit.length; a += 1) {
                const ebillType = allowedEbillSplit[a];
                if (ebillType) {
                  const policyTypeSplit = ebillType.split('-');

                  // Is this policy type/subtype allowed to enroll in Text Notification?
                  if ((policyTypeSplit.length > 1 && this.policyAccount.PolicyType === policyTypeSplit[0] && this.policyAccount.PolicySubType === policyTypeSplit[1])
                    || this.policyAccount.GuidewireFlag === 'Y') {
                    // Show Text Notification enrollment link
                    this.showTextEnrollmentLink = true;
                  }
                }
              }
            } else {
              // Unable to find policy types/subtypes or none are listed
              this.showTextEnrollmentLink = null;
            }
          })
          .catch((error) => {
            this.$store.dispatch('app/setErrorMessage', error.toString());
            this.showTextEnrollmentLink = false;
          });

        // Get the policy types/subtypes allowed to enroll in Paperless Policy
        this.$store.dispatch('app/getFeatureFlag', { sTitle: 'PaperlessPolicyNotifyAllowed' })
          .then((allowedPaperless) => {
            if (allowedPaperless && allowedPaperless.length > 0) {
              const allowedPaperlessSplit = allowedPaperless.split(',');

              // Loop through list of allowed Paperless Policy types
              for (let a = 0; a < allowedPaperlessSplit.length; a += 1) {
                if (allowedPaperlessSplit[a]) {
                  const policyTypeSplit = allowedPaperlessSplit[a].split('-');

                  // Is this policy type/subtype allowed to enroll in Paperless Policy?
                  if (policyTypeSplit.length > 1 && this.policyAccount.PolicyType === policyTypeSplit[0] && this.policyAccount.PolicySubType === policyTypeSplit[1]
                    && this.policyAccount.GuidewireFlag !== 'Y'
                    && this.policyAccount.HasGuidewireEquivalent !== 'Y') {
                    // Show Paperless Policy enrollment link
                    this.showPolicyEnrollmentLink = true;
                  }
                }
              }
            } else {
              // Unable to find policy types/subtypes or none are listed
              this.showPolicyEnrollmentLink = null;
            }
          })
          .catch((error) => {
            this.$store.dispatch('app/setErrorMessage', error.toString());
            this.showPolicyEnrollmentLink = false;
          });
      }
    },
  };
</script>
<style lang="scss">

  div .policy-separator:not(:first-child) {
    border-top: solid 1px $tfb-gray;
  }

  @media (min-width: 960px) {
    div .policy-separator:not(:first-child) {
      border-top: none;
      border-left: solid 1px $tfb-gray;
    }
  }
</style>
