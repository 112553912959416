<template>
  <div>
    <div v-if="policyDetails.BillingInformation && policyDetails.BillingInformation !== null && policyDetails.BillingInformation.length > 0">
      <div v-for="billingInfo in policyDetails.BillingInformation"
           v-bind:key="billingInfo.BillAccountNumber"
           class="payment-separator">
        <div class="font-weight-bold">Current Balance Due:<br /></div>
        <div class="text-right text-md-left">{{formatMoney(billingInfo.MinimumAmountDue, 2)}}<br /></div>
        <div v-if="billingInfo.PastAmountDue && billingInfo.PastAmountDue !== null && billingInfo.PastAmountDue !== ''
             && billingInfo.PastAmountDue !== '0000000000000.00' && billingInfo.PastAmountDue !== '0.00' && billingInfo.PastAmountDue !== '0'
             && billingInfo.PastAmountDue !== billingInfo.MinimumAmountDue">
          <div class="text-red font-weight-bold">Past Due Balance:<br /></div>
          <div class="text-right text-md-left">{{formatMoney(billingInfo.PastAmountDue, 2)}}<br /></div>
        </div>
        <div class="font-weight-bold">Remaining Balance Due:<br /></div>
        <div class="text-right text-md-left">{{formatMoney(billingInfo.MaximumAmountDue, 2)}}<br /></div>
        <div v-if="!billingInfo.PastAmountDue || billingInfo.PastAmountDue === null || billingInfo.PastAmountDue === ''
             || billingInfo.PastAmountDue === '0000000000000.00' || billingInfo.PastAmountDue === '0.00' || billingInfo.PastAmountDue === '0'">
          <div class="font-weight-bold">Current Due Date:<br /></div>
          <div class="text-right text-md-left">{{formatDate(billingInfo.CurrentDueDate)}}<br /></div>
        </div>
        <div v-if="billingInfo.PastAmountDue && billingInfo.PastAmountDue !== null && billingInfo.PastAmountDue !== ''
             && billingInfo.PastAmountDue !== '0000000000000.00' && billingInfo.PastAmountDue !== '0.00' && billingInfo.PastAmountDue !== '0'">
          <div class="text-red font-weight-bold">Past Due Date:<br /></div>
          <div class="text-right text-md-left">{{formatDate(billingInfo.CurrentDueDate)}}<br /></div>
        </div>
        <payment-menu :showLink="policyDetails.PolicyPayableFlag === 'Y' && !paymentsDisabled"
                      :accountNumber="billingInfo.InvoiceCloudAccountNumber"
                      :policyNumber="policyDetails.PolicyNumber"
                      :policyType="policyDetails.PolicyType"
                      :isGuidewire="billingInfo.BillingSource === 'Guidewire'"
                      :paymentTracking="trackingId"></payment-menu>
        <span v-if="policyDetails.PolicyPayableFlag === 'Y' && paymentsDisabled">Online Payments Unavailable<br /></span>
      </div>
    </div>
    <div v-else>
      <div class="font-weight-bold">Current Balance Due:<br /></div>
      <div class="text-right text-md-left">{{formatMoney(policyDetails.PolicyMinimumAmountDue, 2)}}<br /></div>
      <div v-if="!policyDetails.BillingStatus || (policyDetails.BillingStatus !== 'BAC-9' && policyDetails.BillingStatus !== 'BAC-13')">
        <div class="font-weight-bold">Current Due Date:<br /></div>
        <div class="text-right text-md-left">{{formatDate(policyDetails.PolicyDueDate)}}<br /></div>
      </div>
      <div v-if="policyDetails.BillingStatus === 'BAC-9' || policyDetails.BillingStatus === 'BAC-13'">
        <div class="text-red font-weight-bold">Past Due Date:<br /></div>
        <div class="text-right text-md-left">{{formatDate(policyDetails.PolicyDueDate)}}<br /></div>
      </div>
      <div class="font-weight-bold">Remaining Balance Due:<br /></div>
      <div class="text-right text-md-left">{{formatMoney(policyDetails.PolicyMaximumAmountDue, 2)}}<br /></div>
      <payment-menu :showLink="policyDetails.PolicyPayableFlag === 'Y' && !paymentsDisabled"
                    :accountNumber="policyDetails.MemberNumber"
                    :policyNumber="policyDetails.PolicyNumber"
                    :policyType="policyDetails.PolicyType"
                    :isGuidewire="policyDetails.GuidewireFlag === 'Y'"
                    :paymentTracking="trackingId"></payment-menu>
      <span v-if="policyDetails.PolicyPayableFlag === 'Y' && paymentsDisabled">Online Payments Unavailable<br /></span>
    </div>
  </div>
</template>
<script>
  import tfbHelperMixin from '@/mixins/helpers';
  import PaymentMenu from '../components/PaymentMenu.vue';

  export default {
    name: 'PolicyPaymentList',
    props: {
      policyDetails: {
        type: Object,
        required: true,
      },
    },
    mixins: [tfbHelperMixin],
    components: {
      PaymentMenu,
    },
    data() {
      return {
        paymentsDisabled: false,
      };
    },
    mounted() {
      // Is this a member logged in or an internal user?
      if (this.isMemberRole) {
        // See if payments are enabled
        this.$store.dispatch('app/doApplicationCheck', { sApplicationName: 'Payments', sTfbId: this.trackingId })
          .then((returnedStatus) => {
            if (returnedStatus !== '') {
              this.paymentsDisabled = true;
            }
          })
          .catch((error) => {
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      } else {
        this.paymentsDisabled = true;
      }
    },
  };
</script>
<style lang="scss">

  div .payment-separator:not(:first-child) {
    border-top: solid 1px $tfb-gray;
  }
</style>
