<template>
  <v-container fluid
               class="tfb-smaller-container tfb-component-container"
               v-if="eligiblePolicies.length > 0">
    <v-row>
      <v-col cols="12">
        <v-container fluid
                     class="drop-shadow background-white px-md-10 pb-5">
          <v-row>
            <v-col cols="12">
              <h2 v-if="textEligible !== null"
                  class="tfb-title-text-small text-uppercase">
                E-bill Paperless Billing and Text Message Notifications
              </h2>
              <h2 v-else
                  class="tfb-title-text-small text-uppercase">
                E-bill Paperless Billing
              </h2>
            </v-col>
          </v-row>
          <v-row v-if="showEnrollmentMessage">
            <v-col cols="12">
              <span class="font-weight-bold text-blue">Why should you consider enrolling in E-bill Paperless Billing?</span>
              <ul>
                <li>Convenient email notifications let you know when it's time to pay</li>
                <li>24/7 access to your account anytime/anywhere</li>
                <li>View your current and past bills online, while still having the option to print when necessary</li>
                <li>Reduce money spent for stamps, envelopes, and checks</li>
                <li>Reduce clutter in your home and in your mailbox</li>
                <li>Conserve Earth's natural resources by reducing paper output</li>
              </ul>
            </v-col>
          </v-row>
          <v-row v-if="!emailVerified && !phoneVerified && textEligible !== null">
            <v-col cols="12"
                   class="text-center">
              To activate E-bill Paperless Billing or Text Message Notifications on eligible policies or Master Accounts, please first
              verify your <a href="#email">email address</a> or <a href="#phone">phone number</a>.
            </v-col>
          </v-row>
          <v-row v-else-if="!emailVerified">
            <v-col cols="12"
                   class="text-center">
              To activate E-bill Paperless Billing on eligible policies or Master Accounts, please first
              <a href="#email">verify your email address</a>.
            </v-col>
          </v-row>
          <v-row v-else-if="!phoneVerified && textEligible !== null && phoneVerificationEnabled === 'Y'">
            <v-col cols="12"
                   class="text-center">
              To activate Text Notifications on eligible policies or Master Accounts, please first
              <a href="#phone">verify at least one phone number</a>.
            </v-col>
          </v-row>
          <v-row v-if="emailVerified">
            <v-col cols="12"
                   class="text-center">
              <strong>E-bill Paperless Billing Notifications will be sent to: </strong>{{emailAddress}}
            </v-col>
          </v-row>
          <ValidationObserver ref="obvEbill"
                              v-if="phoneVerified || emailVerified"
                              v-slot="{ handleSubmit }">
            <v-form ref="ebillForm"
                    @submit.stop.prevent="handleSubmit(changeEbill)">
              <v-row>
                <v-col cols="12"
                       class="body-2"
                       v-if="filteredPhoneNumbers && filteredPhoneNumbers !== null && filteredPhoneNumbers.length > 0">
                  Please select E-bill paperless and text notifications options below. If you select text notifications only, you will continue to receive billing statements by mail.
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-data-table :headers="eBillHeaders"
                                :items="eligiblePolicies"
                                :loading="ebillLoading"
                                :no-results-text="noResultsText"
                                :no-data-text="noResultsText"
                                hide-default-footer
                                disable-sort>
                    <template v-slot:item.PolicyDescription="{ item }">
                      <span v-html="item.PolicyDescription"></span>
                    </template>
                    <template v-slot:item.NewEbill="{ item }">
                      <v-simple-checkbox v-if="emailVerified && item.AllowChange && item.AllowEbill"
                                         v-model="item.NewEbill"
                                         :value="item.OriginalEbill"
                                         :ripple="false"></v-simple-checkbox>
                      <span v-else-if="!item.AllowChange && item.AllowEbill">
                        {{ item.MemberEmailAddress }}
                      </span>
                    </template>
                    <template v-slot:item.NewPhone="{ item }">
                      <td v-if="phoneVerified && item.AllowChange && item.AllowText && filteredPhoneNumbers.length > 0">
                        <select-input label="Phone Number"
                                      :items="filteredPhoneNumbers"
                                      item-text="PhoneNumber"
                                      item-value="MiraId"
                                      v-model="item.NewPhone"
                                      return-object
                                      dense></select-input>
                      </td>
                      <td v-else>
                        {{ item.MemberPhoneNumber | formatPhone }}
                      </td>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row v-if="!hasChanges">
                <v-col cols="12"
                       class="v-messages theme--light error--text">
                  Please make one or more changes to E-bill Paperless Billing and/or Text Message Notification enrollment before submitting.
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12"
                       md="4"
                       sm="6">
                  <v-btn block
                         :disabled="formDisabled"
                         type="submit"
                         class="tfb-button background-red">Submit</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
          <v-overlay absolute
                     :value="saveOverlay">
            <v-progress-circular indeterminate
                                 size="64"></v-progress-circular>
          </v-overlay>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  /* eslint no-unused-vars: 0 */
  import _ from 'lodash';
  import { dispatch, sync } from 'vuex-pathify';
  import { ValidationObserver } from 'vee-validate';
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'EbillChanges',
    props: {
      contactList: {
        type: Array,
        required: true,
      },
      policyList: {
        type: Array,
        required: true,
      },
      abList: {
        type: Array,
        required: true,
      },
    },
    mixins: [tfbHelperMixin],
    components: {
      ValidationObserver,
    },
    data() {
      return {
        showEnrollmentMessage: true,
        saveOverlay: false,
        formDisabled: false,
        eligiblePolicies: [],
        changedPolicies: [],
        ebillLoading: true,
        hasChanges: true,
        noResultsText: null,
        eBillHeaders: [
          { text: 'Policy Type', align: 'start', value: 'PolicyDescription' },
          { text: 'Policy', value: 'PolicyNumber' },
          { text: 'E-bill Paperless', value: 'NewEbill' },
        ],
        emptyPhoneNumber: {
          ContactId: '',
          MiraId: 0,
          PhoneNumber: 'Not Enrolled',
          FullName: 'Not Enrolled',
          TextEnabled: true,
          Verified: true,
        },
      };
    },
    computed: {
      memberId() {
        try {
          return this.$store.getters['member/memberId'];
        } catch (error) {
          return '0';
        }
      },
      memberNumber() {
        try {
          return this.$store.getters['member/memberNumber'];
        } catch (error) {
          return '';
        }
      },
      emailAddress() {
        try {
          return this.$store.getters['member/emailAddress'];
        } catch (error) {
          return '';
        }
      },
      emailVerified() {
        try {
          return this.$store.getters['member/verifiedIndicator'];
        } catch (error) {
          return false;
        }
      },
      phoneVerified() {
        try {
          if (!this.contactList || this.contactList === null || this.contactList.length <= 0) return false;
          if (this.contactList.filter(c => c.Verified === true && c.TextEnabled === true).length > 0) return true;
          return false;
        } catch (error) {
          return false;
        }
      },
      filteredPhoneNumbers() {
        if (!this.contactList || this.contactList === null || this.contactList.length <= 0) return this.contactList;
        const builtPhoneNumbers = this.contactList.filter(c => c.Verified === true && c.TextEnabled === true);

        if (builtPhoneNumbers.length > 0) {
          builtPhoneNumbers.push(this.emptyPhoneNumber);
        }

        return builtPhoneNumbers;
      },
      phoneVerificationEnabled() {
        try {
          return this.$store.getters['app/phoneVerificationEnabled'];
        } catch (error) {
          return 'N';
        }
      },
      contactUpdateEnabled() {
        try {
          return this.$store.getters['app/contactUpdateEnabled'];
        } catch (error) {
          return 'N';
        }
      },
      textEligible: sync('app/textEligible'),
    },
    watch: {
      contactList(val) {
        this.buildEbill();
      },
    },
    mounted() {
      // Check to see if E-bill application is enabled
      this.$store.dispatch('app/doApplicationCheck', { sApplicationName: 'Ebill', sTfbId: this.trackingId })
        .then((returnedStatus) => {
          if (returnedStatus === '') {
            if (this.policyList === null || this.policyList.length <= 0) {
              // Go fetch the policy list again
              this.getAccountSummary();
              this.getAccountBillSummary();
              this.buildEbill();
            } else {
              this.buildEbill();
            }
          } else {
            this.$store.dispatch('app/setErrorMessage', returnedStatus);
            this.formDisabled = true;
            this.ebillLoading = false;
          }

          if (!this.isMemberRole) {
            // Not the member, don't allow changes
            this.formDisabled = true;
          }
        })
        .catch((error) => {
          this.$store.dispatch('app/setErrorMessage', error.toString());
          this.ebillLoading = false;
        });
    },
    methods: {
      getAccountSummary() {
        dispatch('policy/getAccountSummary', { sMemberNumber: this.memberNumber, sTfbId: this.trackingId })
          .then((accountSummaryResponse) => {
            this.policyList = accountSummaryResponse;
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out, log out and go to login
              this.$store.dispatch('app/setIsLoading', true);
              dispatch('member/doLogout')
                .then(() => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$router.push({ name: 'policyholderLogin' });
                })
                .catch((accessError) => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$store.dispatch('app/setErrorMessage', accessError.toString());
                });
            }
            dispatch('app/setErrorMessage', error.toString());
          });
      },
      getAccountBillSummary() {
        dispatch('payments/getAccountBillInformation', { sMemberNumber: this.memberNumber, sTfbId: this.trackingId })
          .then((accountBillSummaryResponse) => {
            this.abList = accountBillSummaryResponse;
          })
          .catch((error) => {
            dispatch('app/setErrorMessage', error.toString());
          });
      },
      policyEbillEligibilityCheck(policy, policyTypeSplit) {
        return policy && policy !== null && policy.EBillFlag !== 'X' && policy.PolicyType === policyTypeSplit[0]
          && policy.PolicySubType === policyTypeSplit[1] && policy.PolicyStatus === 'ACTIVE';
      },
      getCurrentEbillInformation(policyNumber, policyType) {
        return new Promise((resolve) => {
          dispatch('payments/getEbillInformation', {
            sMemberNumber: this.memberNumber, sPolicyNumber: policyNumber, sPolicyType: policyType, sTfbId: this.trackingId,
          })
            .then((ebillResult) => {
              resolve(ebillResult);
            })
            .catch(() => {
              resolve(null);
            });
        });
      },
      getEbillLimits() {
        return new Promise((resolve) => {
          // Get the policy types/subtypes allowed on E-bill
          dispatch('app/getFeatureFlag', { sTitle: 'AllowedEbill' })
            .then((allowedEbill) => {
              resolve(allowedEbill);
            })
            .catch(() => {
              resolve(null);
            });
        });
      },
      getTextLimits() {
        return new Promise((resolve) => {
          // Get the policy types/subtypes allowed on Text
          this.$store.dispatch('app/getFeatureFlag', { sTitle: 'AllowedEbillTextNotify' })
            .then((allowedText) => {
              if (allowedText && allowedText !== null && allowedText.length > 0) {
                dispatch('app/setTextEligible', allowedText);
                resolve(allowedText);
              }
              resolve(null);
            })
            .catch(() => {
              resolve(null);
            });
        });
      },
      buildEbill() {
        this.ebillLoading = true;
        this.eligiblePolicies = [];
        if (this.policyList && this.policyList.length > 0) {
          this.getEbillLimits()
            .then((foundLimits) => {
              // Are there policy types allowed on E-bill?
              if (foundLimits !== null && foundLimits.length > 0) {
                // E-bill enabled; Getting the text limits again because I can't guarantee textEligible will be set at this point
                this.getTextLimits()
                  .then((textLimits) => {
                    // Is the text enrollment column already added?
                    if (this.eBillHeaders.length > 3) {
                      // Remove the last column/row
                      this.eBillHeaders.pop();
                    }

                    if (textLimits !== null && textLimits.length > 0) {
                      this.eBillHeaders.push({ text: 'Text Notifications', value: 'NewPhone' });
                    } else {
                      this.eBillHeaders.push({ text: '', value: 'NewPhone' });
                    }

                    // Get E-bill policies
                    this.buildEbillPolicy(foundLimits, textLimits)
                      .then(() => {
                        // Get E-bill Master Accounts
                        this.buildEbillAccountBill(textLimits)
                          .then(() => {
                            if (this.eligiblePolicies && this.eligiblePolicies.length <= 0) {
                              this.noResultsText = 'No policies currently eligible';
                            }
                            this.ebillLoading = false;
                          });
                      });
                  });
              } else {
                // E-bill not enabled
                this.noResultsText = 'E-bill not available';
                this.ebillLoading = false;
              }
            });
        } else {
          // No policies found
          this.noResultsText = 'No policies available';
          this.ebillLoading = false;
        }
      },
      buildEbillPolicy(allowedEbill, allowedText) {
        return new Promise((resolve) => {
          this.eligiblePolicies = [];
          // Split the string to find the policyType-policySubType strings for E-bill
          const allowedEbillSplit = allowedEbill.split(',');

          // Loop through list of allowed E-bill Policy types
          for (let a = 0; a < allowedEbillSplit.length; a += 1) {
            if (allowedEbillSplit[a]) {
              const ebillPolicyTypeSplit = allowedEbillSplit[a].split('-');

              // Loop through the list of policies to see if any are eligible
              for (let p = 0; p < this.policyList.length; p += 1) {
                const policy = this.policyList[p];

                // Is this an eligible policy?
                if (this.policyEbillEligibilityCheck(policy, ebillPolicyTypeSplit)) {
                  // This is an eligible policy
                  let allowTextEnroll = false;

                  // Is text allowed?
                  if (allowedText && allowedText !== null && allowedText !== '') {
                    // Split the string to find the policyType-policySubType strings for Text
                    const allowedTextSplit = allowedText.split(',');

                    // Loop through list of allowed Text Policy types
                    for (let t = 0; t < allowedTextSplit.length; t += 1) {
                      if (allowedTextSplit[t]) {
                        const textPolicyTypeSplit = allowedTextSplit[t].split('-');
                        if (policy.PolicyType === textPolicyTypeSplit[0] && policy.PolicySubType === textPolicyTypeSplit[1] && policy.PolicyStatus === 'ACTIVE') {
                          allowTextEnroll = true;
                        }
                      }
                    }
                  }

                  // Is this policy already enrolled in E-bill?
                  if (policy.EBillFlag === 'Y') {
                    // There is at least one policy already on E-bill, so don't show the reasons why to enroll
                    this.showEnrollmentMessage = false;

                    // Get the existing E-bill enrollment information
                    this.getCurrentEbillInformation(policy.PolicyNumber, policy.PolicyType)
                      .then((ebillResult) => {
                        if (ebillResult && ebillResult !== null && (ebillResult.ErrorMessage === null || ebillResult.ErrorMessage === '')) {
                          let textEnrollment = null;
                          // Was a MIRA id included?
                          if (ebillResult.MiraId !== null && ebillResult.MiraId !== 0 && ebillResult.MiraId !== '0' && ebillResult.EnrollmentType !== 'EML') {
                            for (let e = 0; e < this.contactList.length; e += 1) {
                              // Is the MIRA id included in the current list of contacts?
                              if (this.contactList[e] && this.contactList[e].MiraId && ebillResult.MiraId && this.contactList[e].MiraId.toString() === ebillResult.MiraId.toString()) {
                                textEnrollment = this.contactList[e];
                              }
                            }
                          }

                          if (this.memberId.toString().trim() === ebillResult.MemberId.toString().trim()) {
                            // Same member that signed up
                            this.eligiblePolicies.push({
                              PolicyNumber: policy.PolicyNumber,
                              PolicyType: policy.PolicyType,
                              PolicyDescription: policy.PolicyDescription,
                              AllowChange: true,
                              AllowEbill: true,
                              AllowText: allowTextEnroll,
                              MemberEmailAddress: this.emailAddress,
                              MemberPhoneNumber: textEnrollment === null ? '' : ebillResult.MemberPhoneNumber,
                              OriginalEbill: (ebillResult.EnrollmentType === 'BOTH' || ebillResult.EnrollmentType === 'EML'),
                              NewEbill: (ebillResult.EnrollmentType === 'BOTH' || ebillResult.EnrollmentType === 'EML'),
                              OriginalPhone: textEnrollment === null ? this.emptyPhoneNumber : textEnrollment,
                              NewPhone: textEnrollment === null ? this.emptyPhoneNumber : textEnrollment,
                              MemberInitials: '',
                            });

                            // Make sure the policy is only in the array once
                            this.eligiblePolicies = _.uniqBy(this.eligiblePolicies, 'PolicyNumber');
                          } else {
                            // The signed up member is different
                            this.eligiblePolicies.push({
                              PolicyNumber: policy.PolicyNumber,
                              PolicyType: policy.PolicyType,
                              PolicyDescription: policy.PolicyDescription,
                              AllowChange: false,
                              AllowEbill: true,
                              AllowText: allowTextEnroll,
                              MemberEmailAddress: ebillResult.MemberEmailAddress,
                              MemberPhoneNumber: textEnrollment === null ? '' : ebillResult.MemberPhoneNumber,
                              OriginalEbill: (ebillResult.EnrollmentType === 'BOTH' || ebillResult.EnrollmentType === 'EML'),
                              NewEbill: (ebillResult.EnrollmentType === 'BOTH' || ebillResult.EnrollmentType === 'EML'),
                              OriginalPhone: textEnrollment === null ? this.emptyPhoneNumber : textEnrollment,
                              NewPhone: textEnrollment === null ? this.emptyPhoneNumber : textEnrollment,
                              MemberInitials: '',
                            });

                            // Make sure the policy is only in the array once
                            this.eligiblePolicies = _.uniqBy(this.eligiblePolicies, 'PolicyNumber');
                          }
                        } else {
                          // Error message or no record returned, so don't allow changes
                          this.eligiblePolicies.push({
                            PolicyNumber: policy.PolicyNumber,
                            PolicyType: policy.PolicyType,
                            PolicyDescription: policy.PolicyDescription,
                            AllowChange: false,
                            AllowEbill: true,
                            AllowText: allowTextEnroll,
                            MemberEmailAddress: 'Unable to locate enrollment',
                            MemberPhoneNumber: 'Unable to locate enrollment',
                            OriginalEbill: false,
                            NewEbill: false,
                            OriginalPhone: this.emptyPhoneNumber,
                            NewPhone: this.emptyPhoneNumber,
                            MemberInitials: '',
                          });

                          // Make sure the policy is only in the array once
                          this.eligiblePolicies = _.uniqBy(this.eligiblePolicies, 'PolicyNumber');
                        }
                      })
                      .catch(() => {
                        // Error getting the current information, so don't allow changes
                        this.eligiblePolicies.push({
                          PolicyNumber: policy.PolicyNumber,
                          PolicyType: policy.PolicyType,
                          PolicyDescription: policy.PolicyDescription,
                          AllowChange: false,
                          AllowEbill: true,
                          AllowText: allowTextEnroll,
                          MemberEmailAddress: 'Error locating enrollment',
                          MemberPhoneNumber: 'Error locating enrollment',
                          OriginalEbill: false,
                          NewEbill: false,
                          OriginalPhone: this.emptyPhoneNumber,
                          NewPhone: this.emptyPhoneNumber,
                          MemberInitials: '',
                        });

                        // Make sure the policy is only in the array once
                        this.eligiblePolicies = _.uniqBy(this.eligiblePolicies, 'PolicyNumber');
                      });
                  } else if (policy.EBillFlag === 'N') {
                    // Not already enrolled; Add the eligible policy to the list
                    this.eligiblePolicies.push({
                      PolicyNumber: policy.PolicyNumber,
                      PolicyType: policy.PolicyType,
                      PolicyDescription: policy.PolicyDescription,
                      AllowChange: true,
                      AllowEbill: true,
                      AllowText: allowTextEnroll,
                      MemberEmailAddress: this.emailAddress,
                      MemberPhoneNumber: '',
                      OriginalEbill: false,
                      NewEbill: false,
                      OriginalPhone: this.emptyPhoneNumber,
                      NewPhone: this.emptyPhoneNumber,
                      MemberInitials: '',
                    });

                    // Make sure the policy is only in the array once
                    this.eligiblePolicies = _.uniqBy(this.eligiblePolicies, 'PolicyNumber');
                  }
                }
              }
            }
          }

          resolve('');
        });
      },
      buildEbillAccountBill(allowedText) {
        return new Promise((resolve) => {
          if (this.abList && this.abList.length > 0) {
            // Loop through the list of AB to see if any are eligible
            for (let p = 0; p < this.abList.length; p += 1) {
              const accountBill = this.abList[p];

              // Is this an eligible policy?
              if (accountBill && accountBill !== null) {
                // This is an eligible policy
                let allowTextEnroll = false;

                // Is text allowed?
                if (allowedText && allowedText !== null && allowedText !== '') {
                  // Split the string to find the policyType-policySubType strings for Text
                  const allowedTextSplit = allowedText.split(',');

                  // Loop through list of allowed Text Policy types
                  for (let t = 0; t < allowedTextSplit.length; t += 1) {
                    if (allowedTextSplit[t]) {
                      const textPolicyTypeSplit = allowedTextSplit[t].split('-');
                      if (textPolicyTypeSplit[0] === 'AB') {
                        allowTextEnroll = true;
                      }
                    }
                  }
                }

                if (accountBill.EbillStatus === 'Y') {
                  // There is at least one policy already on E-bill, so don't show the reasons why to enroll
                  this.showEnrollmentMessage = false;

                  // Get the existing E-bill enrollment information
                  this.getCurrentEbillInformation(accountBill.MasterAccountNumber, 'AB')
                    .then((ebillResult) => {
                      if (ebillResult && ebillResult !== null && ebillResult.ErrorMessage === null) {
                        let textEnrollment = null;
                        if (ebillResult.MiraId !== null && ebillResult.MiraId !== 0 && ebillResult.EnrollmentType !== 'EML') {
                          for (let e = 0; e < this.contactList.length; e += 1) {
                            if (this.contactList[e] && this.contactList[e].MiraId === ebillResult.MiraId) {
                              textEnrollment = this.contactList[e];
                            }
                          }
                        }

                        if (this.memberId !== ebillResult.MemberId) {
                          // The signed up member is different
                          this.eligiblePolicies.push({
                            PolicyNumber: accountBill.MasterAccountNumber,
                            PolicyType: 'AB',
                            PolicyDescription: accountBill.Description,
                            AllowChange: false,
                            AllowEbill: true,
                            AllowText: allowTextEnroll,
                            MemberEmailAddress: ebillResult.MemberEmailAddress,
                            MemberPhoneNumber: ebillResult.MemberPhoneNumber,
                            OriginalEbill: (ebillResult.EnrollmentType === 'BOTH' || ebillResult.EnrollmentType === 'EML'),
                            NewEbill: (ebillResult.EnrollmentType === 'BOTH' || ebillResult.EnrollmentType === 'EML'),
                            OriginalPhone: textEnrollment === null ? this.emptyPhoneNumber : textEnrollment,
                            NewPhone: textEnrollment === null ? this.emptyPhoneNumber : textEnrollment,
                            MemberInitials: '',
                          });

                          // Make sure the policy is only in the array once
                          this.eligiblePolicies = _.uniqBy(this.eligiblePolicies, 'PolicyNumber');
                        } else {
                          // Same member that signed up
                          this.eligiblePolicies.push({
                            PolicyNumber: accountBill.MasterAccountNumber,
                            PolicyType: 'AB',
                            PolicyDescription: accountBill.Description,
                            AllowChange: true,
                            AllowEbill: true,
                            AllowText: allowTextEnroll,
                            MemberEmailAddress: this.emailAddress,
                            MemberPhoneNumber: ebillResult.MemberPhoneNumber,
                            OriginalEbill: (ebillResult.EnrollmentType === 'BOTH' || ebillResult.EnrollmentType === 'EML'),
                            NewEbill: (ebillResult.EnrollmentType === 'BOTH' || ebillResult.EnrollmentType === 'EML'),
                            OriginalPhone: textEnrollment === null ? this.emptyPhoneNumber : textEnrollment,
                            NewPhone: textEnrollment === null ? this.emptyPhoneNumber : textEnrollment,
                            MemberInitials: '',
                          });

                          // Make sure the policy is only in the array once
                          this.eligiblePolicies = _.uniqBy(this.eligiblePolicies, 'PolicyNumber');
                        }
                      } else {
                        // Error getting the current information or no record returned, so don't allow changes
                        this.eligiblePolicies.push({
                          PolicyNumber: accountBill.MasterAccountNumber,
                          PolicyType: 'AB',
                          PolicyDescription: accountBill.Description,
                          AllowChange: false,
                          AllowEbill: true,
                          AllowText: allowTextEnroll,
                          MemberEmailAddress: '',
                          MemberPhoneNumber: '',
                          OriginalEbill: false,
                          NewEbill: false,
                          OriginalPhone: this.emptyPhoneNumber,
                          NewPhone: this.emptyPhoneNumber,
                          MemberInitials: '',
                        });

                        // Make sure the policy is only in the array once
                        this.eligiblePolicies = _.uniqBy(this.eligiblePolicies, 'PolicyNumber');
                      }
                    })
                    .catch(() => {
                      // Error getting the current information, so don't allow changes
                      this.eligiblePolicies.push({
                        PolicyNumber: accountBill.MasterAccountNumber,
                        PolicyType: 'AB',
                        PolicyDescription: accountBill.Description,
                        AllowChange: false,
                        AllowEbill: true,
                        AllowText: allowTextEnroll,
                        MemberEmailAddress: '',
                        MemberPhoneNumber: '',
                        OriginalEbill: false,
                        NewEbill: false,
                        OriginalPhone: this.emptyPhoneNumber,
                        NewPhone: this.emptyPhoneNumber,
                        MemberInitials: '',
                      });

                      // Make sure the policy is only in the array once
                      this.eligiblePolicies = _.uniqBy(this.eligiblePolicies, 'PolicyNumber');
                    });
                } else if (accountBill.EbillStatus === 'N') {
                  // Add the eligible policy to the list
                  this.eligiblePolicies.push({
                    PolicyNumber: accountBill.MasterAccountNumber,
                    PolicyType: 'AB',
                    PolicyDescription: accountBill.Description,
                    AllowChange: true,
                    AllowEbill: true,
                    AllowText: allowTextEnroll,
                    MemberEmailAddress: this.emailAddress,
                    MemberPhoneNumber: '',
                    OriginalEbill: false,
                    NewEbill: false,
                    OriginalPhone: this.emptyPhoneNumber,
                    NewPhone: this.emptyPhoneNumber,
                    MemberInitials: '',
                  });

                  // Make sure the policy is only in the array once
                  this.eligiblePolicies = _.uniqBy(this.eligiblePolicies, 'PolicyNumber');
                }
              }
            }
          }

          resolve('');
        });
      },
      changeEbill() {
        this.hasChanges = false;
        this.saveOverlay = true;
        for (let p = 0; p < this.eligiblePolicies.length; p += 1) {
          const policy = this.eligiblePolicies[p];

          if (policy.NewEbill !== policy.OriginalEbill && policy.NewPhone.MiraId !== policy.OriginalPhone.MiraId) {
            // Both E-bill and Text options have changed
            // Is this policy being enrolled or unenrolled from E-bill?
            if (policy.NewEbill === false) {
              policy.EbillChange = 'Unenroll';
            } else {
              policy.EbillChange = 'Enroll';
            }

            // Is this policy being enrolled, unenrolled, or changed from Texts?
            if (policy.NewPhone.MiraId === 0) {
              policy.PhoneChange = 'Unenroll';
            } else {
              policy.PhoneChange = `Enroll (${policy.NewPhone.PhoneNumber})`;
            }
            this.changedPolicies.push(policy);
            this.hasChanges = true;
          } else if (policy.NewEbill !== policy.OriginalEbill) {
            // E-bill option only has changed; add it to the list
            if (policy.NewEbill === false) {
              policy.EbillChange = 'Unenroll';
            } else {
              policy.EbillChange = 'Enroll';
            }
            policy.PhoneChange = 'No Change';
            this.changedPolicies.push(policy);
            this.hasChanges = true;
          } else if (policy.NewPhone.MiraId !== policy.OriginalPhone.MiraId) {
            // Text option only has changed; add it to the list
            if (policy.NewPhone.MiraId === 0) {
              policy.PhoneChange = 'Unenroll';
            } else {
              policy.PhoneChange = `Enroll (${policy.NewPhone.PhoneNumber})`;
            }
            policy.EbillChange = 'No Change';
            this.changedPolicies.push(policy);
            this.hasChanges = true;
          }
        }

        if (this.hasChanges) {
          this.$store.commit('payments/SET_EBILL_REQUEST', this.changedPolicies);
          this.$router.push({ name: 'policyholderChangeEbill' });
          this.saveOverlay = false;
        } else {
          this.saveOverlay = false;
        }
      },
    },
  };
</script>
<style lang="scss">
</style>
