<template>
  <v-container fluid
               class="tfb-container pb-10">
    <v-row class="my-1">
      <v-col>
        <h1 class="tfb-title-text-medium text-darker-blue text-center">Payment Options</h1>
      </v-col>
    </v-row>
    <v-row v-if="paymentWordingDisplay === 'Y'">
      <v-col>
        <strong>
          Making a payment via your checking or savings account? You can now save your account data in our new Wallet feature!<br /><br />
          When adding your checking or savings account information, click the check box at the bottom, &ldquo;Add to Wallet&rdquo;,
          click &ldquo;SAVE&rdquo; and your account information will be saved for future use! Note: This feature is not available
          when paying via the Guest Payment Site.
        </strong>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="drop-shadow fill-height mx-lg-10 mx-md-6 pa-6 pa-lg-10">
          <v-card-title><h2 class="tfb-title-text-small font-weight-bold text-darker-blue">Pay Online via My TFBI Account</h2></v-card-title>
          <v-card-text>
            Log in to your account and make a one-time payment on your policy via
            your checking or savings account with no added processing fees! You
            can also set-up automatic payments for no added
            service fees!
          </v-card-text>
          <v-card-actions class="tfb-card-footer text-center">
            <v-btn :to="{ name: 'policyholderLogin' }"
                   :disabled="formDisabled"
                   hreflang="en-us"
                   class="tfb-button-min background-red">Log In</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="drop-shadow fill-height mx-lg-10 mx-md-6 pa-6 pa-lg-10">
          <v-card-title><h2 class="tfb-title-text-small font-weight-bold text-darker-blue">Pay Online via Guest Payment Site</h2></v-card-title>
          <v-card-text>
            Use this option to make a one-time credit, debit, or checking/savings account
            payment on your policy without logging in. Processing fees ($3.95) and other
            restrictions will apply.
          </v-card-text>
          <v-card-actions class="tfb-card-footer text-center">
            <v-btn v-if="!guidewirePaymentsEnabled"
                   :href="nonRegisteredFiservPaymentUrl"
                   :disabled="formDisabled"
                   hreflang="en-us"
                   target="_blank"
                   class="tfb-button-min background-red">Guest Payment Site</v-btn>
            <v-btn v-else
                   @click.stop="nonLoggedInPaymentDialog = true"
                   :disabled="formDisabled"
                   hreflang="en-us"
                   target="_blank"
                   class="tfb-button-min background-red">Guest Payment Site</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2 class="tfb-title-text-small tfb-title-text-bold text-darker-blue">
          Other Ways to Pay
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3 class="tfb-title-text-small text-darker-blue">By Mail:</h3>
        <p>
          Check or money-order policy payments should be mailed to our Dallas
          payment center with your billing statement remittance stub:
        </p>
        <blockquote>
          <address id="paymentCenterAddress">
            <span class="font-weight-bold">{{billingAddress.name}}</span><br />
            {{billingAddress.addressLineOne}}<br />
            {{billingAddress.addressLineTwo}}<br />
          </address>
        </blockquote>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3 class="tfb-title-text-small text-darker-blue">By Phone:</h3>
        <p>
          Call
          <a v-bind:title="ivrPhone.display"
             id="ivrPhone"
             v-bind:href="ivrPhone.link">
            {{ivrPhone.display}}
          </a> to make your policy payment 24 hours a day/7 days a week.
          Processing fees ($3.95) will apply.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3 class="tfb-title-text-small text-darker-blue">
          By Automatic Payment:
        </h3>
        <p>
          Save time, postage, and service fees! Schedule automatic recurring payments from
          your checking or savings account. Log in and click on the
          &ldquo;Schedule Recurring Payments&rdquo; link on your Account Summary page next to your
          policy. NO service or processing fees; not available on all policy lines.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3 class="tfb-title-text-small text-darker-blue">In Person:</h3>
        <p>
          Stop by your local
          <router-link :to="{ name: 'countyLookup'}"
                       hreflang="en-us">
            Texas Farm Bureau County Office
          </router-link>
          to make a check or money order payment with no processing fees.
          You may also make an online credit, debit, or checking/savings account payment.
          Processing fees ($3.95) will apply.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="fill-height">
          <v-card-text>
            <faqs faq-keyword="payments"></faqs>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="nonLoggedInPaymentDialog"
              max-width="450">
      <v-card>
        <v-card-title>
          <h3 class="tfb-title-text-small">Please enter your policy number:</h3>
        </v-card-title>
        <ValidationObserver ref="obv" v-slot="{ handleSubmit }">
          <v-form ref="paymentForm"
                  @submit.stop.prevent="handleSubmit(onPaymentSearch)">
            <v-card-text>
              <v-row class="tfb-notification"
                     role="alert"
                     v-show="errorMessage && errorMessage !== null">
                <v-col cols="12"
                       class="tfb-notification-notice">
                  <span v-html="errorMessage"></span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <text-input rules="required|min:6|max:11"
                              label="Policy Number"
                              autocapitalize="characters"
                              minlength="6"
                              maxlength="11"
                              v-model="policyNumber"></text-input>
                </v-col>
              </v-row>
              <v-row v-if="paymentInformation !== null">
                <v-col cols="6"
                       v-if="paymentInformation.GuidewirePaymentInformation !== undefined && paymentInformation.GuidewirePaymentInformation !== null">
                  <label class="font-weight-medium">Current Balance Due:</label> {{formatMoney(paymentInformation.GuidewirePaymentInformation.CurrentBalanceDue, 2)}}<br />
                  <label class="font-weight-medium">Remaining Balance Due:</label> {{formatMoney(paymentInformation.GuidewirePaymentInformation.RemainingBalanceDue, 2)}}<br />
                  <span v-if="paymentInformation.GuidewirePaymentInformation.PastDueBalance && paymentInformation.GuidewirePaymentInformation.PastDueBalance === '0'">
                    <label class="font-weight-medium">Due Date:</label> {{formatDate(paymentInformation.GuidewirePaymentInformation.CurrentDueDate)}}<br />
                  </span>
                  <span v-else>
                    <label class="text-red font-weight-medium">Past Due Date:</label> {{formatDate(paymentInformation.GuidewirePaymentInformation.CurrentDueDate)}}<br />
                  </span>
                  <span v-if="isGuidewireLinkDisabled"
                        class="make-payment-link">
                    Make a Payment Link Currently Unavailable
                  </span>
                  <span v-else>
                    <a :href="nonRegisteredGuidewirePaymentUrl"
                       class="make-payment-link"
                       target="_blank"
                       title="Make a Payment"
                       v-on:click="policyPayment(nonRegisteredGuidewirePaymentUrl)">Make a Payment</a>
                  </span>
                </v-col>
                <v-col cols="6"
                       v-if="paymentInformation.LegacyPaymentInformation !== undefined && paymentInformation.LegacyPaymentInformation !== null">
                  <label class="font-weight-medium">Current Balance Due:</label> {{formatMoney(paymentInformation.LegacyPaymentInformation.CurrentBalanceDue, 2)}}<br />
                  <label class="font-weight-medium">Remaining Balance Due:</label> {{formatMoney(paymentInformation.LegacyPaymentInformation.RemainingBalanceDue, 2)}}<br />
                  <span v-if="paymentInformation.LegacyPaymentInformation.PastDueBalance
                        && (paymentInformation.LegacyPaymentInformation.PastDueBalance === '0' || paymentInformation.LegacyPaymentInformation.PastDueBalance === '0.00')">
                    <label class="font-weight-medium">Due Date:</label> {{formatDate(paymentInformation.LegacyPaymentInformation.CurrentDueDate)}}<br />
                  </span>
                  <span v-else>
                    <label class="text-red font-weight-medium">Past Due Date:</label> {{formatDate(paymentInformation.LegacyPaymentInformation.CurrentDueDate)}}<br />
                  </span>
                  <span v-if="isLegacyLinkDisabled"
                        class="make-payment-link">
                    Make a Payment Link Currently Unavailable
                  </span>
                  <span v-else>
                    <a :href="nonRegisteredFiservPaymentUrl"
                       class="make-payment-link"
                       target="_blank"
                       title="Make a Payment"
                       v-on:click="policyPayment(nonRegisteredFiservPaymentUrl)">Make a Payment</a>
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="tfb-button background-red"
                     @click="onPaymentSearch"
                     :disabled="formDisabled"
                     type="submit">Submit</v-btn>
            </v-card-actions>
          </v-form>
        </ValidationObserver>
        <v-overlay absolute
                   :value="saveOverlay">
          <v-progress-circular indeterminate
                               size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import tfbDataMixin from '@/mixins/tfbData';
  import tfbMetaMixin from '@/mixins/metaInfo';
  import tfbHelperMixin from '@/mixins/helpers';
  import { genericErrorMessage } from '@/mixins/errorMessages';
  import { ValidationObserver } from 'vee-validate';
  import Faqs from '@/views/content/components/FAQs.vue';

  export default {
    name: 'PaymentOptions',
    mixins: [tfbDataMixin, tfbMetaMixin, tfbHelperMixin],
    components: {
      Faqs,
      ValidationObserver,
    },
    data() {
      return {
        formDisabled: false,
        nonLoggedInPaymentDialog: false,
        errorMessage: null,
        saveOverlay: false,
        policyNumber: '',
        paymentInformation: null,
        nonRegisteredGuidewirePaymentUrl: '',
      };
    },
    computed: {
      metaPageTitle() {
        return 'Payment Options';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/payments/options`;
      },
      isLegacyLinkDisabled() {
        return !this.nonRegisteredFiservPaymentUrl
          || this.nonRegisteredFiservPaymentUrl === null
          || this.nonRegisteredFiservPaymentUrl === undefined
          || this.nonRegisteredFiservPaymentUrl === '';
      },
      isGuidewireLinkDisabled() {
        return !this.nonRegisteredGuidewirePaymentUrl
          || this.nonRegisteredGuidewirePaymentUrl === null
          || this.nonRegisteredGuidewirePaymentUrl === undefined
          || this.nonRegisteredGuidewirePaymentUrl === '';
      },
      nonRegisteredFiservPaymentUrl() {
        try {
          return this.$store.getters['app/nonRegisteredFiservPaymentUrl'];
        } catch (error) {
          return '';
        }
      },
      paymentWordingDisplay() {
        try {
          return this.$store.getters['app/paymentWordingDisplay'];
        } catch (error) {
          return 'N';
        }
      },
      guidewirePaymentsEnabled() {
        try {
          const paymentDisplay = this.$store.getters['app/guidewirePaymentsEnabled'];
          return (paymentDisplay && paymentDisplay !== null && paymentDisplay === 'Y');
        } catch (error) {
          return false;
        }
      },
    },
    mounted() {
      this.$store.dispatch('app/clearErrorMessage');
      this.$store.dispatch('app/setIsLoading', true);
      this.$store.dispatch('app/doApplicationCheck', { sApplicationName: 'Payments', sTfbId: this.trackingId })
        .then((returnedStatus) => {
          if (returnedStatus !== '') {
            this.formDisabled = true;
            this.$store.dispatch('app/setErrorMessage', returnedStatus);
          }
          this.$store.dispatch('app/setIsLoading', false);
        })
        .catch(() => {
          this.$store.dispatch('app/setIsLoading', false);
        });
    },
    methods: {
      onLoginSubmit() {
        this.$router.push({ name: 'policyholderLogin' });
      },
      policyPayment(link) {
        this.$store.dispatch('app/logToULS', {
          logLevel: 'Info',
          logMessage: `Going to: ${link}`,
          fileName: 'PaymentOptions.vue',
          methodName: 'policyPayment-Start',
          trackerId: this.paymentTracking,
        });
      },
      onPaymentSearch() {
        this.saveOverlay = true;
        this.formDisabled = true;
        this.errorMessage = null;
        this.paymentInformation = null;

        this.$store.dispatch('payments/systemCheck', {
          sPolicyNumber: this.policyNumber,
          sTfbId: this.trackingId,
        })
          .then((systemCheckResponse) => {
            if (systemCheckResponse.AccountFound) {
              this.paymentInformation = systemCheckResponse;
              if (systemCheckResponse.GuidewirePaymentInformation !== null && systemCheckResponse.LegacyPaymentInformation !== null) {
                // More than one payment option needs to be displayed
                // Get the token value
                this.$store.dispatch('member/createCloudPaymentUrl', {
                  sAccountNumber: systemCheckResponse.GuidewirePaymentInformation.InvoiceCloudAccountNumber,
                  sInvoiceNumber: '',
                  sTfbId: this.trackingId,
                })
                  .then((cloudPaymentReturn) => {
                    if (cloudPaymentReturn && cloudPaymentReturn !== null && cloudPaymentReturn !== undefined && cloudPaymentReturn !== '') {
                      this.nonRegisteredGuidewirePaymentUrl = cloudPaymentReturn;
                    } else {
                      this.errorMessage = 'Error connecting to our payment processor. Please try again later.';
                    }
                  })
                  .catch((error) => {
                    this.errorMessage = error.toString();
                  });
              } else {
                // Only one payment option returned; redirect them to the appropriate payment site
                if (systemCheckResponse.GuidewirePaymentInformation !== null) {
                  // Get the token value
                  this.$store.dispatch('member/createCloudPaymentUrl', {
                    sAccountNumber: systemCheckResponse.GuidewirePaymentInformation.InvoiceCloudAccountNumber,
                    sInvoiceNumber: '',
                    sTfbId: this.trackingId,
                  })
                    .then((cloudPaymentReturn) => {
                      if (cloudPaymentReturn && cloudPaymentReturn !== null && cloudPaymentReturn !== undefined && cloudPaymentReturn !== '') {
                        this.nonRegisteredGuidewirePaymentUrl = cloudPaymentReturn;
                        this.policyPayment(this.nonRegisteredGuidewirePaymentUrl);
                        window.open(this.nonRegisteredGuidewirePaymentUrl);
                      } else {
                        this.errorMessage = 'Error connecting to our payment processor. Please try again later.';
                      }
                    })
                    .catch((error) => {
                      this.errorMessage = error.toString();
                    });
                }
                if (systemCheckResponse.LegacyPaymentInformation !== null) {
                  this.policyPayment(this.nonRegisteredFiservPaymentUrl);
                  window.open(this.nonRegisteredFiservPaymentUrl);
                }
              }
            } else {
              this.policyPayment(this.nonRegisteredFiservPaymentUrl);
              window.open(this.nonRegisteredFiservPaymentUrl);
            }
            this.saveOverlay = false;
            this.formDisabled = false;
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out
              this.$store.commit('quotes/SET_TIMEOUT', true);
              this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
            }
            if (error.toString().indexOf('status code 500') >= 0) {
              // General server error
              this.errorMessage = genericErrorMessage;
            } else {
              this.errorMessage = error.toString();
            }
            this.saveOverlay = false;
            this.formDisabled = false;
          });
      },
      getPaymentUrl() {
        this.$store.dispatch('app/getConfiguration', { sSection: 'ExternalUrl', sTitle: 'NonRegisteredPaymentsEncrypted' })
          .then((urlResponse) => {
            if (urlResponse && urlResponse !== '') {
              this.nonRegisteredFiservPaymentUrl = urlResponse;
            } else {
              this.$store.dispatch('app/setErrorMessage', 'Unable to fetch guest payment site url');
            }
            this.$store.dispatch('app/setIsLoading', false);
          })
          .catch((error) => {
            this.$store.dispatch('app/logToULS', {
              logLevel: 'Error',
              logMessage: error,
              fileName: 'PaymentOptions.vue',
              methodName: 'getPaymentUrl-Catch',
              trackerId: this.trackingId,
            });
            this.$store.dispatch('app/setErrorMessage', 'Unable to fetch guest payment site url');
            this.$store.dispatch('app/setIsLoading', false);
          });
      },
    },
  };
</script>
