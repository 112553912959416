<template>
  <v-container fluid
               class="py-0">
    <!-- Loop through sections -->
    <div v-for="section in policyDetails.Sections"
         v-bind:key="section.id">
      <!-- Is this a non-mortgagee section? -->
      <div v-if="section.Name !== 'Mortgagees' && section.Name !== 'Mortgagees/Loss Payees'"
           class="py-2">
        <!-- Output the section name -->
        <v-row>
          <v-col cols="12"
                 class="pb-1">
            <h4 class="font-weight-bold">{{section.Name}}</h4>
          </v-col>
        </v-row>
        <!-- Does this section have coverages? -->
        <v-container fluid
                     class="py-0"
                     v-if="section.Coverages && section.Coverages.length > 0">
          <!-- Loop through the section coverages -->
          <div v-for="coverage in section.Coverages"
               v-bind:key="coverage.id">
            <!-- Does this section coverage not have a detail section? -->
            <v-row v-if="!coverage.CoverageDetail || coverage.CoverageDetail.length <= 0"
                   class="mt-3">
              <v-col cols="12"
                     md="9"
                     class="py-0">
                <span v-if="coverage.Group != ''">{{coverage.Group}}. </span>{{coverage.Name}}
              </v-col>
              <v-col cols="12"
                     md="3"
                     class="text-right text-md-left py-0">{{coverage.Limit}}</v-col>
              <v-col cols="12"
                     class="d-block d-lg-none"><hr /></v-col>
            </v-row>
            <!-- Does this section coverage have a detail section? -->
            <v-container fluid
                         class="pa-0"
                         v-if="coverage.CoverageDetail && coverage.CoverageDetail.length > 0">
              <!-- Output the detail section name -->
              <v-row>
                <v-col cols="12"
                       class="mt-1 pb-1">
                  {{coverage.Name}}
                </v-col>
              </v-row>
              <!-- Loop through the detail section subcoverages -->
              <v-row v-for="subCoverage in coverage.CoverageDetail"
                     v-bind:key="subCoverage.id"
                     class="px-3">
                <v-col cols="12"
                       md="9"
                       class="py-0">{{subCoverage.Name}}</v-col>
                <v-col cols="12"
                       md="3"
                       class="text-right text-md-left py-0">{{subCoverage.Limit}}</v-col>
                <v-col cols="12"
                       class="d-block d-lg-none"><hr /></v-col>
              </v-row>
            </v-container>
          </div>
        </v-container>
        <v-row v-else>
          <v-col cols="12"
                 class="py-0 px-6">None</v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>
<script>
  export default {
    name: 'GuidewirePolicyCoverages',
    props: {
      policyDetails: {
        type: Object,
        required: true,
      },
    },
  };
</script>
<style lang="scss">

</style>
