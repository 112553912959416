<template>
  <v-container class="tfb-agent-request-box mb-10"
               v-if="agentEmail && agentEmail !== null && agentEmail !== ''">
    <v-row>
      <v-col>
        <h2 class="tfb-title-text-medium text-uppercase text-darker-blue">Contact Form</h2>
      </v-col>
    </v-row>
    <required-fields-row v-show="!submitted"></required-fields-row>
    <ValidationObserver ref="obv" v-slot="{ handleSubmit }">
      <v-form @submit.stop.prevent="handleSubmit(onSubmit)"
              v-show="!submitted">
        <v-row>
          <v-col cols="12">
            <text-input rules="required|name|min:4|max:60"
                        minlength="4"
                        maxlength="60"
                        label="Name"
                        v-model="fullName"
                        autocomplete="shipping given-name"
                        autocapitalize="words"></text-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <text-input rules="required|email|valid_domain|min:3|max:250"
                        autocomplete="home email"
                        minlength="3"
                        maxlength="250"
                        label="Email"
                        v-model="email"
                        type="email"></text-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <text-input rules="phone_number|min:10|max:12"
                        autocomplete="home tel"
                        minlength="10"
                        maxlength="12"
                        label="Phone"
                        v-model="phoneNumber"
                        v-mask="'###-###-####'"
                        type="tel"></text-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <select-input rules="required|max:10"
                          label="Subject"
                          :items="subjectOptions"
                          v-model="subject"></select-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <select-input rules="max:100"
                          label="Subject Detail"
                          :items="billingOptions"
                          v-model="subjectDetail"
                          v-show="subject === 'Billing'"></select-input>
            <select-input rules="max:100"
                          label="Subject Detail"
                          :items="claimsOptions"
                          v-model="subjectDetail"
                          v-show="subject === 'Claims'"></select-input>
            <select-input rules="max:100"
                          label="Subject Detail"
                          :items="paymentsOptions"
                          v-model="subjectDetail"
                          v-show="subject === 'Payments'"></select-input>
            <select-input rules="max:100"
                          label="Subject Detail"
                          :items="policyOptions"
                          v-model="subjectDetail"
                          v-show="subject === 'Policy'"></select-input>
          </v-col>
        </v-row>
        <v-row v-if="subjectDetail === 'Cancellation'">
          <v-col cols="12">
            <v-menu ref="cancelEffectiveDateMenu"
                    v-model="cancelEffectiveDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px">
              <template v-slot:activator="{ on }">
                <ValidationProvider tag="div"
                                    :bails="false"
                                    rules="required"
                                    name="Cancellation Effective Date"
                                    v-slot="{ errors, valid }"
                                    vid="cancelEffective">
                  <v-text-field outlined
                                required
                                readonly
                                hide-details="auto"
                                :error-messages="errors"
                                :success="valid"
                                v-model="cancelEffectiveDate"
                                label="Cancellation Effective Date"
                                v-on="on">
                    <template v-slot:append>
                      <v-icon>mdi-calendar</v-icon>
                      <v-icon>mdi-asterisk</v-icon>
                    </template>
                  </v-text-field>
                </ValidationProvider>
              </template>
              <v-date-picker v-model="cancelEffectiveDate"
                             color="background-blue"
                             no-title
                             :min="minCancellationDate"
                             @input="cancelEffectiveDateMenu = false"></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <text-area-input rules="required|all_allowed_extended|min:4|max:2000"
                             label="Message"
                             autocapitalize="sentences"
                             minlength="4"
                             maxlength="2000"
                             :counter="2000"
                             v-model="message"></text-area-input>
          </v-col>
        </v-row>
        <!-- REMOVED - Mimecast blocks sending the email to the requestor
        <v-row class="my-0">
          <v-col cols="12"
                 class="py-0">
            <v-checkbox v-model="emailCopy"
                        class="my-0"
                        label="Send a copy to your email address?">
            </v-checkbox>
          </v-col>
        </v-row>-->
        <v-row justify="end">
          <v-col cols="12"
                 sm="7">
            <span role="alert"
                  v-show="!hasCaptcha"
                  class="text-darkest-red">Please validate you are not a bot.</span>
            <vue-recaptcha ref="recaptcha"
                           @verify="onVerify"
                           @expired="onExpired"
                           sitekey="6LdNyB8TAAAAAMAzsnuftXFam0KZpOLfO_h8QKya"></vue-recaptcha>
            <br />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn block
                   type="submit"
                   class="tfb-button background-red">
              Send Message
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
    <v-row v-show="submitted">
      <v-col>
        <span class="tfb-title-text-smaller text-darkest-blue">
          Thank you!
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import DOMPurify from 'dompurify';
  import VueRecaptcha from 'vue-recaptcha';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import { mask } from 'vue-the-mask';
  import tfbDataMixin from '@/mixins/tfbData';
  import tfbHelperMixin from '@/mixins/helpers';
  import RequiredFieldsRow from '@/views/layout/components/RequiredFieldsRow.vue';

  export default {
    name: 'AgentContact',
    props: {
      agentEmail: {
        type: String,
        required: true,
      },
    },
    mixins: [tfbDataMixin, tfbHelperMixin],
    directives: { mask },
    components: {
      ValidationObserver,
      ValidationProvider,
      VueRecaptcha,
      RequiredFieldsRow,
    },
    data() {
      return {
        submitted: false,
        hasCaptcha: true,
        captchaText: '',
        fullName: '',
        email: '',
        phoneNumber: '',
        memberNumber: '',
        subject: '',
        subjectDetail: '',
        message: '',
        emailCopy: false,
        cancelEffectiveDateMenu: false,
        cancelEffectiveDate: null,
        subjectOptions: [
          'Quote',
          'Policy',
          'Billing',
          'Payments',
          'Claims',
          'Other',
        ],
        billingOptions: [
          'Address Change',
          'E-bill/Paperless Billing',
          'Payments',
          'Premiums',
          'Recurring Payments',
          'Other',
        ],
        claimsOptions: [
          'Claim Status',
          'Reporting a Claim',
          'Other',
        ],
        paymentsOptions: [
          'E-bill/Paperless Billing',
          'Recurring Payments',
          'Other',
        ],
        policyOptions: [
          'Address Change',
          'Cancellation',
          'Coverage Change',
          'Paperless Notifications',
          'Premiums',
          'Other',
        ],
      };
    },
    watch: {
      subject() {
        this.subjectDetail = null;
        this.cancelEffectiveDate = null;
        this.$refs.obv.validate();
      },
    },
    computed: {
      agreementNumber() {
        try {
          return this.$store.getters['quotes/agreementNumber'];
        } catch (error) {
          return '';
        }
      },
      minCancellationDate() {
        return this.parseDate(new Date().toISOString());
      },
    },
    mounted() {
      // Prefill the contact form if we can
      this.fullName = this.$store.getters['member/memberName'];
      this.email = this.$store.getters['member/emailAddress'];
      this.memberNumber = this.$store.getters['member/memberNumber'];
      this.getWebmasterEmail();
    },
    methods: {
      getWebmasterEmail() {
        // Get the SpeakWithAnAgent email address
        this.$store.dispatch('app/getConfiguration', { sSection: 'Email', sTitle: 'SpeakWithAnAgent' })
          .then((response) => {
            if (response && response !== '') {
              this.webmasterMail = response;
            }
          });
      },
      onCaptchaSubmit() {
        this.$refs.invisibleRecaptcha.execute();
      },
      onVerify(response) {
        this.hasCaptcha = true;
        this.captchaText = response;
      },
      onExpired() {
        this.hasCaptcha = false;
        this.captchaText = '';
        // console.log('Expired');
      },
      onSubmit() {
        this.$store.dispatch('app/clearErrorMessage');
        if (this.captchaText.length > 0) {
          this.$store.dispatch('app/setIsLoading', true);

          // Build the subject text
          let subjectText = 'Email from Agent Page';

          if (this.subject && this.subject !== null && this.subject !== '') {
            subjectText += ` - ${this.subject}`;
          }

          if (this.subjectDetail && this.subjectDetail !== null && this.subjectDetail !== '') {
            subjectText += ` - ${this.subjectDetail}`;
          }

          // Build the message text
          let messageText = `<table><tr><td><b>My comment is regarding:</b></td><td>${subjectText}</td></tr><tr><td colspan='2'></td></tr>`;
          messageText += '<tr><td colspan=\'2\'><b>Contact Information:</b></td></tr><tr><td><b>Name:</b></td>';
          messageText += `<td align='left'>${DOMPurify.sanitize(this.fullName)}</td></tr>`;
          messageText += `<tr><td><b>Email:</b></td><td align='left'>${DOMPurify.sanitize(this.email)}</td></tr>`;
          messageText += `<tr><td><b>Phone Number:</b></td><td align='left'>${DOMPurify.sanitize(this.phoneNumber)}</td></tr>`;
          messageText += `<tr><td><b>Member Number:</b></td><td align='left'>${this.memberNumber}</td></tr>`;

          if (this.agreementNumber && this.agreementNumber !== null && this.agreementNumber !== '') {
            messageText += `<tr><td><b>Quote Number:</b></td><td align='left'>${this.agreementNumber}</td></tr>`;
          }

          if (this.cancelEffectiveDate && this.cancelEffectiveDate !== null && this.cancelEffectiveDate !== '') {
            messageText += `<tr><td><b>Cancellation Effective Date:</b></td><td align='left'>${this.cancelEffectiveDate}</td></tr>`;
          }

          messageText += '<tr><td colspan=\'2\'><b>Message:</b></td></tr>';
          messageText += `<tr><td colspan='2'>${DOMPurify.sanitize(this.message)}</td></tr>`;
          messageText += '<tr><td colspan=\'2\'></td></tr></table>';

          let ccMessage = this.webmasterMail;
          if (this.emailCopy && this.emailCopy === true) {
            ccMessage += `;${DOMPurify.sanitize(this.email)}`;
          }

          // Send the message
          this.$store.dispatch('app/sendMail', {
            sBCC: '',
            sBody: messageText,
            sCC: ccMessage,
            sFrom: DOMPurify.sanitize(this.email),
            sSubject: subjectText,
            sTo: this.agentEmail,
            sToken: this.captchaText,
          })
            .then((response) => {
              if (response && response.data) {
                const emailResponse = response.data;
                if (!emailResponse.ErrorMessage || emailResponse.ErrorMessage === '') {
                  this.submitted = true;
                } else {
                  this.$store.dispatch('app/setErrorMessage', emailResponse.ErrorMessage.toString());
                  this.$store.dispatch('app/logToULS', {
                    logLevel: 'Error',
                    logMessage: emailResponse.ErrorMessage.toString(),
                    fileName: 'AgentDetails.vue',
                    methodName: 'onSubmit-Else',
                    trackerId: this.trackingId,
                  });
                  this.hasCaptcha = false;
                  this.captchaText = '';
                }
              }
              this.$store.dispatch('app/setIsLoading', false);
            })
            .catch((error) => {
              this.$store.dispatch('app/setErrorMessage', error.toString());
              this.$store.dispatch('app/logToULS', {
                logLevel: 'Error',
                logMessage: error,
                fileName: 'AgentDetails.vue',
                methodName: 'onSubmit-Catch',
                trackerId: this.trackingId,
              });
              this.$store.dispatch('app/setIsLoading', false);
            });
        } else {
          this.onExpired();
        }
      },
    },
  };
</script>
<style lang="scss">
  .tfb-agent-request-box {
    background: $white;
    padding: 30px;
    max-width: 600px;
    color: $tfb-darkest-blue;
  }
</style>
