<template>
  <div id="account-summary">
    <v-container fluid
                 class="tfb-container pb-10">
      <h1 class="tfb-title-text-medium text-darker-blue text-uppercase">Account Summary</h1>
      <print-date></print-date>
      <membership-header></membership-header>
      <!-- Account Bill Summary -->
      <div v-if="accountBillList">
        <v-row v-for="accountBill in accountBillList"
               v-bind:key="accountBill.MasterAccountNumber">
          <v-col>
            <v-container fluid
                         class="active-policy drop-shadow">
              <v-row>
                <v-col sm="2"
                       md="1"
                       class="d-none d-sm-flex no-print pl-lg-4">
                  <v-avatar class="policy-icon no-print">
                    <img v-bind:src="accountBill.IconPath"
                         v-bind:alt="accountBill.Description"
                         aria-hidden="true" />
                  </v-avatar>
                </v-col>
                <v-col cols="12"
                       sm="10"
                       md="6">
                  <span class="tfb-title-text-small text-uppercase"
                        v-html="accountBill.Description">
                  </span>&nbsp;&nbsp;
                  <router-link v-if="accountBill.DetailPath"
                               class="tfb-title-text-smaller"
                               :to="{ name: 'policyholderPolicyDetail', params: { policyType: accountBill.DetailPath, policyNumber: accountBill.MasterAccountNumber }}">
                    {{accountBill.MasterAccountNumber}}
                  </router-link>
                  <span v-else
                        class="tfb-title-text-smaller">{{accountBill.MasterAccountNumber}}</span>
                  <br />
                  <div class="text-uppercase py-1">
                    {{accountBill.InsuredFullName}}
                  </div>
                  <account-bill-billing-display :account-bill="accountBill"></account-bill-billing-display>
                </v-col>
                <link-list displayLocation="summary"
                           displayType="accountbill"
                           :policyAccount="accountBill"></link-list>
              </v-row>
              <print-date></print-date>
            </v-container>
          </v-col>
        </v-row>
      </div>
      <!-- Policy Summary -->
      <!-- Loading Policies -->
      <div v-if="policyLoading && !policyList">
        <v-row v-for="i in 3"
               :key="i">
          <v-col>
            <v-skeleton-loader :loading="policyLoading"
                               type="list-item-avatar-three-line"
                               class="mx-auto">
            </v-skeleton-loader>
          </v-col>
        </v-row>
      </div>
      <!-- No Policies Found -->
      <div v-if="!policyLoading && (!policyList || policyList.length === 0)">
        <v-row>
          <v-col>
            No Policies Found
          </v-col>
        </v-row>
        <print-date></print-date>
      </div>
      <!-- Policy Summary Display -->
      <div v-if="policyList">
        <v-row v-for="policy in policyList"
               v-bind:key="policy.PolicyNumber">
          <v-col>
            <v-container fluid
                         v-bind:class="[ policy.PolicyStatus === 'INACTIVE' ? 'inactive-policy' : 'background-white', 'drop-shadow' ]">
              <v-row>
                <v-col sm="2"
                       md="1"
                       class="d-none d-sm-flex no-print pl-lg-4">
                  <v-avatar class="policy-icon no-print">
                    <img v-bind:src="policy.IconPath"
                         v-bind:alt="policy.PolicyDescription"
                         aria-hidden="true" />
                  </v-avatar>
                </v-col>
                <v-col cols="12"
                       sm="10"
                       md="6">
                  <span class="tfb-title-text-small text-uppercase"
                        v-html="policy.PolicyDescription"></span>&nbsp;&nbsp;
                  <router-link v-if="policy.PolicyLinkFlag === 'Y' && policy.DetailPath !== ''"
                               class="tfb-title-text-smaller"
                               :to="{ name: 'policyholderPolicyDetail', params: { policyType: policy.DetailPath, policyNumber: policy.PolicyNumber }}">
                    {{policy.PolicyNumber}}
                  </router-link>
                  <span v-else
                        class="tfb-title-text-smaller">
                    {{policy.PolicyNumber}}
                  </span>
                  <v-icon v-if="policy.PolicyStatus === 'INACTIVE'"
                          @click.stop="inactiveDialog = true">mdi-information-outline</v-icon>
                  <br />
                  <div v-bind:class="[ policy.PolicyStatus === 'INACTIVE' ? 'inactive-policy-name' : '', 'text-uppercase', 'py-1' ]">
                    {{policy.PolicyInsuredName}}
                  </div>
                  <div class="py-1"
                       v-if="policy.PolicyType !== 'PR'">
                    {{formatDate(policy.PolicyEffectiveDate)}} -
                    {{formatDate(policy.PolicyExpirationDate)}}
                  </div>
                  <div class="py-1 text-uppercase"
                       v-if="policy.PropertyLocation && policy.PropertyLocation.trim() !== ''">
                    {{policy.PropertyLocation}}
                  </div>
                  <policy-billing-display :policy="policy"></policy-billing-display>
                </v-col>
                <link-list displayLocation="summary"
                           displayType="policy"
                           :policyAccount="policy"></link-list>
              </v-row>
              <print-date></print-date>
            </v-container>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <v-dialog v-model="inactiveDialog"
              max-width="290">
      <v-card>
        <v-card-title class="headline">Inactive Policy</v-card-title>
        <v-card-text>
          Your policy is INACTIVE.
          Please pay the current balance due amount if one is present.
          Please note that paying the current balance due WILL NOT make
          your policy active. If you would like to reissue your policy,
          please contact your agent.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary"
                 text
                 @click="inactiveDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { dispatch } from 'vuex-pathify';
  import tfbMetaInfo from '@/mixins/metaInfo';
  import tfbHelperMixin from '@/mixins/helpers';
  import MembershipHeader from './components/MembershipHeader.vue';
  import LinkList from './components/LinkList.vue';
  import PrintDate from './components/PrintDate.vue';
  import AccountBillBillingDisplay from './summary/AccountBillBillingDisplay.vue';
  import PolicyBillingDisplay from './summary/PolicyBillingDisplay.vue';

  export default {
    name: 'AccountSummary',
    mixins: [tfbMetaInfo, tfbHelperMixin],
    components: {
      MembershipHeader,
      LinkList,
      PrintDate,
      PolicyBillingDisplay,
      AccountBillBillingDisplay,
    },
    data() {
      return {
        inactiveDialog: false,
        policyLoading: true,
        accountBillList: null,
        policyList: null,
      };
    },
    computed: {
      metaPageTitle() {
        return 'Account Summary | PolicyHolder';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/policyholder/account-summary`;
      },
      memberNumber() {
        try {
          return this.$store.getters['member/memberNumber'];
        } catch (error) {
          return '';
        }
      },
    },
    methods: {
      async getAccountSummary() {
        this.policyLoading = true;
        this.accountBillList = null;
        this.policyList = null;

        if (this.memberNumber !== '') {
          dispatch('policy/getAccountSummary', { sMemberNumber: this.memberNumber, sTfbId: this.trackingId })
            .then((accountSummary) => {
              this.policyList = accountSummary;
              this.policyLoading = false;
              for (let i = 0; i < accountSummary.length; i += 1) {
                const policy = accountSummary[i];

                if (policy.PropertyLocation && (policy.PropertyLocation === ' ' || policy.PropertyLocation === '' || policy.PropertyLocation === null)) {
                  this.getPropertyLocation(policy.PolicyNumber, policy.PolicyType, policy.PolicySubType, policy.PolicySymbol, policy)
                    .then((propertyData) => {
                      policy.PropertyLocation = propertyData;
                    })
                    .catch(() => {
                      // Do nothing
                    });
                }

                if (((policy.PolicyType === 'TC' || policy.PolicyType === 'CF') && policy.PolicySubType === '1')
                  || (policy.PolicyType === 'CP' && policy.PolicySubType === '2')) {
                  this.getPolicyDescription(policy.PolicyNumber, policy.PolicyType, policy)
                    .then((descriptionData) => {
                      policy.PolicyDescription = descriptionData;
                    })
                    .catch(() => {
                      // Do nothing
                    });
                }
              }
            })
            .catch((error) => {
              if (error.toString().indexOf('status code 401') >= 0) {
                // Session has timed out, log out and go to login
                this.$store.dispatch('app/setIsLoading', true);
                dispatch('member/doLogout')
                  .then(() => {
                    this.$store.dispatch('app/setIsLoading', false);
                    this.$router.push({ name: 'policyholderLogin' });
                  })
                  .catch((accessError) => {
                    this.$store.dispatch('app/setIsLoading', false);
                    this.$store.dispatch('app/setErrorMessage', accessError.toString());
                  });
              }
              dispatch('app/setErrorMessage', error.toString());
              this.policyLoading = false;
            });

          dispatch('payments/getAccountBillInformation', { sMemberNumber: this.memberNumber, sTfbId: this.trackingId })
            .then((accountBillSummary) => {
              this.accountBillList = accountBillSummary;
            })
            .catch((error) => {
              dispatch('app/setErrorMessage', error.toString());
            });
        }
      },
      getPolicyDescription(policyNumber, policyType, policyDetail) {
        return new Promise((resolve, reject) => {
          dispatch('policy/getMajescoPolicyInformation', {
            sPolicyDetail: policyDetail, sPolicyNumber: policyNumber, sPolicyType: policyType, sTfbId: this.trackingId,
          })
            .then((majescoData) => {
              if (majescoData.PolicyName && majescoData.PolicyName !== '') {
                resolve(majescoData.PolicyName);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      getPropertyLocation(policyNumber, policyType, policySubType, policySymbol, policyDetail) {
        const dataRequest = {
          sPolicyDetail: policyDetail,
          sPolicyNumber: policyNumber,
          sPolicyType: policyType,
          sPolicySubType: policySubType,
          sPolicySymbol: policySymbol,
          sTfbId: this.trackingId,
        };

        return new Promise((resolve) => {
          if (policyType === 'AA' || policyType === 'AT') {
            dispatch('policy/getTAAPolicyInformation', dataRequest)
              .then((taaPHData) => {
                if (taaPHData) {
                  resolve(taaPHData.PropertyLocation);
                } else {
                  resolve('');
                }
              })
              .catch(() => {
                resolve('');
              });
          } else if (policyType === 'HT' || policyType === 'TT') {
            dispatch('policy/getHOLPolicyInformation', dataRequest)
              .then((holPHData) => {
                if (holPHData) {
                  resolve(holPHData.PropertyLocation);
                } else {
                  resolve('');
                }
              })
              .catch(() => {
                resolve('');
              });
          } else if (policyType === 'SF' || policyType === 'PF') {
            if (policySubType === '1') {
              dispatch('policy/getSFPolicyInformation', dataRequest)
                .then((sfData) => {
                  if (sfData) {
                    resolve(sfData.PropertyLocation);
                  } else {
                    resolve('');
                  }
                })
                .catch(() => {
                  resolve('');
                });
            }
          } else if (((policyType === 'TC' || policyType === 'CF') && policySubType === '1')
            || (policyType === 'CP' && policySubType === '2')) {
            dispatch('policy/getMajescoPolicyInformation', dataRequest)
              .then((majescoData) => {
                if (majescoData.Locations && majescoData.Locations.length > 0) {
                  resolve(majescoData.Locations[0].LocationAddress);
                } else {
                  resolve('');
                }
              })
              .catch(() => {
                resolve('');
              });
          }
        });
      },
    },
    watch: {
      memberNumber: 'getAccountSummary',
    },
    mounted() {
      dispatch('app/clearErrorMessage');
      dispatch('app/setIsLoading', true);
      dispatch('app/doApplicationCheck', { sApplicationName: 'Policyholder', sTfbId: this.trackingId })
        .then((returnedStatus) => {
          if (returnedStatus === '') {
            const includedToken = this.$route.query.access_token;
            dispatch('member/validateLogin', { sToken: includedToken, sTfbId: this.trackingId })
              .then((loginValid) => {
                if (loginValid) {
                    if (this.doStatusCheck()) {
                        this.getAccountSummary();
                    }
                  dispatch('app/setIsLoading', false);
                } else {
                  dispatch('member/doLogout')
                    .then(() => {
                      this.$router.push({ name: 'policyholderLogin' });
                    })
                    .catch((accessError) => {
                      dispatch('app/setErrorMessage', accessError.toString());
                      dispatch('app/setIsLoading', false);
                    });
                }
              })
              .catch((error) => {
                dispatch('app/setErrorMessage', error.toString());
                dispatch('app/setIsLoading', false);
              });
          } else {
            dispatch('app/setErrorMessage', returnedStatus);
            dispatch('app/setIsLoading', false);
          }
        })
        .catch((error) => {
          dispatch('app/setErrorMessage', error.toString());
          dispatch('app/setIsLoading', false);
        });
    },
  };
</script>
<style lang="scss">

  .inactive-policy {
    background-color: $tfb-lighter-gray;
  }

  .active-policy {
    background-color: $white;
  }

  .inactive-policy-name {
    color: $tfb-darker-red;
  }

  .policy-icon {
    background-color: $tfb-blue !important;
    border: 4px solid $tfb-blue !important;
    height: 25px !important;
    min-width: 25px !important;
    width: 25px !important;
  }

  div .policy-separator:not(:first-child) {
    border-top: solid 1px $tfb-gray;
  }

  @media (min-width: 960px) {
    div .policy-separator:not(:first-child) {
      border-top: none;
      border-left: solid 1px $tfb-gray;
    }
  }

  @media (min-width: 600px) {
    .policy-icon {
      height: 75px !important;
      min-width: 75px !important;
      width: 75px !important;
      margin-left: auto !important;
      margin-right: auto !important;
    }
    .policy-icon img {
      padding: 10px;
    }
  }
</style>
