<template>
  <div id="policyholder-login"
       class="background-darker-blue tfbins-agent-login"
       v-if="isValidUser">
    <v-container class="tfbins-agent-login-box">
      <h1 class="tfb-title-text-medium text-uppercase">Agent Access</h1>
      <ValidationObserver ref="obv" v-slot="{ handleSubmit }">
        <v-form @submit.stop.prevent="handleSubmit(logIn)">
          <v-row>
            <v-col cols="12">
              <keep-alive>
                <ValidationProvider tag="div"
                                    :bails="false"
                                    rules="required|numeric"
                                    name="Member County"
                                    v-slot="{ errors, valid, required }">
                  <v-select outlined
                            hide-details="auto"
                            :error-messages="errors"
                            :success="valid"
                            label="Member County"
                            :items="countyList"
                            item-text="`${data.item._countyId} - ${data.item._countyName}`"
                            item-value="_countyId"
                            v-model="countyCode">
                    <template v-slot:append>
                      <v-icon>mdi-asterisk</v-icon>
                      <v-icon>mdi-menu-down</v-icon>
                    </template>
                    <template slot="selection" slot-scope="data">
                      {{data.item._countyId}} - {{data.item._countyName}}
                    </template>
                    <template slot="item" slot-scope="data">
                      {{data.item._countyId}} - {{data.item._countyName}}
                    </template>
                  </v-select>
                </ValidationProvider>
              </keep-alive>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <text-input rules="required|alpha_num|min:4|max:11"
                          minlength="4"
                          maxlength="11"
                          autocapitalize="characters"
                          label="Member Number"
                          v-model="memberNumber"></text-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn block
                     type="submit"
                     class="tfb-button background-red">Go to Policyholder Info</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </ValidationObserver>
    </v-container>
  </div>
</template>
<script>
  /* eslint no-underscore-dangle: 0 */
  import { dispatch } from 'vuex-pathify';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import tfbMetaMixin from '@/mixins/metaInfo';
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'PolicyHolderAgentAccess',
    mixins: [tfbMetaMixin, tfbHelperMixin],
    components: {
      ValidationObserver,
      ValidationProvider,
    },
    data() {
      return {
        countyList: [],
        memberNumber: '',
        countyCode: '',
        isValidUser: false,
      };
    },
    computed: {
      metaPageTitle() {
        return 'Agent Access | PolicyHolder';
      },
      includedUser() {
        try {
          return this.$route.query.u;
        } catch (error) {
          return null;
        }
      },
    },
    watch: {
      memberNumber(val) {
        this.memberNumber = val.toUpperCase();
      },
    },
    async mounted() {
      dispatch('member/doLogout');

      // Make sure this is a valid user (very loose security, but it's something)
      // Check for a QueryString value (ShareNet access method)
      // AgentLogin.aspx?i=6447f61ab55478af664240046c9d204509cfdc72&u=UTABC01
      const includedToken = this.$route.query.i;

      // Check for member number and county code (CRM access method)
      // AgentLogin.aspx?i=6447f61ab55478af664240046c9d204509cfdc72&m=9CDL&c=155
      const includedMember = this.$route.query.m;
      const includedCounty = this.$route.query.c;

      if (includedToken && includedToken !== '') {
        // Build the date value
        const seedDate = new Date().getFullYear() + new Date().getDate() + (new Date().getMonth() + 1);

        // Set the text to encrypt
        const seedText = 'TxfB';
        let seedKey = '';

        for (let i = 0; i < seedText.length; i += 1) {
          seedKey += (seedText.charCodeAt(i)).toString(16);
        }

        if (seedKey.substr(0, 2) === includedToken.substr(10, 2)) {
          if (seedKey.substr(2, 2) === includedToken.substr(12, 2)) {
            if (seedKey.substr(4, 2) === includedToken.substr(16, 2)) {
              if (seedKey.substr(6, 2) === includedToken.substr(18, 2)) {
                if (seedDate.toString() === includedToken.substr(28, 4)) {
                  this.isValidUser = true;
                }
              }
            }
          }
        }

        if (this.isValidUser) {
          // Get county list
          this.getCountyList();

          if (includedMember && includedMember !== null && includedMember !== '') {
            this.memberNumber = includedMember.toUpperCase();
          }

          if (includedCounty && includedCounty !== null && includedCounty !== '') {
            this.countyCode = includedCounty.toUpperCase();
          }

          if (this.memberNumber !== null && this.countyCode !== null && this.memberNumber !== '' && this.countyCode !== '') {
            this.logIn();
          }
        } else {
          // Invalid token, no access
          this.$router.push({ name: 'home' });
        }
      } else {
        // No token, no access
        this.$router.push({ name: 'home' });
      }
      this.$store.dispatch('app/setIsLoading', false);
    },
    methods: {
      logIn() {
        this.$store.dispatch('app/setIsLoading', true);
        this.$store.dispatch('app/clearErrorMessage');
        this.$store.dispatch('member/doAgentLogin', {
          sMemberNumber: this.memberNumber.toUpperCase(),
          sCountyCode: this.countyCode,
          sUser: this.includedUser,
          sTfbId: this.trackingId,
        })
          .then((response) => {
            if (response !== '') {
              this.$store.dispatch('app/setErrorMessage', response);
              this.$store.dispatch('app/setIsLoading', false);
            } else {
              // Go to Account Summary
              this.$router.push({ name: 'policyholderAccountSummary' });
            }
          })
          .catch((error) => {
            this.$store.dispatch('app/setErrorMessage', error);
            this.$store.dispatch('app/setIsLoading', false);
            this.$store.dispatch('app/logToULS', {
              logLevel: 'ERROR',
              logMessage: error,
              fileName: 'AgentAccess.vue',
              methodName: 'logIn-Catch',
              trackerId: this.trackingId,
            });
          });
      },
      getCountyList() {
        dispatch('agentcounty/getCountyList')
          .then((response) => {
            if (response && response.data && response.data._counties) {
              this.countyList = response.data._counties;
            }
          })
          .catch((error) => {
            this.$store.dispatch('app/setErrorMessage', error);
          });
      },
    },
    created() {
      dispatch('app/setIsLoading', true);
      dispatch('app/clearErrorMessage');
    },
  };
</script>
<style lang="scss">

  .tfbins-agent-login {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .tfbins-agent-login-box {
    background: $white;
    padding: 20px 50px;
    max-width: 500px;
    color: $tfb-darkest-blue;
  }
</style>
