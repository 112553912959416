/* eslint dot-notation: 0 */
/* eslint no-undef: 0 */
/* eslint func-names: 0 */
/* eslint wrap-iife: 0 */

import Vue from 'vue';
import { make } from 'vuex-pathify';
import axios from 'axios';
import helpers from '../../mixins/helpers';
import { genericErrorMessage } from '../../mixins/errorMessages';

const documentServiceUrl = `${process.env.VUE_APP_WEB_URI}/services/TFBIC.Services.RESTTCI.Search`;

const tokenName = process.env.VUE_APP_WEB_ENV !== 'PROD' ? `tfb_${process.env.VUE_APP_WEB_ENV}_token` : 'tfb_token';

const config = {
  crossdomain: true,
  withCredentials: true,
  headers: { 'Access-Control-Allow-Origin': '*', Accept: 'application/json; odata=verbose', 'Content-Type': 'application/json; odata=verbose' },
};

 function isSafari() {
   return /constructor/i.test(window.HTMLElement)
     || (function (p) { return p.toString() === '[object SafariRemoteNotification]'; })(!window['safari']
       || (typeof safari !== 'undefined' && window['safari'].pushNotification));
 }

// Groups/sorts policy documents
function groupPolicyDocuments(policyDocuments) {
  let groupDate = '';
  let groupVersion = '';
  let documentGroup = {};
  let dateGroupDocuments = [];
  let versionGroupDocuments = [];
  const policyDocumentReturn = [];
  let duplicateNameIndicator = false;
  let singlePrintDocumentIndicator = false;

  for (let d = 0; d < policyDocuments.length; d += 1) {
    const document = policyDocuments[d];
    if (document) {
      if (groupDate === '') {
        groupDate = document.Date;
      }
      if (groupVersion === '') {
        groupVersion = document.VersionId;
      }

      // Does a document with this name already exist in this version group?
      for (let v = 0; v < versionGroupDocuments.length; v += 1) {
        const versionDoc = versionGroupDocuments[v];
        if (versionDoc.FormDescription === document.FormDescription) {
          // Since only one of a type of document should be in each version group, we
          //  flag documents with the same name as a document in the current group
          //  to indicate that a new version group should be started
          duplicateNameIndicator = true;
        }
      }

      // Does this document start a new version group?
      if (document.VersionId !== groupVersion || duplicateNameIndicator) {
        // Does the existing group have only one item?
        if (versionGroupDocuments.length === 1) {
          // Is this just the Complaint document?
          if (helpers.methods.containsString(versionGroupDocuments[0].FormDescription, 'complaint')) {
            singlePrintDocumentIndicator = true;
          }
        }
        // Does the existing group have only two items?
        if (versionGroupDocuments.length === 2) {
          // Is this just the two Complaint documents?
          if (helpers.methods.containsString(versionGroupDocuments[0].FormDescription, 'complaint')
            && helpers.methods.containsString(versionGroupDocuments[1].FormDescription, 'complaint')) {
            singlePrintDocumentIndicator = true;
          }
        }

        if (!singlePrintDocumentIndicator) {
          // Sort the documents to move "Notice of Material Change" to top of the list
          // eslint-disable-next-line no-loop-func, prefer-arrow-callback
          versionGroupDocuments.forEach(function (item, i) {
            if (item.FormDescription.toUpperCase().trim() === 'NOTICE OF MATERIAL CHANGE') {
              versionGroupDocuments.splice(i, 1);
              versionGroupDocuments.unshift(item);
            }
          });
          // Add the existing version group to the document group
          dateGroupDocuments.push(versionGroupDocuments);
        }

        // Set the version of the new version group
        groupVersion = document.VersionId;
        // Clear out the grouped documents
        versionGroupDocuments = [];
        // Reset the duplicate name indicator
        duplicateNameIndicator = false;
        // Reset the single print document indicator
        singlePrintDocumentIndicator = false;
      }

      // Does this document start a new date group?
      if (document.Date !== groupDate) {
        // Does the existing group have only one item?
        if (dateGroupDocuments.length === 1) {
          // Is this just the Complaint document?
          if (helpers.methods.containsString(dateGroupDocuments[0].FormDescription, 'complaint')) {
            singlePrintDocumentIndicator = true;
          }
        }
        // Does the existing group have only two items?
        if (versionGroupDocuments.length === 2) {
          // Is this just the two Complaint documents?
          if (helpers.methods.containsString(versionGroupDocuments[0].FormDescription, 'complaint')
            && helpers.methods.containsString(versionGroupDocuments[1].FormDescription, 'complaint')) {
            singlePrintDocumentIndicator = true;
          }
        }

        if (!singlePrintDocumentIndicator && dateGroupDocuments.length > 0) {
          // Add the existing grouped documents to a document group with the date
          documentGroup = { Date: groupDate, Documents: dateGroupDocuments };
          // Add the document group to the return
          policyDocumentReturn.push(documentGroup);
        }

        // Set the date of the new date group
        groupDate = document.Date;
        // Clear out the grouped documents
        dateGroupDocuments = [];
        // Reset the single print document indicator
        singlePrintDocumentIndicator = false;
      }

      // Add the document to the document group
      versionGroupDocuments.push(document);
    }
  }

  // Add the last version group to the document group
  dateGroupDocuments.push(versionGroupDocuments);

  // Add the last grouped documents to a document group with the date
  documentGroup = { Date: groupDate, Documents: dateGroupDocuments };

  // Add the last/remaining document group to the return
  policyDocumentReturn.push(documentGroup);

  return policyDocumentReturn;
}

// state
const state = {
};

// getters
const getters = {
  ...make.getters(state),
};

// mutations
const mutations = {
  ...make.mutations(state),
};

// actions
const actions = {
  getAutoIdCard(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${documentServiceUrl}/tci/getautoidcard/${sRequest.sPolicyNumber}/${sRequest.sPolicyType}/${sRequest.sExpirationDate}?access_token=${ssoToken}`, config)
        .then((documentResults) => {
          // Was something returned? Was data returned? Were pages returned? Were more than zero pages returned?
          if (documentResults && documentResults.data && documentResults.data.pages && documentResults.data.pages !== null && documentResults.data.pages.length > 0) {
            // Build and display the PDF from the returned binary string
            // showPDF(documentResults.data.pages[0].content, `${sRequest.sExpirationDate}-${sRequest.sPolicyNumber}-AutoIdCard.pdf`, context, sRequest.sTfbId);

            // Resolve the promise, returning the document data
            resolve(documentResults.data.pages[0].content);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'documents.js', methodName: 'getAutoIdCard', trackerId: sRequest.sTfbId,
          }, { root: true });
          if (error && ((error.status && error.status === 404) || (error.toString().indexOf('status code 404') >= 0 || error.toString().indexOf('status code 401') >= 0))) {
            // No document found
            reject(new Error(`No ID card is currently available for ${sRequest.sPolicyNumber}. Please try again later.`));
          } else {
            // Some other reason
            reject(error);
          }
        });
    });
  },
  getRoadsideAssistanceCard(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${documentServiceUrl}/tci/RoadsideAssistanceCard/${sRequest.sPolicyNumber}/${sRequest.sPolicyType}/${sRequest.sExpirationDate}?access_token=${ssoToken}`, config)
        .then((documentResults) => {
          // Was something returned? Was data returned? Were pages returned? Were more than zero pages returned?
          if (documentResults && documentResults.data && documentResults.data.pages && documentResults.data.pages !== null && documentResults.data.pages.length > 0) {
            // Build and display the PDF from the returned binary string
            // showPDF(documentResults.data.pages[0].content, `${sRequest.sExpirationDate}-${sRequest.sPolicyNumber}-RoadsideAssistanceCard.pdf`, context, sRequest.sTfbId);

            // Resolve the promise, returning the document data
            resolve(documentResults.data.pages[0].content);
          } else {
            reject(new Error(`No Roadside Assistance card is currently available for ${sRequest.sPolicyNumber}. Please try again later.`));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'documents.js', methodName: 'getRoadsideAssistanceCard', trackerId: sRequest.sTfbId,
          }, { root: true });
          if (error && ((error.status && error.status === 404) || (error.toString().indexOf('status code 404') >= 0 || error.toString().indexOf('status code 401') >= 0))) {
            // No document found
            reject(new Error(`No Roadside Assistance card is currently available for ${sRequest.sPolicyNumber}. Please try again later.`));
          } else {
            // Some other reason
            reject(error);
          }
        });
    });
  },
  getBillingDocumentList(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.post(`${documentServiceUrl}/document/list/${sRequest.sPolicyNumber}?access_token=${ssoToken}`,
        {
          PolicyNumber: sRequest.sPolicyNumber,
          MemberNumber: sRequest.sMemberNumber,
          PolicyType: (sRequest.sPolicyType === 'PF' ? 'SF' : sRequest.sPolicyType),
          PolicySubType: sRequest.sPolicySubType,
          LocationType: sRequest.sLocationType,
        },
        config)
        .then((documentResults) => {
          if (documentResults && documentResults.data) {
            resolve(documentResults.data);
          }

          reject(new Error());
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'documents.js', methodName: 'getBillingDocumentList', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getCurrentBillingDocument(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.post(`${documentServiceUrl}/document/current/${sRequest.sPolicyNumber}?access_token=${ssoToken}`,
        {
          PolicyNumber: sRequest.sPolicyNumber,
          MemberNumber: sRequest.sMemberNumber,
          PolicyType: (sRequest.sPolicyType === 'PF' ? 'SF' : sRequest.sPolicyType),
          PolicySubType: sRequest.sPolicySubType,
          LocationType: 'ACCT-CUR',
        },
        config)
        .then((documentResults) => {
          // Was something returned? Was data returned? Were pages returned? Were more than zero pages returned?
          if (documentResults && documentResults.data && documentResults.data.pages && documentResults.data.pages !== null && documentResults.data.pages.length > 0) {
            // Build and display the PDF from the returned binary string
            // const documentDate = helpers.methods.parseDate(new Date().toISOString());
            // showPDF(documentResults.data.pages[0].content, `${documentDate}-${sRequest.sPolicyNumber}-Billing.pdf`, context, sRequest.sTfbId);

            // Resolve the promise, returning the document data
            resolve(documentResults.data.pages[0].content);
          } else {
            reject(new Error(`Current billing document for policy ${sRequest.sPolicyNumber} is unavailable. Please try again later.`));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'documents.js', methodName: 'getCurrentBillingDocument', trackerId: sRequest.sTfbId,
          }, { root: true });
          if (error && ((error.status && error.status === 404) || (error.toString().indexOf('status code 404') >= 0 || error.toString().indexOf('status code 401') >= 0))) {
            // No document found
            reject(new Error(`Current billing document for policy ${sRequest.sPolicyNumber} is unavailable. Please try again later.`));
          } else {
            // Some other reason
            reject(error);
          }
        });
    });
  },
  getSpecificIdBillingDocument(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.post(`${documentServiceUrl}/document/byid/${sRequest.sPolicyNumber}?access_token=${ssoToken}`,
        {
          PolicyNumber: sRequest.sPolicyNumber,
          PolicyType: (sRequest.sPolicyType === 'PF' ? 'SF' : sRequest.sPolicyType),
          PolicySubType: sRequest.sPolicySubType,
          DocumentId: sRequest.sDocumentId,
        },
        config)
        .then((documentResults) => {
          // Was something returned? Was data returned? Were pages returned? Were more than zero pages returned?
          if (documentResults && documentResults.data && documentResults.data.pages && documentResults.data.pages !== null && documentResults.data.pages.length > 0) {
            // Build and display the PDF from the returned binary string
            // showPDF(documentResults.data.pages[0].content, `${sRequest.sVersionId}-${sRequest.sPolicyNumber}-${sRequest.sFormDescription}.pdf`, context, sRequest.sTfbId);

            // Resolve the promise, returning the document data
            resolve(documentResults.data.pages[0].content);
          } else {
            reject(new Error(`Requested billing document for policy ${sRequest.sPolicyNumber} is unavailable. Please try again later.`));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'documents.js', methodName: 'getSpecificIdBillingDocument', trackerId: sRequest.sTfbId,
          }, { root: true });
          if (error && ((error.status && error.status === 404) || (error.toString().indexOf('status code 404') >= 0 || error.toString().indexOf('status code 401') >= 0))) {
            // No document found
            reject(new Error(`Requested billing document for policy ${sRequest.sPolicyNumber} is unavailable. Please try again later.`));
          } else {
            // Some other reason
            reject(error);
          }
        });
    });
  },
  getPolicyDocumentList(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.post(`${documentServiceUrl}/document/policy/list/${sRequest.sPolicyNumber}?access_token=${ssoToken}`,
        {
          PolicyNumber: sRequest.sPolicyNumber,
          PolicyType: (sRequest.sPolicyType === 'PF' ? 'SF' : sRequest.sPolicyType),
          PolicySubType: sRequest.sPolicySubType,
          LocationType: sRequest.sLocationType,
        },
        config)
        .then((documentResults) => {
          if (documentResults && documentResults.data) {
            resolve(groupPolicyDocuments(documentResults.data));
          }

          reject(new Error());
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'documents.js', methodName: 'getPolicyDocumentList', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getSpecificIdPolicyDocument(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.post(`${documentServiceUrl}/document/policy/byid/${sRequest.sPolicyNumber}?access_token=${ssoToken}`,
        {
          PolicyNumber: sRequest.sPolicyNumber,
          PolicyType: (sRequest.sPolicyType === 'PF' ? 'SF' : sRequest.sPolicyType),
          PolicySubType: sRequest.sPolicySubType,
          DocumentId: sRequest.sDocumentId,
        },
        config)
        .then((documentResults) => {
          // Was something returned? Was data returned? Were pages returned? Were more than zero pages returned?
          if (documentResults && documentResults.data && documentResults.data.pages && documentResults.data.pages !== null && documentResults.data.pages.length > 0) {
            // Build and display the PDF from the returned binary string
            // showPDF(documentResults.data.pages[0].content, `${sRequest.sPolicyNumber}-${sRequest.sFormDescription}.pdf`, context, sRequest.sTfbId);

            // Resolve the promise, returning the document data
            resolve(documentResults.data.pages[0].content);
          } else {
            reject(new Error(`Requested policy document for policy ${sRequest.sPolicyNumber} is unavailable. Please try again later.`));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'documents.js', methodName: 'getSpecificIdPolicyDocument', trackerId: sRequest.sTfbId,
          }, { root: true });
          if (error && ((error.status && error.status === 404) || (error.toString().indexOf('status code 404') >= 0 || error.toString().indexOf('status code 401') >= 0))) {
            // No document found
            reject(new Error(`Requested policy document for policy ${sRequest.sPolicyNumber} is unavailable. Please try again later.`));
          } else {
            // Some other reason
            reject(error);
          }
        });
    });
  },
  getCurrentPolicyDocument(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.post(`${documentServiceUrl}/document/policy/current/${sRequest.sPolicyNumber}?access_token=${ssoToken}`,
        {
          PolicyNumber: sRequest.sPolicyNumber,
          PolicyType: (sRequest.sPolicyType === 'PF' ? 'SF' : sRequest.sPolicyType),
          PolicySubType: sRequest.sPolicySubType,
          LocationType: 'POL-CURR',
        },
        config)
        .then((documentResults) => {
          // Was something returned? Was data returned? Were pages returned? Were more than zero pages returned?
          if (documentResults && documentResults.data && documentResults.data.pages && documentResults.data.pages !== null && documentResults.data.pages.length > 0) {
            // Build and display the PDF from the returned binary string
            // const documentDate = helpers.methods.parseDate(new Date().toISOString());
            // showPDF(documentResults.data.pages[0].content, `${documentDate}-${sRequest.sPolicyNumber}-Policy.pdf`, context, sRequest.sTfbId);

            // Resolve the promise, returning the document data
            resolve(documentResults.data.pages[0].content);
          } else {
            reject(new Error(`Current policy document for policy ${sRequest.sPolicyNumber} is unavailable. Please try again later.`));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'documents.js', methodName: 'getCurrentPolicyDocument', trackerId: sRequest.sTfbId,
          }, { root: true });
          if (error && ((error.status && error.status === 404) || (error.toString().indexOf('status code 404') >= 0 || error.toString().indexOf('status code 401') >= 0))) {
            // No document found
            reject(new Error(`Current policy document for policy ${sRequest.sPolicyNumber} is unavailable. Please try again later.`));
          } else {
            // Some other reason
            reject(error);
          }
        });
    });
  },
  showPDF(context, sRequest) {
    return new Promise((resolve, reject) => {
      try {
        const isSafariBrowser = isSafari();
        const byteCharacters = atob(sRequest.inputPDFArray);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i += 1) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const file = new Blob([byteArray], { type: 'application/pdf;base64' });
        const pdfUrl = (window.URL || window.webkitURL).createObjectURL(file);

        // Based on the browser, determine how we open the document
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(file, sRequest.fileName);
        } else if (isSafariBrowser) {
          // If browser is Safari, use a Reader to display the PDF in a newly opened window
           const pdfWindow = window.open('');
           const reader = new FileReader();
           reader.onloadend = function () {
            pdfWindow.location.href = reader.result;
           };
          reader.readAsDataURL(file);
          // Is this user blocking pop-ups?
          if (!pdfWindow || pdfWindow === null || typeof (pdfWindow) === 'undefined') {
            // Tell the user to disable their pop-up blocker
            context.dispatch('app/setErrorMessage', 'Pop-ups are currently blocked for this site. Please disable your pop-up blocker and click the link again.', { root: true });
            reject(new Error('Pop-ups are currently blocked for this site. Please disable your pop-up blocker and click the link again.'));
          }

          // let popUp = null;
          // const objectUrl = window.URL.createObjectURL(file);
          // popUp = document.createElement('a');
          // popUp.href = objectUrl;
          // popUp.download = sRequest.fileName;
          // document.body.appendChild(popUp);
          // popUp.click();
          // document.body.removeChild(popUp);
          // // Is this user blocking pop-ups?
          // if (!popUp || popUp === null || typeof (popUp) === 'undefined') {
          //  // Tell the user to disable their pop-up blocker
          //  context.dispatch('app/setErrorMessage', 'Pop-ups are currently blocked for this site. Please disable your pop-up blocker and click the link again.', { root: true });
          //  reject(new Error('Pop-ups are currently blocked for this site. Please disable your pop-up blocker and click the link again.'));
          // }

          // const a = document.createElement('a');
          // a.style.display = 'none';
          // document.body.appendChild(a);
          // a.href = window.URL.createObjectURL(file);
          // a.setAttribute('download', fileName);
          // a.click();
          // window.URL.revokeObjectURL(a.href);
          // document.body.removeChild(a);
        } else {
          // If browser is not Safari, open a new window (works in chrome)
          window.open(pdfUrl, '_blank');
        }

        resolve(true);
      } catch (e) {
        context.dispatch('app/logToULS', {
          logLevel: 'Error', logMessage: e, fileName: 'documents.js', methodName: 'showPDF', trackerId: sRequest.tfbId,
        }, { root: true });
        context.dispatch('app/setErrorMessage', 'Error loading document. Please try again.', { root: true });
        reject(new Error('Error loading document. Please try again.'));
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
