<template>
  <div>
    <v-row no-gutters
           v-if="policy.BillingInformation && policy.BillingInformation !== null && policy.BillingInformation.length > 0">
      <v-col cols="12"
             :sm="12 / policy.BillingInformation.length"
             v-for="billingInfo in policy.BillingInformation"
             v-bind:key="billingInfo.BillAccountNumber">
        <div v-if="policy.PolicyStatus === 'ACTIVE'">
          <label class="font-weight-medium">Current Balance Due:</label> {{formatMoney(billingInfo.MinimumAmountDue, 2)}}<br />
          <span v-if="billingInfo.PastAmountDue && billingInfo.PastAmountDue !== null && billingInfo.PastAmountDue !== ''
                && billingInfo.PastAmountDue !== '0000000000000.00' && billingInfo.PastAmountDue !== '0.00' && billingInfo.PastAmountDue !== '0'
                && billingInfo.PastAmountDue !== billingInfo.MinimumAmountDue">
            <label class="text-red font-weight-medium">Past Due Balance:</label> {{formatMoney(billingInfo.PastAmountDue, 2)}}<br />
          </span>
          <label class="font-weight-medium">Remaining Balance Due:</label> {{formatMoney(billingInfo.MaximumAmountDue, 2)}}<br />
          <span v-if="!billingInfo.PastAmountDue || billingInfo.PastAmountDue === null || billingInfo.PastAmountDue === ''
                || billingInfo.PastAmountDue === '0000000000000.00' || billingInfo.PastAmountDue === '0.00' || billingInfo.PastAmountDue === '0'">
            <label class="font-weight-medium">Due Date:</label> {{formatDate(billingInfo.CurrentDueDate)}}<br />
          </span>
          <span v-if="billingInfo.PastAmountDue && billingInfo.PastAmountDue !== null && billingInfo.PastAmountDue !== ''
                && billingInfo.PastAmountDue !== '0000000000000.00' && billingInfo.PastAmountDue !== '0.00' && billingInfo.PastAmountDue !== '0'">
            <label class="text-red font-weight-medium">Past Due Date:</label> {{formatDate(billingInfo.CurrentDueDate)}}<br />
          </span>
        </div>
        <div v-else>
          <span v-if="billingInfo.MinimumAmountDue > 0">
            <label class="font-weight-medium">Current Balance Due:</label> {{formatMoney(billingInfo.MinimumAmountDue, 2)}}<br />
          </span>
          <span v-if="billingInfo.MaximumAmountDue > 0">
            <label class="font-weight-medium">Remaining Balance Due:</label> {{formatMoney(billingInfo.MaximumAmountDue, 2)}}<br />
          </span>
          <span v-if="(billingInfo.MinimumAmountDue > 0 || billingInfo.MaximumAmountDue > 0)
                    && (!billingInfo.BillingStatus || (billingInfo.BillingStatus !== 'BAC-9' && billingInfo.BillingStatus !== 'BAC-13'))">
            <label class="font-weight-medium">Due Date:</label> {{formatDate(billingInfo.CurrentDueDate)}}<br />
          </span>
          <span v-if="(billingInfo.MinimumAmountDue > 0 || billingInfo.MaximumAmountDue > 0)
                    && (billingInfo.BillingStatus && (billingInfo.BillingStatus === 'BAC-9' || billingInfo.BillingStatus === 'BAC-13'))">
            <label class="text-red font-weight-medium">Past Due Date:</label> {{formatDate(billingInfo.CurrentDueDate)}}<br />
          </span>
        </div>
        <payment-menu :showLink="!paymentsDisabled && (policy.PolicyStatus === 'ACTIVE' || billingInfo.MinimumAmountDue > 0) && policy.PolicyPayableFlag === 'Y'"
                      :accountNumber="billingInfo.InvoiceCloudAccountNumber"
                      :policyNumber="policy.PolicyNumber"
                      :policyType="policy.PolicyType"
                      :isGuidewire="billingInfo.BillingSource === 'Guidewire'"
                      :paymentTracking="trackingId"></payment-menu>
        <span v-if="paymentsDisabled && (policy.PolicyStatus === 'ACTIVE' || billingInfo.MinimumAmountDue > 0) && policy.PolicyPayableFlag === 'Y'">Online Payments Unavailable<br /></span>
      </v-col>
    </v-row>
    <div v-else>
      <div v-if="policy.PolicyStatus === 'ACTIVE'">
        <label class="font-weight-medium">Current Balance Due:</label> {{formatMoney(policy.PolicyMinimumAmountDue, 2)}}<br />
        <label class="font-weight-medium">Remaining Balance Due:</label> {{formatMoney(policy.PolicyMaximumAmountDue, 2)}}<br />
        <span v-if="!policy.BillingStatus || (policy.BillingStatus !== 'BAC-9' && policy.BillingStatus !== 'BAC-13')">
          <label class="font-weight-medium">Due Date:</label> {{formatDate(policy.PolicyDueDate)}}<br />
        </span>
        <span v-if="policy.BillingStatus === 'BAC-9' || policy.BillingStatus === 'BAC-13'">
          <label class="text-red font-weight-medium">Past Due Date:</label> {{formatDate(policy.PolicyDueDate)}}<br />
        </span>
      </div>
      <div v-else>
        <span v-if="policy.PolicyMinimumAmountDue > 0">
          <label class="font-weight-medium">Current Balance Due:</label> {{formatMoney(policy.PolicyMinimumAmountDue, 2)}}<br />
        </span>
        <span v-if="policy.PolicyMaximumAmountDue > 0">
          <label class="font-weight-medium">Remaining Balance Due:</label> {{formatMoney(policy.PolicyMaximumAmountDue, 2)}}<br />
        </span>
        <span v-if="(policy.PolicyMinimumAmountDue > 0 || policy.PolicyMaximumAmountDue > 0)
                    && (!policy.BillingStatus || (policy.BillingStatus !== 'BAC-9' && policy.BillingStatus !== 'BAC-13'))">
          <label class="font-weight-medium">Due Date:</label> {{formatDate(policy.PolicyDueDate)}}<br />
        </span>
        <span v-if="(policy.PolicyMinimumAmountDue > 0 || policy.PolicyMaximumAmountDue > 0)
                    && (policy.BillingStatus && (policy.BillingStatus === 'BAC-9' || policy.BillingStatus === 'BAC-13'))">
          <label class="text-red font-weight-medium">Past Due Date:</label> {{formatDate(policy.PolicyDueDate)}}<br />
        </span>
      </div>
      <div class="py-1" v-if="policy.PolicyPremiumFinanceFlag === 'Y' && policy.PolicyType !== 'PR'">
        <label class="font-weight-medium">Contract Number:</label>
        <router-link :to="{ name: 'policyholderPolicyDetail',
                                 params: { policyType: 'premium-finance', policyNumber: policy.ContractNumber }}">
          {{policy.ContractNumber}}
        </router-link><br />
      </div>
      <a href=""
         v-on:click.prevent="premiumFinancePayment(policy.ContractNumber, policy.PolicyMinimumAmountDue);"
         v-if="policy.PolicyPremiumFinanceFlag === 'Y' && !paymentsDisabled">
        Pay Premium Contract<br />
      </a>
      <span v-if="policy.PolicyPremiumFinanceFlag === 'Y' && paymentsDisabled">Online Payments Unavailable<br /></span>
        <payment-menu :showLink="(policy.PolicyStatus === 'ACTIVE' || policy.PolicyMinimumAmountDue > 0) && policy.PolicyPayableFlag === 'Y' && !paymentsDisabled"
                      :accountNumber="policy.MemberNumber"
                      :policyNumber="policy.PolicyNumber"
                      :policyType="policy.PolicyType"
                      :isGuidewire="policy.GuidewireFlag === 'Y'"
                      :paymentTracking="trackingId"></payment-menu>
        <span v-if="(policy.PolicyStatus === 'ACTIVE' || policy.PolicyMinimumAmountDue > 0) && policy.PolicyPayableFlag === 'Y' && paymentsDisabled">Online Payments Unavailable<br /></span>
    </div>
  </div>
</template>
<script>
  import tfbHelperMixin from '@/mixins/helpers';
  import { insuredAccessOnlyErrorMessage } from '@/mixins/errorMessages';
  import PaymentMenu from '../components/PaymentMenu.vue';

  export default {
    name: 'SummaryPolicyBillingDisplay',
    props: {
      policy: {
        type: Object,
        required: true,
      },
    },
    mixins: [tfbHelperMixin],
    components: {
      PaymentMenu,
    },
    data() {
      return {
        paymentsDisabled: false,
      };
    },
    mounted() {
      // Is this a member logged in or an internal user?
      if (this.isMemberRole) {
        // See if payments are enabled
        this.$store.dispatch('app/doApplicationCheck', { sApplicationName: 'Payments', sTfbId: this.trackingId })
          .then((returnedStatus) => {
            if (returnedStatus !== '') {
              this.paymentsDisabled = true;
            }
          })
          .catch((error) => {
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      } else {
        this.paymentsDisabled = true;
      }
    },
    methods: {
      premiumFinancePayment(contractNumber, minimumAmountDue) {
        if (this.isMemberRole) {
          this.$store.dispatch('app/logToULS', {
            logLevel: 'Info',
            logMessage: `${contractNumber}-${minimumAmountDue}`,
            fileName: 'PolicyBillingDisplay.vue',
            methodName: 'premiumFinancePayment-Start',
            trackerId: this.trackingId,
          });
          this.$store.dispatch('app/setIsLoading', true);
          this.$store.commit('payments/SET_PF_PAYMENT_REQUEST', {
            ContractNumber: contractNumber,
            MinimumAmountDue: minimumAmountDue,
          });
          this.$router.push({ name: 'policyholderPFPayment' });
        } else {
          this.$store.dispatch('app/setErrorMessage', insuredAccessOnlyErrorMessage);
        }
      },
    },
  };
</script>
<style lang="scss">

</style>
