<template>
  <v-container fluid
               class="py-0">
    <div v-if="policyDetails.Endorsements && policyDetails.Endorsements.length > 0">
      <v-row v-for="endorsement in policyDetails.Endorsements"
             v-bind:key="endorsement.Id">
        <v-col cols="3"
               class="py-0 d-none d-sm-block">{{endorsement.Number}}</v-col>
        <v-col cols="9"
               class="py-0">{{endorsement.Name}}</v-col>
        <v-col cols="12"
               class="d-block d-lg-none"><hr /></v-col>
      </v-row>
    </div>
    <v-row v-else>
      <v-col cols="12"
             class="py-0">None</v-col>
    </v-row>
  </v-container>
</template>
<script>
  export default {
    name: 'GuidewireEndorsements',
    props: {
      policyDetails: {
        type: Object,
        required: true,
      },
    },
  };
</script>
<style lang="scss">

</style>
