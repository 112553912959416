<template>
  <!-- Policy Documents -->
  <v-expansion-panels accordion
                      flat
                      v-model="openPanel"
                      class="tfb-section my-3">
    <v-expansion-panel>
      <v-expansion-panel-header class="px-0">
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="12"
                 md="5">
            <template v-slot:actions>
              <v-icon>$expand</v-icon>
            </template>
            <h3 class="text-blue font-weight-bold">Policy Documents</h3>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="background-lightest-gray py-4">
        <v-container>
          <v-row>
            <v-col>
              <h4>Policy Documents</h4>
            </v-col>
          </v-row>
          <v-row v-if="futureEffectiveDate && futureEffectiveDate !== '' && upcomingStaticDocumentList.length > 0"
                 class="pt-0">
            <v-col class="pt-0">
              <h5>Current Policy Term</h5>
            </v-col>
          </v-row>
          <v-skeleton-loader class="mx-auto"
                             max-width="300"
                             type="list-item@3"
                             tile
                             :loading="currentStaticDocumentLoading">
            <v-row>
              <v-col cols="12"
                     v-if="currentStaticDocumentList.length > 0"
                     class="pt-0">
                <v-list dense
                        flat
                        color="#ededed"
                        class="px-2 pt-0">
                  <v-list-item dense
                               class="tfb-policy-document"
                               v-for="document in currentStaticDocumentList"
                               :key="document.ID">
                    <v-list-item-content class="py-1">
                      <a :href="document.Url"
                         hreflang="en-us"
                         target="_blank"
                         :aria-label="document.Title">{{document.Title}}</a>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col v-else
                     class="pt-0">
                No general policy documents available
              </v-col>
            </v-row>
          </v-skeleton-loader>
          <v-row v-if="futureEffectiveDate && futureEffectiveDate !== '' && showUpcomingStaticDocumentChange && upcomingStaticDocumentList.length > 0"
                 class="pt-0">
            <v-col class="pt-0">
              <h5>Upcoming Policy Term</h5>
            </v-col>
          </v-row>
          <v-skeleton-loader class="mx-auto"
                             max-width="300"
                             type="list-item@3"
                             tile
                             v-if="futureEffectiveDate && futureEffectiveDate !== ''"
                             :loading="upcomingStaticDocumentLoading">
            <v-row>
              <v-col cols="12"
                     v-if="upcomingStaticDocumentList.length > 0"
                     class="pt-0">
                <v-list dense
                        flat
                        color="#ededed"
                        class="px-2 pt-0">
                  <v-list-item dense
                               class="tfb-policy-document"
                               v-for="document in upcomingStaticDocumentList"
                               :key="document.ID">
                    <v-list-item-content class="py-1">
                      <a :href="document.Url"
                         hreflang="en-us"
                         target="_blank"
                         :aria-label="document.Title">{{document.Title}}</a>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-skeleton-loader>
          <v-row>
            <v-col>
              <h4>Other Documents</h4>
            </v-col>
          </v-row>
          <v-skeleton-loader class="mx-auto"
                             max-width="300"
                             type="list-item@3"
                             tile
                             :loading="documentLoading">
            <v-row v-if="documentList && documentList.length > 0">
              <v-col cols="12"
                     class="pt-0">
                <v-list dense
                        flat
                        color="#ededed"
                        v-for="documentGroup in documentList"
                        :key="documentGroup.Date"
                        class="px-2 pt-0">
                  <h5>Issued: {{ documentGroup.Date }}</h5>
                  <v-list-item dense
                               class="tfb-policy-document">
                    <v-list-item-content class="py-1">
                      <v-list dense
                              flat
                              color="#ededed"
                              v-for="versionGroup in documentGroup.Documents"
                              v-bind:key="versionGroup.id"
                              class="px-2 pt-0">
                        <v-list-item dense
                                     class="tfb-policy-document"
                                     v-for="document in versionGroup"
                                     :key="document.DocumentId">
                          <v-list-item-content class="py-1">
                            <a href=""
                               v-on:click.prevent="getPolicyDocument(policyNumber, policyType, policySubType,
                               document.DocumentId, document.FormDescription)"
                               hreflang="en-us"
                               target="_blank"
                               :aria-label="document.LabelDescription">{{document.LabelDescription}}</a>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12"
                     class="pt-0">No policy documents currently available</v-col>
            </v-row>
          </v-skeleton-loader>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
  import { dispatch } from 'vuex-pathify';
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'PolicyDocumentList',
    props: {
      policyNumber: {
        type: String,
        required: true,
      },
      policyType: {
        type: String,
        required: true,
      },
      policySubType: {
        type: String,
        required: true,
      },
      policySymbol: {
        type: String,
        required: true,
      },
      effectiveDate: {
        type: String,
        required: true,
      },
      expirationDate: {
        type: String,
        required: true,
      },
      futureEffectiveDate: {
        type: String,
        required: false,
      },
      futureExpirationDate: {
        type: String,
        required: false,
      },
      openPanel: {
        type: Number,
        required: true,
        default() {
          return 99;
        },
      },
    },
    mixins: [tfbHelperMixin],
    data() {
      return {
        documentLoading: true,
        currentStaticDocumentLoading: true,
        currentStaticDocumentList: [],
        upcomingStaticDocumentLoading: true,
        upcomingStaticDocumentList: [],
        documentList: [],
      };
    },
    computed: {
      showUpcomingStaticDocumentChange() {
        let displayIndicator = true;
        if (this.upcomingStaticDocumentList.length > 0 && this.currentStaticDocumentList.length > 0) {
          if (this.upcomingStaticDocumentList[0].Url === this.currentStaticDocumentList[0].Url) {
            displayIndicator = false;
          }
        }

        return displayIndicator;
      },
    },
    methods: {
      async getStaticPolicyDocuments() {
        dispatch('app/getStaticDocuments', {
          sLineOfBusiness: this.policySymbol, sEffectiveDate: this.effectiveDate, sExpirationDate: this.expirationDate, sTfbId: this.trackingId,
        })
          .then((staticDocumentResponse) => {
            this.currentStaticDocumentList = staticDocumentResponse;
            this.currentStaticDocumentLoading = false;
          })
          .catch(() => {
            this.currentStaticDocumentLoading = false;
          });

        if (this.futureEffectiveDate && this.futureEffectiveDate !== '') {
          dispatch('app/getStaticDocuments', {
            sLineOfBusiness: this.policySymbol, sEffectiveDate: this.futureEffectiveDate, sExpirationDate: this.futureExpirationDate, sTfbId: this.trackingId,
          })
            .then((upcomingStaticDocumentResponse) => {
              this.upcomingStaticDocumentList = upcomingStaticDocumentResponse;
              this.upcomingStaticDocumentLoading = false;
            })
            .catch(() => {
              this.upcomingStaticDocumentLoading = false;
            });
        } else {
          this.upcomingStaticDocumentLoading = false;
        }
      },
      async getPolicyDocuments() {
        dispatch('documents/getPolicyDocumentList', {
          sPolicyNumber: this.policyNumber, sPolicyType: this.policyType, sPolicySubType: this.policySubType, sLocationType: 'POL-HIST', sTfbId: this.trackingId,
        })
          .then((documentResponse) => {
            this.documentList = documentResponse;
            this.documentLoading = false;
          })
          .catch(() => {
            this.documentLoading = false;
          });
      },
      getPolicyDocument(policyNumber, policyType, policySubType, documentId, formDescription) {
        this.$store.dispatch('app/setIsLoading', true);
        dispatch('documents/getSpecificIdPolicyDocument', {
          sPolicyNumber: policyNumber, sPolicyType: policyType, sPolicySubType: policySubType, sDocumentId: documentId, sFormDescription: formDescription, sTfbId: this.trackingId,
        })
          .then((documentPages) => {
            this.$store.dispatch('app/setIsLoading', false);
            if (documentPages && documentPages !== null) {
              this.$store.dispatch('app/setIsDocumentLoading', true);
              dispatch('documents/showPDF', {
                inputPDFArray: documentPages, fileName: `${policyNumber}-${formDescription}.pdf`, tfbId: this.trackingId,
              })
                .then(() => {
                  // Do nothing
                })
                .catch((error) => {
                  this.$store.dispatch('app/setIsDocumentLoading', false);
                  this.$store.dispatch('app/setErrorMessage', error.toString());
                });
            }
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out, log out and go to login
              this.$store.dispatch('app/setIsLoading', true);
              dispatch('member/doLogout')
                .then(() => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$router.push({ name: 'policyholderLogin' });
                })
                .catch((accessError) => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$store.dispatch('app/setErrorMessage', accessError.toString());
                });
            } else if (error.toString().indexOf('status code 404') >= 0) {
              // Document was not found
              this.$store.dispatch('app/setIsLoading', false);
              this.$store.dispatch('app/setErrorMessage', 'Unable to load document. Please try again.');
            } else {
              this.$store.dispatch('app/setIsLoading', false);
              this.$store.dispatch('app/setErrorMessage', error.toString());
            }
          });
      },
    },
    mounted() {
      this.getStaticPolicyDocuments();
      this.getPolicyDocuments();
    },
  };
</script>
<style lang="scss">
  .tfb-policy-document.v-list-item--dense, .v-list--dense .tfb-policy-document.v-list-item {
    min-height: 22px;
  }
</style>
