import Vue from 'vue';
import axios from 'axios';
import { format } from 'date-fns';
import helpers from '../../mixins/helpers';
import { genericErrorMessage } from '../../mixins/errorMessages';

const paymentLookupServiceUrl = `${process.env.VUE_APP_WEB_URI}/services/TFBIC.Services.RESTPayments.Lookup`;
const paymentModifyServiceUrl = `${process.env.VUE_APP_WEB_URI}/services/TFBIC.Services.RESTPayments.Modify`;

const tokenName = process.env.VUE_APP_WEB_ENV !== 'PROD' ? `tfb_${process.env.VUE_APP_WEB_ENV}_token` : 'tfb_token';

const config = {
  crossdomain: true,
  withCredentials: true,
  headers: { 'Access-Control-Allow-Origin': '*', Accept: 'application/json; odata=verbose', 'Content-Type': 'application/json; odata=verbose' },
};

/**
 * accountBillSummaryTransform
 * @param {object} accountBillData JSON object of Account Bill data
 * @returns {object} JSON object of formatted policy data
 */
function accountBillSummaryTransform(accountBillSummary, context, tfbId) {
  try {
    const abFiltered = [];
    // console.log( accountBillSummary );
    if (accountBillSummary) {
      // Filter out the duplicate master account numbers
      for (let i = 0, l = accountBillSummary.length; i < l; i += 1) {
        let unique = true;
        for (let j = 0, k = abFiltered.length; j < k; j += 1) {
          if (accountBillSummary[i].MasterAccountNumber === abFiltered[j].MasterAccountNumber) {
            unique = false;
          }
        }
        if (unique) {
          abFiltered.push(accountBillSummary[i]);
        }
      }

      // Loop through the filtered master account numbers to build/format the details
      for (let a = 0; a < abFiltered.length; a += 1) {
        const accountBill = abFiltered[a];
        accountBill.Description = 'Master Account';
        // accountBill.DetailPath = 'account-bill';
        accountBill.DetailPath = '';
        accountBill.PolicyType = 'AB';
        accountBill.PolicySubType = '0';
        accountBill.IconPath = `${process.env.VUE_APP_ROOT_URI}/publishingimages/policy-icons/misc.png`;
        accountBill.CurrentAmountDue = accountBill.CurrentAmountDue.toString();
        accountBill.RemainingAmountDue = accountBill.RemainingAmountDue.toString();

        if (accountBill.PaymentRestriction === '0' || accountBill.PaymentRestriction === 0) {
          accountBill.PaymentRestriction = 'NoRestriction';
        } else {
          accountBill.PaymentRestriction = 'NoPaymentAllowed';
        }

        if (accountBill.RecurringPaymentStatus === 'Y') {
          accountBill.CurrentRecurring = 'CHANGE';
        } else {
          accountBill.CurrentRecurring = 'SIGNUP';
        }
      }
    }

    return abFiltered;
  } catch (error) {
    context.dispatch('app/logToULS', {
      logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'payments.js', methodName: 'accountBillSummaryTransform', trackerId: tfbId,
    }, { root: true });
    return accountBillSummary;
  }
}

function assurancePayTransform(assurancePayData, context, tfbId) {
  try {
    const returnAssurancePay = assurancePayData;

    if (assurancePayData && !assurancePayData.ErrorMessage) {
      returnAssurancePay.MaskedAccountNumber = helpers.methods.maskString(assurancePayData.AccountNumber, '5', 'X');
      returnAssurancePay.MaskedRoutingNumber = helpers.methods.maskString(assurancePayData.RoutingNumber, '5', 'X');
      returnAssurancePay.PaymentDate = Number(assurancePayData.PaymentDate);

      if (assurancePayData.AccountType === 'CH') {
        returnAssurancePay.FullAccountType = 'Checking';
      } else if (assurancePayData.AccountType === 'SA') {
        returnAssurancePay.FullAccountType = 'Savings';
      } else {
        returnAssurancePay.FullAccountType = assurancePayData.AccountType;
      }
    }

    return returnAssurancePay;
  } catch (error) {
    context.dispatch('app/logToULS', {
      logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'policy.js', methodName: 'summaryTransform', trackerId: tfbId,
    }, { root: true });
    return assurancePayData;
  }
}

function getDefaultState() {
  return {
    policyNumber: '',
    accountBillSummary: [],
    ebillRequest: null,
    recurringRequest: null,
    pfPaymentRequest: null,
  };
}

// mutations
const mutations = {
  SET_ACCOUNT_BILL_SUMMARY(state, value) {
    const parsed = JSON.stringify(value);
    sessionStorage.setItem('tfb_ab_summary', parsed);

    state.accountBillSummary = value;
  },
  SET_EBILL_REQUEST(state, value) {
    state.ebillRequest = value;
  },
  SET_RECURRING_REQUEST(state, value) {
    state.recurringRequest = value;
  },
  SET_PF_PAYMENT_REQUEST(state, value) {
    state.pfPaymentRequest = value;
  },
  CLEAR_ACCOUNT_BILL_SUMMARY(state) {
    sessionStorage.removeItem('tfb_ab_summary');

    state.accountBillSummary = [];
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

// getters
const getters = {
  accountBillSummary(state) {
    if (!state.accountBillSummary || state.accountBillSummary.length === 0) {
      const tfbAccountBillSummary = sessionStorage.getItem('tfb_ab_summary');
      const tfbParsedAccountBillSummary = JSON.parse(tfbAccountBillSummary);

      state.accountBillSummary = tfbParsedAccountBillSummary;
    }

    return state.accountBillSummary;
  },
  ebillRequest(state) {
    return state.ebillRequest;
  },
  recurringRequest(state) {
    return state.recurringRequest;
  },
  pfPaymentRequest(state) {
    return state.pfPaymentRequest;
  },
};

// state
const state = getDefaultState();

// actions
const actions = {
  getAccountBillInformation(context, sRequest) {
    if (state.accountBillSummary && state.accountBillSummary.length > 0) {
      return state.accountBillSummary;
    }

    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${paymentLookupServiceUrl}/account-bill/policyholder/${sRequest.sMemberNumber.trim()}?access_token=${ssoToken}`, config)
        .then((policyResult) => {
          if (policyResult && policyResult.data) {
            const accountBillSummary = accountBillSummaryTransform(policyResult.data, context, sRequest.sTfbId);
            context.commit('SET_ACCOUNT_BILL_SUMMARY', accountBillSummary);
            resolve(accountBillSummary);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAccountBillDetailInformation(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${paymentLookupServiceUrl}/account-bill/policyholder/${sRequest.sMemberNumber.trim()}/${sRequest.sMasterAccount.trim()}`, config)
        .then((policyResult) => {
          if (policyResult && policyResult.data) {
            resolve(accountBillSummaryTransform(policyResult.data, context, sRequest.sTfbId));
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAccountBillPolicies(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${paymentLookupServiceUrl}/account-bill/policies/${sRequest.sMemberNumber}/${sRequest.sMasterAccount}`, config)
        .then((policyResult) => {
          if (policyResult && policyResult.data) {
            resolve(policyResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAccountBillEligiblePolicies(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${paymentLookupServiceUrl}/account-bill/policies/eligible/${sRequest.sMemberNumber}/pilot`, config)
        .then((policyResult) => {
          if (policyResult && policyResult.data) {
            resolve(policyResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getEncryptPaymentSummary(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'payments.js', methodName: 'getPaymentSummary-Begin', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.post(`${paymentLookupServiceUrl}/payments/policy/encrypt`, {
        PolicyNumber: sRequest.sPolicyNumber,
        LineOfBusiness: sRequest.sPolicyType,
        SourceIdentifier: 'BILLMATRIX',
        RequestTimestamp: format(new Date(), 'yyyy-MM-dd\'T\'hh:mm:ss'),
      }, config)
        .then((policyResult) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: JSON.stringify(policyResult), fileName: 'payments.js', methodName: 'getPaymentSummary-Result', trackerId: sRequest.sTfbId,
          }, { root: true });
          if (policyResult && policyResult.data) {
            resolve(policyResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'payments.js', methodName: 'getPaymentSummary-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  systemCheck(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'payments.js', methodName: 'systemCheck-Begin', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${paymentLookupServiceUrl}/payments/system-check/${sRequest.sPolicyNumber}`, config)
        .then((policyResult) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: JSON.stringify(policyResult), fileName: 'payments.js', methodName: 'systemCheck-Result', trackerId: sRequest.sTfbId,
          }, { root: true });
          if (policyResult && policyResult.data) {
            resolve(policyResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'payments.js', methodName: 'systemCheck-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getEbillInformation(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'payments.js', methodName: 'getEbillInformation-Begin', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.post(`${paymentLookupServiceUrl}/ebill/lookup`, { MemberNumber: sRequest.sMemberNumber, PolicyNumber: sRequest.sPolicyNumber, PolicyType: sRequest.sPolicyType }, config)
        .then((policyResult) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: JSON.stringify(policyResult), fileName: 'payments.js', methodName: 'getEbillInformation-Result', trackerId: sRequest.sTfbId,
          }, { root: true });
          if (policyResult && policyResult.data) {
            resolve(policyResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'payments.js', methodName: 'getEbillInformation-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getRecurringPaymentInfo(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'payments.js', methodName: 'getRecurringPaymentInfo-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${paymentLookupServiceUrl}/payments/recurring/${sRequest.sPolicyNumber}`, config)
        .then((policyResult) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: JSON.stringify(policyResult), fileName: 'payments.js', methodName: 'getRecurringPaymentInfo-Result', trackerId: sRequest.sTfbId,
          }, { root: true });
          if (policyResult && policyResult.data) {
            resolve(assurancePayTransform(policyResult.data, context, sRequest.sTfbId));
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'payments.js', methodName: 'getRecurringPaymentInfo-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  validateRoutingNumber(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${paymentLookupServiceUrl}/validation/routing-number/${sRequest.sRoutingNumber}`, config)
        .then((validationResults) => {
          if (validationResults && validationResults.data) {
            resolve(helpers.methods.replaceAll(validationResults.data, '"', ''));
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setupEbillInformation(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'payments.js', methodName: 'setupEbillInformation-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;

      const changeRequest = [];
      for (let i = 0; i < sRequest.sPolicyList.length; i += 1) {
        const policy = sRequest.sPolicyList[i];
        let enrollmentType = '';

        if ((policy.NewPhone === null || policy.NewPhone.MiraId === 0) && policy.NewEbill === true) {
          enrollmentType = 'EML';
        } else if ((policy.NewPhone !== null && policy.NewPhone.MiraId !== 0) && policy.NewEbill === true) {
          enrollmentType = 'BOTH';
        } else if ((policy.NewPhone !== null && policy.NewPhone.MiraId !== 0) && policy.NewEbill === false) {
          enrollmentType = 'TXT';
        }

        changeRequest.push({
          MemberInitials: policy.MemberInitials,
          MemberEmailAddress: sRequest.sEmailAddress,
          MemberPhoneNumber: helpers.methods.replaceAll(policy.NewPhone.PhoneNumber, 'Not Enrolled', ''),
          EnrollmentType: enrollmentType,
          PolicyNumber: policy.PolicyNumber,
          PolicyType: policy.PolicyType,
          MemberNumber: sRequest.sMemberNumber,
          MemberId: sRequest.sMemberId,
          MiraId: policy.NewPhone.MiraId,
        });
      }
      context.dispatch('app/logToULS', {
        logLevel: 'Info', logMessage: JSON.stringify(changeRequest), fileName: 'payments.js', methodName: 'setupEbillInformation-SendRequest', trackerId: sRequest.sTfbId,
      }, { root: true });

      axios.post(`${paymentModifyServiceUrl}/ebill/setup`, changeRequest, config)
        .then((setupResult) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: JSON.stringify(setupResult), fileName: 'payments.js', methodName: 'setupEbillInformation-Result', trackerId: sRequest.sTfbId,
          }, { root: true });
          if (setupResult && setupResult.data) {
            resolve(setupResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'payments.js', methodName: 'setupEbillInformation-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  removeEbillInformation(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'payments.js', methodName: 'removeEbillInformation-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;

      const changeRequest = [];
      for (let i = 0; i < sRequest.sPolicyList.length; i += 1) {
        const policy = sRequest.sPolicyList[i];
        changeRequest.push({
          PolicyNumber: policy.Number,
          PolicyType: policy.Type,
          MemberNumber: sRequest.sMemberNumber,
          MemberId: sRequest.sMemberId,
          EndRequestor: policy.Initials,
        });
      }
      context.dispatch('app/logToULS', {
        logLevel: 'Info', logMessage: JSON.stringify(changeRequest), fileName: 'payments.js', methodName: 'removeEbillInformation-Sending', trackerId: sRequest.sTfbId,
      }, { root: true });

      axios.post(`${paymentModifyServiceUrl}/ebill/remove`, changeRequest, config)
        .then((removeResult) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: JSON.stringify(removeResult), fileName: 'payments.js', methodName: 'removeEbillInformation-Result', trackerId: sRequest.sTfbId,
          }, { root: true });
          if (removeResult && removeResult.data) {
            resolve(removeResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'payments.js', methodName: 'removeEbillInformation-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  modifyRecurringPayments(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'payments.js', methodName: 'modifyRecurringPayments-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      const modifyRequest = {
        AccountType: sRequest.sAccountType, // .substring( 0, 2 ).toUpperCase()
        AgentId: sRequest.sAgentId,
        BankAccountNumber: sRequest.sAccountNumber,
        BankName: helpers.methods.replaceAll(sRequest.sBankName, '"', ''),
        BankRoutingNumber: sRequest.sRoutingNumber,
        EffectiveDate: sRequest.sEffectiveDate,
        InsuredEmailAddress: sRequest.sEmailAddress,
        MemberNumber: sRequest.sMemberNumber,
        PaymentDate: sRequest.sPaymentDate,
        PolicyNumber: sRequest.sPolicyNumber,
        PolicySubType: sRequest.sPolicySubType,
        PolicyType: sRequest.sPolicyType,
        PolicyholderName: sRequest.sPolicyholderName,
        RequestType: sRequest.sRequestType,
      };

      context.dispatch('app/logToULS', {
        logLevel: 'Info', logMessage: JSON.stringify(modifyRequest), fileName: 'payments.js', methodName: 'modifyRecurringPayments-SendRequest', trackerId: sRequest.sTfbId,
      }, { root: true });

      axios.post(`${paymentModifyServiceUrl}/eft`, modifyRequest, config)
        .then((modifyResult) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: JSON.stringify(modifyResult), fileName: 'payments.js', methodName: 'modifyRecurringPayments-Result', trackerId: sRequest.sTfbId,
          }, { root: true });
          if (modifyResult && modifyResult.data) {
            resolve(modifyResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'payments.js', methodName: 'modifyRecurringPayments-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  manageAccountBill(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'payments.js', methodName: 'manageAccountBill-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;

      const policyRequest = [];
      for (let i = 0; i < sRequest.accountBillArray.length; i += 1) {
        const policy = sRequest.accountBillArray[i];
        policyRequest.push({
          PolicyNumber: policy.PolicyNumber,
          LineOfBusiness: policy.LineOfBusiness,
          PolicyType: policy.PolicyType,
          PolicySubType: policy.PolicySubType,
          Status: 'ADD',
        });
      }

      const modifyRequest = {
        RequestType: '0',
        InsuredFirstName: sRequest.firstName,
        InsuredLastName: sRequest.lastName,
        AddressLineOne: sRequest.addressLine1,
        AddressLineTwo: (sRequest.addressLine2 || ''),
        City: sRequest.city,
        State: sRequest.state,
        ZipCode: sRequest.zipCode,
        County: sRequest.county,
        MemberNumber: sRequest.memberNumber,
        RequestedPaymentDate: sRequest.draftDay,
        AssurancePayFlag: sRequest.assurancePayFlag,
        NameOnBankAccount: (sRequest.accountName || ''),
        BankName: (sRequest.bankName || ''),
        BankRoutingNumber: (sRequest.routingNumber || ''),
        BankAccountNumber: (sRequest.accountNumber || ''),
        AccountType: (sRequest.accountType ? sRequest.accountType.Value : ''),
        RequestedPolicies: policyRequest,
      };
      context.dispatch('app/logToULS', {
        logLevel: 'Info', logMessage: JSON.stringify(modifyRequest), fileName: 'payments.js', methodName: 'manageAccountBill-SendRequest', trackerId: sRequest.sTfbId,
      }, { root: true });

      axios.post(`${paymentModifyServiceUrl}/account-bill/setup`, modifyRequest, config)
        .then((setupResult) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: JSON.stringify(setupResult), fileName: 'payments.js', methodName: 'manageAccountBill-Result', trackerId: sRequest.sTfbId,
          }, { root: true });
          if (setupResult && setupResult.data) {
            resolve(setupResult.data);
          } else {
            context.dispatch('app/logToULS', {
              logLevel: 'Error', logMessage: setupResult, fileName: 'payments.js', methodName: 'manageAccountBill-NullResult', trackerId: sRequest.sTfbId,
            }, { root: true });
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'payments.js', methodName: 'manageAccountBill-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  makePremiumFinancePayment(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'payments.js', methodName: 'makePremiumFinancePayment-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;

      const paymentRequest = {
        ContractNumber: sRequest.sContractNumber,
        MemberNumber: sRequest.sMemberNumber,
        RoutingNumber: sRequest.sRoutingNumber,
        AccountNumber: sRequest.sAccountNumber,
        PaymentAmount: sRequest.sPaymentAmount,
      };
      context.dispatch('app/logToULS', {
        logLevel: 'Info', logMessage: JSON.stringify(paymentRequest), fileName: 'payments.js', methodName: 'makePremiumFinancePayment-SendRequest', trackerId: sRequest.sTfbId,
      }, { root: true });

      axios.post(`${paymentModifyServiceUrl}/payments/remittance/premium-finance`, paymentRequest, config)
        .then((paymentResult) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: JSON.stringify(paymentResult), fileName: 'payments.js', methodName: 'makePremiumFinancePayment-Result', trackerId: sRequest.sTfbId,
          }, { root: true });
          if (paymentResult && paymentResult.data) {
            resolve(paymentResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'payments.js', methodName: 'makePremiumFinancePayment-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
