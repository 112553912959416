<template>
  <div>
    <!-- Insured Driver Information -->
    <v-row>
      <v-col cols="12">
        <span class="tfb-title-text-small text-darkest-blue text-uppercase">
          Insured Driver Information
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <select-input rules="required"
                      label="Name"
                      :items="drivers"
                      item-text="name.displayName"
                      return-object
                      v-model="insured.driver"></select-input>
      </v-col>
    </v-row>
    <div v-if="insured.driver && insured.driver.name && insured.driver.name.displayName && insured.driver.name.displayName === 'Other'">
      <v-row>
        <v-col cols="12">
          <text-input rules="required|name|min:2|max:25"
                      minlength="2"
                      maxlength="25"
                      label="Driver First Name"
                      v-model="insured.driver.name.firstName"
                      autocomplete="shipping given-name"
                      autocapitalize="words"></text-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <text-input rules="required|name|min:2|max:25"
                      minlength="2"
                      maxlength="25"
                      label="Driver Last Name"
                      v-model="insured.driver.name.lastName"
                      autocomplete="shipping family-name"
                      autocapitalize="words"></text-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <text-input rules="min:4|max:13"
                      label="Driver License Number"
                      autocapitalize="characters"
                      minlength="4"
                      maxlength="13"
                      v-model="insured.driver.licenseNumber"
                      vid="dlNum"></text-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <select-input rules="alpha|min:2|max:2"
                        label="Driver License State"
                        :items="stateList"
                        item-text="StateName"
                        item-value="StateCode"
                        v-model="insured.driver.licenseState"
                        vid="dlState"></select-input>
        </v-col>
      </v-row>
    </div>
    <!-- Insured Vehicle Information -->
    <v-row>
      <v-col cols="12">
        <span class="tfb-title-text-small text-darkest-blue text-uppercase">
          Insured Vehicle Information
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <select-input rules="required"
                      label="Vehicle"
                      :items="vehicles"
                      item-text="displayName"
                      item-value="seqNum"
                      return-object
                      v-model="insured.vehicle"></select-input>
      </v-col>
    </v-row>
    <v-row v-if="insured.vehicle">
      <v-col cols="12"
             md="8">
        <label>
          Did the airbags deploy?
        </label>
      </v-col>
      <v-col cols="12"
             md="4">
        <radio-group-input rules="required"
                           name="vehicle airbags"
                           :options="yesNoOptions"
                           v-model="insured.vehicle.wereAirbagsDeployed"
                           vid="airbags"></radio-group-input>
      </v-col>
    </v-row>
    <v-row v-if="insured.vehicle">
      <v-col cols="12"
             md="8">
        <label>
          Is this vehicle driveable?
        </label>
      </v-col>
      <v-col cols="12"
             md="4">
        <radio-group-input rules="required"
                           name="vehicle driveable"
                           :options="yesNoOptions"
                           v-model="insured.vehicle.isDriveable"
                           vid="driveable"></radio-group-input>
      </v-col>
    </v-row>
    <v-row v-if="insured.vehicle && insured.vehicle.isDriveable && insured.vehicle.isDriveable === 'N'">
      <v-col cols="12">
        <text-input rules="required|min:3|max:40"
                    minlength="3"
                    maxlength="40"
                    autocomplete="off"
                    counter="40"
                    label="Current Location of Vehicle"
                    v-model="insured.vehicle.currentLocation"></text-input>
      </v-col>
    </v-row>
    <v-row v-if="insured.driver">
      <v-col cols="12"
             md="8">
        <label>
          Was anyone in this vehicle injured?
        </label>
      </v-col>
      <v-col cols="12"
             md="4">
        <radio-group-input rules="required"
                           name="occupant injuries"
                           :options="yesNoOptions"
                           v-model="insured.driver.injuryInd"
                           vid="injuries"></radio-group-input>
      </v-col>
    </v-row>
    <v-row v-if="insured.driver && insured.driver.injuryInd && insured.driver.injuryInd === 'Y'">
      <v-col>
        <text-area-input rules="required|all_allowed|min:3|max:50"
                         label="Describe Injuries"
                         autocapitalize="sentences"
                         autocomplete="off"
                         minlength="3"
                         maxlength="50"
                         :counter="50"
                         v-model="insured.driver.injuryDescription"></text-area-input>
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import { dispatch } from 'vuex-pathify';
  import tfbHelperMixin from '@/mixins/helpers';
/* eslint object-shorthand: 0 */

  export default {
    name: 'ClaimInsured',
    props: {
      insured: {
        type: Object,
        required: true,
      },
      drivers: {
        type: Array,
        required: true,
      },
      vehicles: {
        type: Array,
        required: true,
      },
    },
    mixins: [tfbHelperMixin],
    data() {
      return {
        yesNoOptions: [{ Id: 0, DisplayText: 'Yes', Value: 'Y' }, { Id: 1, DisplayText: 'No', Value: 'N' }],
      };
    },
    methods: {
      getStateList() {
        // Get the state information
        dispatch('app/getStateList', { sTfbId: this.trackingId })
          .then((stateListResponse) => {
            if (stateListResponse && stateListResponse.length > 0) {
              this.stateList = stateListResponse;
            }
          })
          .catch((error) => {
            dispatch('app/setErrorMessage', error.toString());
          });
      },
    },
    watch: {
      'accident.AccidentDate'(val) {
        this.accidentDateFormatted = this.formatDate(val);
      },
    },
    mounted() {
      this.getStateList();
    },
  };
</script>
<style lang="scss">

</style>
