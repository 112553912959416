import { extend, localize } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
// import { required, email, min, max, is, required_if } from 'vee-validate/dist/rules';
import en from 'vee-validate/dist/locale/en.json';
import store from '../store/index';
import helpers from './helpers';
/* eslint prefer-destructuring: 0 */

// Add all the available rules
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

localize('en', en);

// Override the default message.
// extend('required', {
//  ...required,
//  message: '{_field_} is required',
// });

// Override the default message.
// extend('max', {
//  ...max,
//  message: '{_field_} must be {length} characters or less',
// });

// Override the default message.
// extend('email', {
//  ...email,
//  message: 'This field must be a valid email',
// });

// No message specified.
// extend('email', email);

extend('all_allowed', {
  // eslint-disable-next-line no-useless-escape
  validate: value => /^[A-Za-z0-9\/\\_\-;.,!?'&#@()* ]+$/.test(value),
  message: '{_field_} contains some invalid characters',
});

extend('all_allowed_extended', {
  // eslint-disable-next-line no-useless-escape
  validate: value => /^[A-Za-z0-9~!@#$%^&*()_\-+= {[}\]|/\:;"<,>.?/ \r\n]+$/.test(value),
  message: '{_field_} contains some invalid characters',
});

extend('vin_valid', {
  validate: async (value) => {
    const vinErrors = [];
    const response = await store.dispatch('quotes/validateVIN', { sVIN: value, sTfbId: '' })
      .then((validationResponse) => {
        if (!validationResponse) {
          vinErrors.push('Unable to locate vehicle information for the entered VIN. Please validate the entered information or enter vehicle details.');
        }

        return {
          valid: validationResponse,
          errors: vinErrors,
        };
      })
      .catch((error) => {
        vinErrors.push(error.message);
        return {
          valid: false,
          errors: vinErrors,
        };
      });

    if (response && response.valid) {
      return true;
    }

    return {
      valid: false,
      data: {
        // this will be used to interpolate the message.
        serverMessage: response.errors[0],
      },
    };
  },
  message: 'Unable to locate vehicle information for the entered VIN. Please validate the entered information or enter vehicle details.',
});

extend('bi_pd_coverage_difference', {
  params: ['biCoverage'],
  validate: (value, { biCoverage }) => {
    const splitBI = biCoverage.split('/');
    return parseInt(value, 10) <= parseInt(splitBI[0], 10);
  },
  message: 'Property Damage Liability limit cannot be greater than Bodily Injury Liability limit.',
});

extend('bi_umbi_coverage_difference', {
  params: ['biCoverage'],
  validate: (value, { biCoverage }) => value <= biCoverage,
  message: 'Uninsured/Underinsured Motorist Bodily Injury limits cannot be greater than Bodily Injury Liability limits.',
});

extend('pd_umpd_coverage_difference', {
  params: ['pdCoverage'],
  validate: (value, { pdCoverage }) => value <= pdCoverage,
  message: 'Uninsured/Underinsured Motorist Property Damage limits cannot be greater than Property Damage Liability limits.',
});

extend('decimal', {
  validate: value => /^[-]?\d*(\.\d+)?$/.test(value),
  message: '{_field_} must be in X.XX format',
});

extend('name', {
  validate: value => /^[a-zA-Z0-9-&',. ]+$/.test(value),
  message: '{_field_} contains some invalid characters',
});

extend('full_name_alpha_only', {
  validate: value => /^[a-zA-Z',. ]+$/.test(value),
  message: '{_field_} contains some invalid characters',
});

// Excludes certain domains from being entered
//  ProNovaPartners.com - Spammers of our Contact form
//  repoggvo.com - Spammers of our Contact form
//  ipayperlead.com - Spammers of our Contact form
//  REMOVED 07/28/2022 - ProtonMail.com/PM.me/proton.me - Per legal/security, TxFB cannot send email to or receive email from the Proton Mail service
//  TXFBAgent.com - TxFB user via the agent backdoor
extend('valid_domain', {
  validate: value => !(/^.*(ProNovaPartners.com)|(TXFBAgent.com)|(repoggvo.com)|(ipayperlead.com).*$/igm.test(value)),
  message: 'Sorry, that email domain is not allowed by our system',
});

extend('phone_number', {
  validate: value => /^$|^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value),
  message: '{_field_} is not a correctly formatted phone number (XXX-XXX-XXXX)',
});

extend('min_length', {
  params: ['length'],
  validate(value, { length }) {
    return value.length >= length;
  },
  message: 'The {_field_} must contain at least {length} item(s)',
});

extend('routing_number', {
  validate: value => /^((0[0-9])|(1[0-2])|(2[1-9])|(3[0-2])|(6[1-9])|(7[0-2])|80)([0-9]{7})$/.test(value),
  message: '{_field_} format is invalid',
});

extend('member_number', {
  validate: value => /^([A-Z0-9]{4}|[A-Z0-9]{6}|[A-Z0-9]{11})$/.test(value),
  message: '{_field_} should be alphanumeric and 4, 6, or 11 characters long',
});

extend('time', {
  validate: value => /[0-9]{1,2}:[0-9]{2} (AM|PM|am|pm)/.test(value),
  message: '{_field_} is not a correctly formatted time (XX:XX AM/PM)',
});

extend('hasNUppercaseLetters', {
  params: ['numberOfCharacters'],
  validate: (value, { numberOfCharacters }) => helpers.methods.hasNUppercaseCharacters(value, numberOfCharacters),
  message: '{_field_} must contain {numberOfCharacters} uppercase character(s)',
});

extend('hasNNumbers', {
  params: ['numberOfNumbers'],
  validate: (value, { numberOfNumbers }) => helpers.methods.hasNNumericCharacters(value, numberOfNumbers),
  message: '{_field_} must contain {numberOfNumbers} number(s)',
});

extend('hasMoreThanNConcurrentLetters', {
  params: ['numberOfCharacters'],
  validate: (value, { numberOfCharacters }) => helpers.methods.hasMoreThanNConcurrentLetters(value, numberOfCharacters),
  message: '{_field_} must not contain more than {numberOfCharacters} concurrent character(s)',
});
