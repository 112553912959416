<template>
    <v-row no-gutters
           v-if="accountBill && accountBill !== undefined && accountBill !== null">
      <v-col cols="12">
        <label class="font-weight-medium">Current Balance Due:</label> {{formatMoney(accountBill.CurrentAmountDue, 2)}}<br />
        <label class="font-weight-medium">Remaining Balance Due:</label> {{formatMoney(accountBill.RemainingAmountDue, 2)}}<br />
        <label class="font-weight-medium">Due Date:</label> {{formatDate(accountBill.CurrentDueDate)}}<br />
        <payment-menu :showLink="accountBill.CurrentRecurring !== 'CHANGE' && !paymentsDisabled"
                      :accountNumber="accountBill.MemberNumber"
                      :policyNumber="accountBill.MasterAccountNumber"
                      policyType="AB"
                      :isGuidewire="1 == 2"
                      :paymentTracking="trackingId"></payment-menu>
        <span v-if="accountBill.CurrentRecurring !== 'CHANGE' && paymentsDisabled">Online Payments Unavailable<br /></span>
      </v-col>
    </v-row>
</template>
<script>
  import tfbHelperMixin from '@/mixins/helpers';
  import PaymentMenu from '../components/PaymentMenu.vue';

  export default {
    name: 'SummaryAccountBillBillingDisplay',
    props: {
      accountBill: {
        type: Object,
        required: true,
      },
    },
    mixins: [tfbHelperMixin],
    components: {
      PaymentMenu,
    },
    data() {
      return {
        paymentsDisabled: false,
      };
    },
    mounted() {
      // Is this a member logged in or an internal user?
      if (this.isMemberRole) {
        // See if payments are enabled
        this.$store.dispatch('app/doApplicationCheck', { sApplicationName: 'Payments', sTfbId: this.trackingId })
          .then((returnedStatus) => {
            if (returnedStatus !== '') {
              this.paymentsDisabled = true;
            }
          })
          .catch((error) => {
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      } else {
        this.paymentsDisabled = true;
      }
    },
  };
</script>
<style lang="scss">

</style>
