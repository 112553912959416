<template>
  <v-container fluid
               class="tfb-smaller-container tfb-component-container"
               v-if="showEnrollmentSection && eligiblePolicies.length > 0">
    <v-row>
      <v-col cols="12">
        <v-container fluid
                     class="drop-shadow background-white px-md-10 pb-5">
          <v-row>
            <v-col cols="12">
              <span class="tfb-title-text-small text-uppercase">
                Paperless Policy Notifications
              </span>
            </v-col>
          </v-row>
          <v-row v-if="showEnrollmentMessage">
            <v-col cols="12">
              <span class="font-weight-bold text-blue">Why should you consider enrolling in Paperless Policy Notifications?</span>
              <ul>
                <li>Convenient email notifications alert you when new policy documents are available</li>
                <li>24/7 access to your policy documents</li>
                <li>Reduce clutter in your home and mailbox</li>
                <li>Conserve Earth's natural resources by reducing paper output</li>
              </ul>
            </v-col>
          </v-row>
          <v-row v-if="!emailVerified">
            <v-col cols="12"
                   class="text-center">
              To activate Paperless Policy Notifications on eligible policies, please first
              <a href="#email">verify your email address</a>.
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12"
                   class="text-center">
              <strong>Paperless Policy Notifications will be sent to: </strong>{{emailAddress}}
            </v-col>
          </v-row>
          <v-form ref="unEnrollForm"
                  @submit.stop.prevent="changePaperless"
                  v-if="emailVerified">
            <v-row>
              <v-col cols="12">
                <v-data-table :headers="paperlessHeaders"
                              :items="eligiblePolicies"
                              :loading="paperlessLoading"
                              :no-results-text="noResultsText"
                              :no-data-text="noResultsText"
                              hide-default-footer
                              disable-sort>
                  <template v-slot:item.MemberInitials="{ item }">
                    <v-simple-checkbox v-if="item.AllowChange"
                                       v-model="item.NewPaperless"
                                       :value="item.OriginalPaperless"
                                       :ripple="false"></v-simple-checkbox>
                    <span v-else>
                      {{ item.MemberEmailAddress }}
                    </span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row v-if="!hasChanges">
              <v-col cols="12"
                     class="v-messages theme--light error--text">
                Please make one or more changes to Paperless Policy Notifications enrollment before submitting.
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"
                     md="4"
                     sm="6">
                <v-btn block
                       :disabled="formDisabled"
                       type="submit"
                       class="tfb-button background-red">Submit</v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-overlay absolute
                     :value="saveOverlay">
            <v-progress-circular indeterminate
                                 size="64"></v-progress-circular>
          </v-overlay>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { dispatch } from 'vuex-pathify';
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'PaperlessChanges',
    mixins: [tfbHelperMixin],
    props: {
      contactList: {
        type: Array,
        required: true,
      },
      policyList: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        showEnrollmentSection: false,
        showEnrollmentMessage: true,
        saveOverlay: false,
        formDisabled: false,
        eligiblePolicies: [],
        changedPolicies: [],
        paperlessLoading: true,
        noResultsText: null,
        hasChanges: true,
        paperlessHeaders: [
          { text: 'Policy Type', align: 'start', value: 'PolicyDescription' },
          { text: 'Policy', value: 'PolicyNumber' },
          { text: 'Activate', value: 'MemberInitials' },
        ],
      };
    },
    computed: {
      memberId() {
        try {
          return this.$store.getters['member/memberId'];
        } catch (error) {
          return '0';
        }
      },
      memberNumber() {
        try {
          return this.$store.getters['member/memberNumber'];
        } catch (error) {
          return '';
        }
      },
      emailAddress() {
        try {
          return this.$store.getters['member/emailAddress'];
        } catch (error) {
          return '';
        }
      },
      emailVerified() {
        try {
          return this.$store.getters['member/verifiedIndicator'];
        } catch (error) {
          return false;
        }
      },
    },
    methods: {
      getAccountSummary() {
        dispatch('policy/getAccountSummary', { sMemberNumber: this.memberNumber, sTfbId: this.trackingId })
          .then(() => {
            // Policies should load
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out, log out and go to login
              this.$store.dispatch('app/setIsLoading', true);
              dispatch('member/doLogout')
                .then(() => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$router.push({ name: 'policyholderLogin' });
                })
                .catch((accessError) => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$store.dispatch('app/setErrorMessage', accessError.toString());
                });
            }
            dispatch('app/setErrorMessage', error.toString());
          });
      },
      buildPaperlessInformation(allowedPaperless) {
        // Split the string to find the policyType-policySubType strings
        const allowedPaperlessSplit = allowedPaperless.split(',');

        // Loop through list of allowed Paperless Policy types
        for (let a = 0; a < allowedPaperlessSplit.length; a += 1) {
          if (allowedPaperlessSplit[a]) {
            const policyTypeSplit = allowedPaperlessSplit[a].split('-');

            if (policyTypeSplit.length > 1 && this.policyList && this.policyList.length > 0) {
              // Loop through the list of policies to see if any are eligible
              for (let p = 0; p < this.policyList.length; p += 1) {
                const policy = this.policyList[p];

                // Is this an eligible policy?
                if (policy && policy !== null && policy.PolicyType === policyTypeSplit[0] && policy.PolicySubType === policyTypeSplit[1] && policy.PolicyStatus === 'ACTIVE') {
                  if (policy.PaperlessFlag === 'Y') {
                    // There is at least one policy already on Paperless, so don't show the reasons why to enroll
                    this.showEnrollmentMessage = false;

                    // Get the existing paperless enrollment information
                    dispatch('policy/getPaperlessInformation', {
                      sMemberNumber: this.memberNumber, sPolicyNumber: policy.PolicyNumber, sPolicyType: policy.PolicyType, sTfbId: this.trackingId,
                    })
                      .then((paperlessResult) => {
                        if (paperlessResult && paperlessResult.ErrorMessage === null) {
                          if (paperlessResult && (this.memberId !== paperlessResult.MemberId)) {
                            // The signed up member is different
                            this.eligiblePolicies.push({
                              PolicyNumber: policy.PolicyNumber,
                              PolicyType: policy.PolicyType,
                              PolicyDescription: policy.PolicyDescription,
                              AllowChange: false,
                              MemberEmailAddress: paperlessResult.MemberEmailAddress,
                              OriginalPaperless: true,
                              NewPaperless: true,
                              MemberInitials: '',
                            });
                          } else {
                            // Same member that signed up, or no record returned
                            this.eligiblePolicies.push({
                              PolicyNumber: policy.PolicyNumber,
                              PolicyType: policy.PolicyType,
                              PolicyDescription: policy.PolicyDescription,
                              AllowChange: true,
                              MemberEmailAddress: this.emailAddress,
                              OriginalPaperless: true,
                              NewPaperless: true,
                              MemberInitials: '',
                            });
                          }
                        } else {
                          // Error getting the current information or no record returned, so don't allow changes
                          this.eligiblePolicies.push({
                            PolicyNumber: policy.PolicyNumber,
                            PolicyType: policy.PolicyType,
                            PolicyDescription: policy.PolicyDescription,
                            AllowChange: false,
                            MemberEmailAddress: this.emailAddress,
                            OriginalPaperless: false,
                            NewPaperless: false,
                            MemberInitials: '',
                          });
                        }
                      })
                      .catch(() => {
                        // Error getting the current information, so don't allow changes
                        this.eligiblePolicies.push({
                          PolicyNumber: policy.PolicyNumber,
                          PolicyType: policy.PolicyType,
                          PolicyDescription: policy.PolicyDescription,
                          AllowChange: false,
                          MemberEmailAddress: this.emailAddress,
                          OriginalPaperless: false,
                          NewPaperless: false,
                          MemberInitials: '',
                        });
                      });
                  } else {
                    // Add the eligible policy to the list
                    this.eligiblePolicies.push({
                      PolicyNumber: policy.PolicyNumber,
                      PolicyType: policy.PolicyType,
                      PolicyDescription: policy.PolicyDescription,
                      AllowChange: true,
                      MemberEmailAddress: this.emailAddress,
                      OriginalPaperless: false,
                      NewPaperless: false,
                      MemberInitials: '',
                    });
                  }
                  this.paperlessLoading = false;
                }
              }
            } else {
              // No policies available
              this.noResultsText = 'No policies available';
              this.paperlessLoading = false;
            }
          }
        }
      },
      changePaperless() {
        this.hasChanges = false;
        this.saveOverlay = true;
        for (let p = 0; p < this.eligiblePolicies.length; p += 1) {
          const policy = this.eligiblePolicies[p];

          if (policy.NewPaperless !== policy.OriginalPaperless) {
            // Option has changed; add it to the list
            this.changedPolicies.push(policy);
            this.hasChanges = true;
          }
        }

        if (this.hasChanges) {
          this.$store.commit('policy/SET_PAPERLESS_REQUEST', this.changedPolicies);
          this.$router.push({ name: 'policyholderChangePaperless' });
          this.saveOverlay = false;
        } else {
          this.saveOverlay = false;
        }
      },
    },
    mounted() {
      // Is enrollment in Paperless Policy allowed?
      this.$store.dispatch('app/getFeatureFlag', { sTitle: 'PaperlessPolicyNotifyAllowed' })
        .then((allowedPaperless) => {
          if (allowedPaperless && allowedPaperless.length > 0) {
            this.showEnrollmentSection = true;
            this.buildPaperlessInformation(allowedPaperless);
          } else {
            // Paperless Policy not enabled
            this.noResultsText = 'Paperless Policy not available';
            this.paperlessLoading = false;
          }
        })
        .catch((error) => {
          this.$store.dispatch('app/setErrorMessage', error.toString());
          this.paperlessLoading = false;
        });

      if (!this.isMemberRole) {
        // Not the member, don't allow changes
        this.formDisabled = true;
      }
    },
  };
</script>
<style lang="scss">
</style>
