<template>
  <v-menu offset-y
          :close-on-content-click="false"
          v-if="showLink">
    <template v-slot:activator="{ on }">
      <!-- I don't know why, but items in here have to be in reverse order -->
      <br />
      <v-icon class="mdi-18px">mdi-open-in-new</v-icon>&nbsp;
      <a v-on="on"
         class="make-payment-link">
        Make a Payment</a>
    </template>
    <v-list dense
            class="make-payment-menu">
      <v-list-item three-line
                   class="make-payment-menu-item">
        <span v-if="registeredLinkDisabled"
              class="make-payment-item-link">
          Pay via Checking or Savings Account Link Currently Unavailable
        </span>
        <span v-else>
          <a :href="registeredPaymentUrl"
             class="make-payment-item-link"
             target="_blank"
             title="Pay via Checking or Savings Account"
             v-on:click="policyPayment(registeredPaymentUrl)">Pay via Checking or Savings Account</a>
        </span>
        <p class="make-payment-item-text">No processing fees (via My TFBI Account)</p>
      </v-list-item>
      <v-list-item three-line
                   class="make-payment-menu-item">
        <span v-if="nonRegisteredLinkDisabled"
              class="make-payment-item-link">
          Pay via Credit, Debit, Checking or Savings Account Link Currently Unavailable
        </span>
        <span v-else>
          <a :href="nonRegisteredPaymentUrl"
             class="make-payment-item-link"
             target="_blank"
             title="Pay via Credit, Debit, Checking or Savings Account"
             v-on:click="policyPayment(nonRegisteredPaymentUrl)">Pay via Credit, Debit, Checking or Savings Account</a>
        </span>
        <p class="make-payment-item-text">Processing fees ($3.95) and other restrictions will apply (via guest payment site)</p>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
  export default {
    name: 'PaymentMenu',
    props: {
      showLink: {
        type: Boolean,
        required: true,
      },
      accountNumber: {
        type: String,
        required: true,
      },
      policyNumber: {
        type: String,
        required: true,
      },
      policyType: {
        type: String,
        required: true,
      },
      isGuidewire: {
        type: Boolean,
        required: true,
      },
      paymentTracking: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        registeredPaymentUrl: '',
        nonRegisteredPaymentUrl: '',
        registeredLinkDisabled: true,
        nonRegisteredLinkDisabled: true,
      };
    },
    mounted() {
      if (this.isGuidewire) {
        // Get the token value
        const invoiceCloudToken = this.$store.getters['member/invoiceCloudToken'];
        if (invoiceCloudToken && invoiceCloudToken !== undefined && invoiceCloudToken !== null && invoiceCloudToken !== '' && invoiceCloudToken !== 'null') {
          this.registeredPaymentUrl = `${this.$store.getters['app/invoiceCloudIFrameUrl']}?RemoteAuthenticationToken=${invoiceCloudToken}&ViewMode=0&RedirectID=8`;
          this.registeredLinkDisabled = false;
        }

        this.$store.dispatch('member/createCloudPaymentUrl', { sAccountNumber: this.accountNumber, sInvoiceNumber: '', sTfbId: this.trackingId })
          .then((cloudPaymentReturn) => {
            // console.log(cloudPaymentReturn);
            if (cloudPaymentReturn && cloudPaymentReturn !== null && cloudPaymentReturn !== undefined && cloudPaymentReturn !== '') {
              this.nonRegisteredPaymentUrl = cloudPaymentReturn;
              this.nonRegisteredLinkDisabled = false;
            }
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out, log out and go to login
              this.$store.dispatch('app/setIsLoading', true);
              this.$store.dispatch('member/doLogout')
                .then(() => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$router.push({ name: 'policyholderLogin' });
                })
                .catch((accessError) => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$store.dispatch('app/setErrorMessage', accessError.toString());
                });
            }
          });
      } else {
        this.registeredPaymentUrl = `${process.env.VUE_APP_ROOT_URI}/payments/${this.policyType}/${this.policyNumber}`;
        this.registeredLinkDisabled = false;
        this.nonRegisteredPaymentUrl = this.$store.getters['app/nonRegisteredFiservPaymentUrl'];
        this.nonRegisteredLinkDisabled = false;
      }
    },
    methods: {
      policyPayment(link) {
        this.$store.dispatch('app/logToULS', {
          logLevel: 'Info',
          logMessage: `Going to: ${link}`,
          fileName: 'PaymentMenu.vue',
          methodName: 'policyPayment-Start',
          trackerId: this.paymentTracking,
        });
      },
    },
  };
</script>
<style lang="scss">
  .make-payment-link {
    text-decoration: none;
    color: #008ce1;
  }

  .make-payment-menu {
    max-width: 400px !important;
  }

  .make-payment-menu-item {
    -webkit-align-items: center;
    align-items: center;
    -webkit-align-self: stretch;
    align-self: stretch;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex: 1 1;
    flex: 1 1;
    overflow: hidden;
    padding: 8px 16px;
    min-height: 10px !important;
  }

    .make-payment-menu-item > :not(:last-child) {
      margin-bottom: 2px;
    }

  .make-payment-item-link {
    font-size: .8125rem;
    font-weight: 500;
    line-height: 1rem;
    text-overflow: unset !important;
    white-space: normal !important;
    overflow: visible !important;
    flex: 1 1 100%;
    align-self: center;
  }

  .make-payment-item-text {
    font-size: .8125rem;
    font-weight: 500;
    line-height: 1rem;
    text-overflow: unset !important;
    white-space: normal !important;
    overflow: visible !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    flex: 1 1 100%;
    color: rgba(0,0,0,.6);
  }
</style>
