<template>
  <v-container fluid
               class="tfb-smaller-container tfb-component-container">
    <v-row>
      <v-col cols="12">
        <v-container fluid
                     class="drop-shadow background-white px-md-10 pb-5">
          <iframe :src="payByTextIFrameUrl"
                  title="Pay by Text Enrollment"
                  class="payByTextIFrame"></iframe>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  export default {
    name: 'InvoiceCloudTextPayment',
    computed: {
      remoteAuthenticationToken() {
        try {
          return this.$store.getters['member/invoiceCloudToken'];
        } catch (error) {
          return '';
        }
      },
      payByTextIFrameUrl() {
        try {
          if (!this.isMemberRole && this.remoteAuthenticationToken && this.remoteAuthenticationToken !== null && this.remoteAuthenticationToken !== '') {
            // Not the member, don't allow changes
            return `${this.$store.getters['app/invoiceCloudIFrameUrl']}?RemoteAuthenticationToken=${this.remoteAuthenticationToken}&ViewMode=1&RedirectID=11`;
          }

          return '';
        } catch (error) {
          return '';
        }
      },
    },
  };
</script>
<style lang="scss">

  .payByTextIFrame iframe {
    width: 100%;
    height: 100%;
  }

  .payByTextIFrame {
    width: 100%;
    height: 400px;
  }
</style>
