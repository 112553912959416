const webmasterMail = 'webmaster@txfb-ins.com';
const primaryPhone = { display: '1.877.327.6287', link: 'tel:+18773276287' };
const ivrPhone = { display: '1.866.658.9038', link: 'tel:+18666589038' };
const guidewireIvrPhone = { display: '1.866.658.9038', link: 'tel:+18666589038' };
const contactUsPhone = { display: '1.800.772.6535', link: 'tel:+18007726535' };
const claimsReportingCenter = { title: 'Texas Farm Bureau Insurance 24-Hour Claims Reporting Center', phoneDisplay: '1.800.266.5458', phoneLink: 'tel:+18002665458' };
const texasWindstorm = { title: 'Texas Windstorm Insurance Association', phoneDisplay: '1.800.788.8247', phoneLink: 'tel:+18007888247' };
const wacoClaims = { title: 'Waco Claims Office', phoneDisplay: '1.800.224.7936', phoneLink: 'tel:+18002247936' };
const wrightFlood = { title: 'Wright Flood', phoneDisplay: '1.800.725.9472', phoneLink: 'tel:+18007259472' };
const roadsideAssistance = { title: 'Roadside Assistance', phoneDisplay: '1.833.832.7623', phoneLink: 'tel:+18338327623' };
const fraudHotline = { title: 'Fraud Hotline', phoneDisplay: '1.800.711.0394', phoneLink: 'tel:+18007110394' };
const tfbPhone = { title: '1.877.FARM BUREAU', phoneDisplay: '1.877.FARM BUREAU', phoneLink: 'tel:+18773276287' };
const poBoxAddress = 'Post Office Box 2689, Waco, Texas 76702-2689';
const legalContact = {
  name: 'Paul D. Marable, III',
  emailDisplay: 'pmarable@txfb.org',
  emailLink: 'mailto:pmarable@txfb.org',
  address: '7420 Fish Pond Road, Waco, Texas 76710',
};
const billingAddress = {
  name: 'Texas Farm Bureau Insurance Companies',
  addressLineOne: 'P.O. Box 660755',
  addressLineTwo: 'Dallas, TX 75266-0755',
};
const corporateOfficeAddress = {
  name: 'Texas Farm Bureau Insurance Companies',
  addressLineOne: '7420 Fish Pond Road',
  addressLineTwo: 'Waco, TX 76710',
};
const defaultAgentImage = `${process.env.VUE_APP_ROOT_URI}/agentphotos/9999-.jpg`;

export default {
  data() {
    return {
      webmasterMail,
      primaryPhone,
      ivrPhone,
      guidewireIvrPhone,
      contactUsPhone,
      claimsReportingCenter,
      texasWindstorm,
      wacoClaims,
      wrightFlood,
      roadsideAssistance,
      fraudHotline,
      tfbPhone,
      poBoxAddress,
      legalContact,
      billingAddress,
      corporateOfficeAddress,
      defaultAgentImage,
    };
  },
};
