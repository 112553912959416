<template>
  <div id="policyholder-registration"
       class="background-darker-blue tfbins-registration">
    <v-container class="tfbins-registration-box"
                 v-if="!registered">
      <h1 class="tfb-title-text-medium text-uppercase">Registration</h1>
      <ValidationObserver ref="obv" v-slot="{ handleSubmit }">
        <v-form @submit.stop.prevent="handleSubmit(onSubmit)">
          <v-row>
            <v-col cols="12">
              <text-input rules="required|alpha_num|min:6|max:11"
                          minlength="6"
                          maxlength="11"
                          label="Policy Number"
                          v-model="policyNumber"></text-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <keep-alive>
                <ValidationProvider tag="div"
                                    :bails="false"
                                    rules="required|alpha_num|min:4|max:11"
                                    name="Member Number"
                                    v-slot="{ errors, valid, required }">
                  <v-text-field outlined
                                hide-details="auto"
                                v-model="memberNumber"
                                :error-messages="errors"
                                :success="valid"
                                minlength="4"
                                maxlength="11"
                                label="Member Number">
                    <template v-slot:append>
                      <v-icon v-if="required">mdi-asterisk</v-icon>
                    </template>
                  </v-text-field>
                </ValidationProvider>
              </keep-alive>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <span class="tfb-title-text-smaller text-uppercase">
                Username
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <text-input rules="required|email|valid_domain|confirmed:username|min:4|max:60"
                          minlength="4"
                          maxlength="60"
                          label="Email Address"
                          autocomplete="username"
                          v-model="userName"></text-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <text-input rules="required|email|min:4|max:60"
                          minlength="4"
                          maxlength="60"
                          label="Confirm Email Address"
                          v-model="confirmUserName"
                          autocomplete="username"
                          vid="username"></text-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <span class="tfb-title-text-smaller text-uppercase">
                Password
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <text-input rules="required|all_allowed|min:16|max:99|confirmed:password|hasMoreThanNConcurrentLetters:2|hasNUppercaseLetters:1|hasNNumbers:2"
                          minlength="16"
                          maxlength="99"
                          label="Password"
                          v-model="passWord"
                          autocomplete="new-password"
                          type="password"></text-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <text-input rules="required|all_allowed|min:16|max:99"
                          minlength="16"
                          maxlength="99"
                          label="Confirm Password"
                          v-model="confirmPassWord"
                          autocomplete="new-password"
                          type="password"
                          vid="password"></text-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h4>Password requirements:</h4>
              <ul>
                <li style="list-style-type:none;">
                  <v-icon v-if="passwordLengthValidation">mdi-check</v-icon><v-icon v-else>mdi-close</v-icon> Between 16 and 99 chracters
                </li>
                <li style="list-style-type:none;">
                  <v-icon v-if="passwordNumbersValidation">mdi-check</v-icon><v-icon v-else>mdi-close</v-icon> At least two numbers
                </li>
                <li style="list-style-type:none;">
                  <v-icon v-if="passwordAlphasValidation">mdi-check</v-icon><v-icon v-else>mdi-close</v-icon> At least two letters
                </li>
                <li style="list-style-type:none;">
                  <v-icon v-if="passwordCapitalsValidation">mdi-check</v-icon><v-icon v-else>mdi-close</v-icon> At least one uppercase letter
                </li>
                <li style="list-style-type:none;">
                  <v-icon v-if="passwordRepeatingValidation">mdi-check</v-icon><v-icon v-else>mdi-close</v-icon> No more than two concurrent like characters
                </li>
                <li style="list-style-type:none;">
                  <v-icon v-if="passwordSpecialCharactersValidation">mdi-check</v-icon><v-icon v-else>mdi-close</v-icon> Does not include a colon
                </li>
              </ul>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-checkbox v-model="communication"
                          label="I would like to receive marketing communications from Texas Farm Bureau Insurance Companies">
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn block
                     :disabled="formDisabled"
                     type="submit"
                     class="tfb-button background-red">Register</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </ValidationObserver>
    </v-container>
    <v-container class="tfbins-registration-box"
                 v-if="registered">
      <h1 class="tfb-title-text-medium text-uppercase">Complete Registration</h1>
      <v-row>
        <v-col cols="12">
          Thank you for registering with Texas Farm Bureau Insurance! Please check your email for
          instructions on how to complete your registration.
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          Didn't receive an email? <a href=""
                                      v-on:click.prevent="resendRegistration()"
                                      hreflang="en-us"
                                      aria-label="Click here to resend the instructions.">Click here to resend the instructions.</a>
          <p v-if="registrationReSent"
             class="text-red">The registration instructions have been re-sent to your email.</p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import tfbMetaMixin from '@/mixins/metaInfo';
  import tfbHelperMixin from '@/mixins/helpers';
  import { invalidRegistrationIdMessage } from '@/mixins/errorMessages';

  export default {
    name: 'PolicyHolderRegister',
    mixins: [tfbMetaMixin, tfbHelperMixin],
    components: {
      ValidationObserver,
      ValidationProvider,
    },
    data() {
      return {
        policyNumber: '',
        memberNumber: '',
        userName: '',
        confirmUserName: '',
        passWord: '',
        confirmPassWord: '',
        communication: false,
        formDisabled: false,
        registered: false,
        registrationReSent: false,
      };
    },
    computed: {
      metaPageTitle() {
        return 'Register | PolicyHolder';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/policyholder/register`;
      },
      registrationId() {
        if (this.$route.query.regid && this.$route.query.regid !== undefined && this.$route.query.regid !== null) {
          return this.$route.query.regid;
        }

        if (this.$route.query.regId && this.$route.query.regId !== undefined && this.$route.query.regId !== null) {
          return this.$route.query.regId;
        }

        return null;
      },
      passwordLengthValidation() {
        return this.passWord.length >= 16 && this.passWord.length <= 99;
      },
      passwordNumbersValidation() {
        return this.hasNNumericCharacters(this.passWord, 2);
      },
      passwordAlphasValidation() {
        return this.hasNAlphaCharacters(this.passWord, 2);
      },
      passwordCapitalsValidation() {
        return this.hasNUppercaseCharacters(this.passWord, 1);
      },
      passwordRepeatingValidation() {
        return this.hasMoreThanNConcurrentLetters(this.passWord, 2);
      },
      passwordSpecialCharactersValidation() {
        return !this.containsString(this.passWord, ':');
      },
    },
    watch: {
      memberNumber(val) {
        this.memberNumber = val.toUpperCase();
      },
      policyNumber(val) {
        this.policyNumber = val.toUpperCase();
      },
    },
    created() {
      this.$store.dispatch('app/setIsLoading', false);
    },
    async mounted() {
      this.$store.dispatch('app/clearErrorMessage');
      await this.$store.dispatch('app/doApplicationCheck', { sApplicationName: 'Policyholder', sTfbId: this.trackingId })
        .then((returnedStatus) => {
          if (returnedStatus === '') {
            if (this.isLoggedIn) {
              // Redirect where needed
              this.doRedirect();
            } else {
              // See if this is an SSO session
              const includedToken = this.$route.query.access_token;

              if (includedToken && includedToken !== '') {
                // We have a token, see if we can log in
                this.$store.dispatch('member/doSsoLogin', { sAccessToken: includedToken, sTfbId: this.trackingId })
                  .then((response) => {
                    this.$store.dispatch('app/logToULS', {
                      logLevel: 'Info',
                      logMessage: response,
                      fileName: 'Login.vue',
                      methodName: 'doSsoLogin-Success',
                      trackerId: this.trackingId,
                    });

                    if (response === 'invalid_grant') {
                      this.$store.dispatch('app/setErrorMessage', 'Username/Password Not Found');
                      this.$store.dispatch('app/setIsLoading', false);
                    }

                    if (response !== '') {
                      this.$store.dispatch('app/setErrorMessage', response);
                      this.$store.dispatch('app/setIsLoading', false);
                    } else {
                      // Redirect where needed
                      this.doRedirect();
                    }
                  })
                  .catch((error) => {
                    this.$store.dispatch('app/setErrorMessage', error.toString());
                    this.$store.dispatch('app/setIsLoading', false);
                    this.$store.dispatch('app/logToULS', {
                      logLevel: 'Error',
                      logMessage: error,
                      fileName: 'Login.vue',
                      methodName: 'doSsoLogin-Catch',
                      trackerId: this.trackingId,
                    });
                  });
              } else {
                // Is there a registration id?
                // eslint-disable-next-line no-lonely-if
                if (this.registrationId && this.registrationId !== null && this.registrationId !== '') {
                  this.$store.dispatch('app/setIsLoading', true);
                  // Send the registration id to see if it's valid
                  this.$store.dispatch('member/doRegistrationIdVerification', { sRegistrationId: this.registrationId, sTfbId: this.trackingId })
                    .then((validationResponse) => {
                      this.$store.dispatch('app/logToULS', {
                        logLevel: 'Info',
                        logMessage: validationResponse,
                        fileName: 'Login.vue',
                        methodName: 'validateRegistrationId-Success',
                        trackerId: this.trackingId,
                      });

                      if (validationResponse !== null && validationResponse.ValidIdIndicator) {
                        // Valid registration id, so populate the fields
                        this.memberNumber = validationResponse.MemberNumber;
                        this.policyNumber = validationResponse.PolicyNumber;
                        this.userName = validationResponse.EmailAddress;
                        this.confirmUserName = validationResponse.EmailAddress;

                        this.$store.dispatch('app/setIsLoading', false);
                      } else {
                        // Invalid registration id, so display the error
                        this.$store.dispatch('app/setErrorMessage', invalidRegistrationIdMessage);
                        this.$store.dispatch('app/setIsLoading', false);
                        this.$store.dispatch('app/logToULS', {
                          logLevel: 'Error',
                          logMessage: 'Invalid registration id',
                          fileName: 'Login.vue',
                          methodName: 'validateRegistrationId-Invalid registration id',
                          trackerId: this.trackingId,
                        });
                      }
                    })
                    .catch((error) => {
                      this.$store.dispatch('app/setErrorMessage', error.toString());
                      this.$store.dispatch('app/setIsLoading', false);
                      this.$store.dispatch('app/logToULS', {
                        logLevel: 'Error',
                        logMessage: error,
                        fileName: 'Login.vue',
                        methodName: 'validateRegistrationId-Catch',
                        trackerId: this.trackingId,
                      });
                    });
                }
              }
              this.$store.dispatch('app/setIsLoading', false);
            }
          } else {
            this.$store.dispatch('app/setErrorMessage', returnedStatus);
            this.$store.dispatch('app/setIsLoading', false);
            this.formDisabled = true;
          }
        })
        .catch((error) => {
          this.$store.dispatch('app/setErrorMessage', error.toString());
          this.$store.dispatch('app/setIsLoading', false);
        });
    },
    methods: {
      onSubmit() {
        this.$store.dispatch('app/setIsLoading', true);
        this.$store.dispatch('app/clearErrorMessage');
        this.$store.dispatch('member/doRegistration', {
          sPolicyNumber: this.policyNumber,
          sMemberNumber: this.memberNumber,
          sUserName: this.userName,
          sPassword: this.passWord,
          sPasswordConfirmation: this.confirmPassWord,
          sCommunication: this.communication ? 'Y' : 'N',
          sTfbId: this.trackingId,
        })
          .then((response) => {
            this.$store.dispatch('app/setIsLoading', false);
            if (response !== '') {
              this.$store.dispatch('app/setErrorMessage', response);
              this.$store.dispatch('app/setIsLoading', false);
            } else {
              // Show the instructions to complete registration
              this.registered = true;
            }
          })
          .catch((error) => {
            this.$store.dispatch('app/setErrorMessage', error.toString());
            this.$store.dispatch('app/setIsLoading', false);
          });
      },
      resendRegistration() {
        this.registrationReSent = false;
        this.$store.dispatch('member/resendRegistration', {
          sMemberNumber: this.memberNumber,
          sUserName: this.userName,
          sTfbId: this.trackingId,
        })
          .then((response) => {
            if (response !== '') {
              this.$store.dispatch('app/setErrorMessage', response);
              this.$store.dispatch('app/setIsLoading', false);
            } else {
              // Show the instructions to complete registration
              this.registered = true;
              this.registrationReSent = true;
            }
          })
          .catch((error) => {
            this.$store.dispatch('app/setErrorMessage', error.toString());
            this.$store.dispatch('app/setIsLoading', false);
          });
      },
    },
  };
</script>
<style lang="scss">

  .tfbins-registration {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .tfbins-registration-box {
    background: $white;
    padding: 20px 50px;
    max-width: 700px;
    color: $tfb-darkest-blue;
  }
</style>
